import React, { useEffect, useRef } from "react";
import { Heading, Pane } from "evergreen-ui";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { Text } from "@fluentui/react/lib/Text";
import CustomModal from "@Components/CustomModal";
import Cap from "lodash/capitalize";
// import { SetStateAddons, SetStateSettings } from './types.d';
import { useCalculoProvisionalesStore } from "@Stores/storeCalculoProvisionales";
import { useCalculoStore } from "./CalculoProvisionalesModalStore";
import { useQuery } from "react-query";
import "./CalculoProvisionalesModal.scss";
import axios from "@Utils/axios";
import { stringify } from "query-string";
// import { useEquivalenciasStore } from './CalculoProvisionalesEqStore';
import CalculoProvisionalesModalStep1 from "./CalculoProvisionalesModalStep1";
import CalculoProvisionalesModalStep2 from "./CalculoProvisionalesModalStep2";
import CalculoProvisionalesModalStep3 from "./CalculoProvisionalesModalStep3";
import RazonSocial from "../RazonSocial";
import jsPDF from "jspdf";
import SegoeUI from "./jsFonts/SegoeUIRegular";
import SegoeUIBold from "./jsFonts/SegoeUIBold";
import SegoeUISemiBold from "./jsFonts/SegoeUISemiBold";

const customFonts = [
  {
    fullName: "Segoe_UI",
    font: SegoeUI,
    weight: "400",
  },
  {
    fullName: "Segoe_UI_Bold",
    font: SegoeUIBold,
    weight: "700",
  },
  {
    fullName: "Segoe_UI_SemiBold",
    font: SegoeUISemiBold,
    // weight: "600",
  },
];

jsPDF.API.events.push([
  "addFonts",
  function () {
    customFonts.forEach((customFont) => {
      this.addFileToVFS(`${customFont.fullName}.ttf`, customFont.font);
      this.addFont(
        `${customFont.fullName}.ttf`,
        customFont.fullName,
        "normal",
        customFont.weight
      );
    });
  },
]);

export async function getClientAcumulados(key, params) {
  const { data } = await axios.get(
    `/api/v1/acumulados?${stringify({ ...params })}`
  );
  return data;
}

export async function getPerdidasClienteYear(key, params) {
  const { data } = await axios.get(
    `/api/v1/perdidas?${stringify({ ...params })}`
  );
  return data;
}

export async function getCalculoAnterior(key, params) {
  const { data } = await axios.get(
    `/api/v1/calculoProvisionales/anterior?${stringify({ ...params })}`
  );
  return data;
}

export default function CalculoProvisionalesModal({ fromParent }) {
  const [
    visible,
    autoSave,
    selectedClient,
    dateTokens,
    startDate,
    canContinue,
  ] = useCalculoProvisionalesStore(
    `modalVisible, onAutoSaved, selectedClient, dateTokens, startDate, canContinue`
  );

  const pendingFetchesRef = useRef(3);

  const clienteAcumuladosKey = [
    "clienteAcumulados",
    {
      year: startDate.format("YYYY"),
      cliente: selectedClient?.id,
    },
  ];

  const clientePerdidasKey = [
    "clientePerdidas",
    {
      year: startDate.startOf("year").subtract(1, "year").format("YYYY"),
      cliente: selectedClient?.id,
    },
  ];

  const calculoAnteriorKey = [
    "calculoAnterior",
    {
      fecha_inicial: startDate.format("YYYY-MM-DD"),
      cliente: selectedClient?.id,
    },
  ];

  useQuery(clienteAcumuladosKey, getClientAcumulados, {
    onError: (err) => console.log(err),
    onSuccess: (data) => {
      useCalculoProvisionalesStore
        .getState()
        .onFetchAcumulados(data.acumulados);
      pendingFetchesRef.current = pendingFetchesRef.current - 1;
      if (pendingFetchesRef.current === 0 && autoSave) {
        startAutoSave();
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedClient,
  });

  useQuery(clientePerdidasKey, getPerdidasClienteYear, {
    onError: (err) => console.log(err),
    onSuccess: (data) => {
      useCalculoStore.getState().onFetchPerdidas(data);
      pendingFetchesRef.current = pendingFetchesRef.current - 1;
      if (pendingFetchesRef.current === 0 && autoSave) {
        startAutoSave();
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedClient,
  });

  useQuery(calculoAnteriorKey, getCalculoAnterior, {
    onError: (err) => console.log(err),
    onSuccess: (data) => {
      useCalculoStore.getState().onFetchPreviousCalculo(data);
      pendingFetchesRef.current = pendingFetchesRef.current - 1;
      if (pendingFetchesRef.current === 0 && autoSave) {
        startAutoSave();
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!selectedClient,
  });

  const [step, existingCalculo, editableMode] = useCalculoStore(`
    step, existingCalculo, editableMode
  `);

  useEffect(() => {
    useCalculoStore.setState({ clientData: selectedClient });
  }, [selectedClient]);

  if (!selectedClient) return null;

  function onDismissed() {
    // useCargaModalStore.getState().resetForm()
  }

  function startAutoSave() {
    // console.log("modal is ready to continue");
    setTimeout(() => {
      // console.log("continue");
      useCalculoStore.getState().stepForward();
    }, 1000);
  }

  function onClose(bypassDialog = false) {
    const closeModal = async () => {
      if (fromParent === "capturaProvisionales") {
        let newClientObject = { ...selectedClient };
        delete newClientObject.calculo;
        delete newClientObject.provisionales;

        pendingFetchesRef.current = 3;
        useCalculoProvisionalesStore.setState({
          modalVisible: false,
          modalAutoSave: false,
          selectedClient: newClientObject,
        });
      } else {
        pendingFetchesRef.current = 3;
        useCalculoProvisionalesStore.setState({
          modalVisible: false,
          modalAutoSave: false,
          selectedClient: null,
        });
      }
    };

    if (bypassDialog) {
      closeModal();
      return;
    }

    const dialog = useCalculoProvisionalesStore.getState().dialogRef;
    if (!dialog) {
      closeModal();
      return;
    }

    dialog.open({
      title: "Proceso inconcluso",
      subtitle: `El avance se perderá al cerrar. ¿Deseas salir de todos modos?`,
      cancelLabel: "No",
      actionLabel: "Sí",
      busyText: "",
      actionFunction: closeModal,
    });
  }

  function onExportarPDF() {
    // console.log("exportar a pdf");
    const doc = new jsPDF({
      compress: true,
      unit: "px",
      format: "letter",
      orientation: "portrait",
    });

    // const labels = Array.from(
    //   document.querySelectorAll(".calculo-provisionales-modal .ms-Label")
    // );
    // labels.forEach((label) => {
    //   label.style.fontFamily = "Segoe_UI_SemiBold";
    // });

    doc.html(document.querySelector(".ms-Modal-scrollableContent "), {
      image: { quality: 8, type: "png" },
      html2canvas: {
        width: 1836 / 2,
        // width: 4000,
        height: 2376 / 2,
        windowWidth: 1000,
        // windowHeight: 2376,
        scale: 0.245 * 2,

        useCORS: true,
        removeContainer: true,
        ignoreElements: (element) => {
          if (
            typeof element.className === "string" &&
            (element.className.includes("ms-Button-flexContainer") ||
              element.className.includes("ms-Button"))
          ) {
            return true;
          }
          return false;
        },
      },
      x: 0,
      y: 0,
      margin: 0,
      callback: (doc) => {
        const docType = step === 1 ? "Calculo" : "Equivalencias";
        doc.deletePage(2);
        doc
          .save(
            `${docType}_${selectedClient.rfc}_${periodoHeader.replace(
              /\s/g,
              ""
            )}.pdf`,
            { returnPromise: true }
          )
          .then(() => {});
      },
    });
  }

  const readyOnly = existingCalculo && !editableMode;

  const periodoHeader =
    selectedClient.periodicidad === "mensual"
      ? Cap(dateTokens.mensualFechaInicial.format("MMM YYYY"))
      : `${Cap(dateTokens.bimestralFechaInicial.format("MMM YYYY"))} - ${Cap(
          dateTokens.bimestralFechaFinal.format("MMM YYYY")
        )}`;

  const modalClass = autoSave
    ? "calculo-provisionales-modal autoSave"
    : "calculo-provisionales-modal";

  return (
    <CustomModal
      header={
        <div>
          <Heading size={600} marginBottom={0} marginTop="default">
            {"Cálculo para pagos provisionales"}
          </Heading>
        </div>
      }
      isOpen={visible}
      isBlocking={true}
      onDismissed={onDismissed}
      onClose={() => onClose(existingCalculo && !editableMode)}
      wide
      className={modalClass}
      unClosable={step === 3}
    >
      <Pane>
        <Pane display="flex" justifyContent="space-between">
          <Pane display="flex" flexDirection="column" flexWrap="nowrap">
            {/* <p style={{ fontWeight: 'bold' }}>{selectedClient?.razon_social}</p> */}
            <RazonSocial
              cliente={selectedClient}
              bold
              style={{ fontFamily: "Segoe_UI_SemiBold" }}
            />
            <Text
              variant="medium"
              block
              style={{ marginTop: 4, width: "100%", fontFamily: "Segoe_UI" }}
            >
              {selectedClient?.rfc}
            </Text>
          </Pane>
          <Pane display="flex">
            <Text
              variant="mediumPlus"
              block
              style={{ marginTop: 10, fontFamily: "Segoe_UI" }}
            >
              {periodoHeader}
            </Text>
          </Pane>
        </Pane>
        {(() => {
          if (step === 1) {
            return <CalculoProvisionalesModalStep1 />;
          } else if (step === 2) {
            return <CalculoProvisionalesModalStep2 autoSave={autoSave} />;
          } else if (step === 3) {
            return <CalculoProvisionalesModalStep3 />;
          }
        })()}
        {step !== 3 && (
          <Pane display="flex" justifyContent="space-between" marginTop={40}>
            <Pane display="flex" flex="1">
              {readyOnly && (
                <DefaultButton
                  style={{ marginRight: 8 }}
                  text="Exportar a PDF"
                  onClick={() => onExportarPDF()}
                  allowDisabledFocus
                  disabled={false}
                />
              )}
            </Pane>
            <Pane display="flex">
              {step !== 1 && (
                <DefaultButton
                  style={{ marginRight: 8 }}
                  text="Regresar"
                  onClick={() => useCalculoStore.getState().stepBackward()}
                  allowDisabledFocus
                  disabled={false}
                />
              )}
              <PrimaryButton
                className="capturaProvContinueBtn"
                text={
                  step === 2
                    ? existingCalculo
                      ? editableMode
                        ? "Guardar"
                        : "Cerrar"
                      : "Guardar"
                    : "Continuar"
                }
                type="button"
                onClick={() => {
                  if (existingCalculo && !editableMode && step === 2) {
                    onClose(true);
                  } else {
                    useCalculoStore.getState().stepForward();
                  }
                }}
                allowDisabledFocus
                disabled={!canContinue}
              />
            </Pane>
          </Pane>
        )}
      </Pane>
    </CustomModal>
  );
}
