import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { TextInputField, Button, ChevronLeftIcon, toaster, EyeOpenIcon, EyeOffIcon } from 'evergreen-ui';
import AuthForm from '@Root/layout/AuthForm';
import { wait, addWhenFieldError } from '@Utils/index';
import colors from '@Root/style/colors';
import axios from '@Utils/axios';

function getParams(){
  const url = window.location.href;
  var params = {};
  if (url.indexOf('?') !== -1){
    var query = url.split('?')[1];
    if (query.indexOf("&") !== -1){
      var arr = query.split("&");
      for (var i=0; i < arr.length; i++){
        params[arr[i].split("=")[0]] = arr[i].split("=")[1];
      }
    }else{
      params[query.split("=")[0]] = query.split("=")[1];
    }
  }
  return params;
}


export const CustomTextInput = ({ rightIcon, type, iconStyle={}, ...props }) => {

  const [showText, setShowText] = useState(false);

  const inputType = type !== 'password'
    ? type
    : showText ? 'text' : 'password';

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    }}>
      <TextInputField {...props} type={inputType} />
      <Button
        appearance="minimal"
        intent="none"
        onClick={() => setShowText((curr) => {
          return !curr;
        })}
        className="visibility"
        style={{ position: 'absolute', right: 0, top: 24, ...iconStyle }}
      >
        {
          showText ?
          <EyeOpenIcon style={{ color: '#9e9fa0'}} /> :
          <EyeOffIcon style={{ color: '#9e9fa0'}} />
        }
      </Button>

    </div>
  )
}

export default function ResetPassword() {
  const shouldRedirectRef = useRef(true);
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [name] = useState(getParams().name);
  const [token] = useState(getParams().token);
  const { handleSubmit, errors, control, getValues, reset } = useForm();

  useEffect(() => {
    return () => {
      shouldRedirectRef.current = false;
    }
  }, [])

  async function onSubmit(formData) {
    setSending(true);

    axios.post('/api/v1/auth/adminResetPassword', { password: formData.password, token })
      .then(async response => {
        if (response.data) {
          setSending(false);
          reset();
          // console.log(response.data);
          toaster.success("Contraseña actualizada", {
            description:
              "Ya puedes ingresar al sistema con tu nueva contraseña.",
          });

          await wait(2000);
          if (shouldRedirectRef.current) {
            // console.log('redirecting');
            history.push("/");
          }
        }
      })
      .catch(err => {
        setSending(false);
        if (err.response) {
          toaster.danger('No se pudo actualizar la contraseña', {
            description: err.response.data
          })
        } else {
          toaster.danger('No se pudo actualizar la contraseña', {
            description: 'Error en el servidor. Por favor intenta más tarde.'
          })
        }
      });

  }

  return (
    <AuthForm>
      <ResetPasswordS>
        <h1>Configura tu contraseña</h1>

        <p className="instructions">
          Hola {decodeURIComponent(name)}, configura una nueva contraseña para tu cuenta.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={CustomTextInput}
            control={control}
            defaultValue=""
            type="password"
            label="Contraseña"
            name="password"
            placeholder="SuperSecreto@2020"
            rules={{
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres.' },
            }}
            {...addWhenFieldError('password', errors)}
          />

          <Controller
            as={CustomTextInput}
            control={control}
            defaultValue=""
            type="password"
            label="Confirmar contraseña"
            name="confirm"
            placeholder="SuperSecreto@2020"
            rules={{
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres.' },
              validate: (val) => {
                const pass = getValues('password')
                if (val !== pass) {
                  return 'No coincide con la nueva contraseña'
                }
                return true
              },
            }}
            {...addWhenFieldError('confirm', errors)}
          />

          <div className="action-buttons">
            <Link className="forgot" to="/auth/login">
              <ChevronLeftIcon />
              Regresar
            </Link>
            <Button className="submit" appearance="primary" isLoading={sending} type="submit">
              {!sending ? 'Enviar' : 'Enviando'}
            </Button>
          </div>
        </form>
      </ResetPasswordS>
    </AuthForm>
  )

}

const ResetPasswordS = styled.div`
  h1 {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.text.dark};
  }

  label {
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    color: ${colors.text.default};
  }

  input[type=text], input[type=password], .instructions {
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  .instructions {
    margin-bottom: 20px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .forgot {
    text-decoration: none;
    color: ${colors.blue.B9};
    margin-right: 14px;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .submit {
    display: flex;
    align-self: right;
  }
`;
