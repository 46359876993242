import React from 'react'
import { Heading } from 'evergreen-ui'
import { useHeaderTitle } from '@Hooks/index'

function SectionTitle({ text, style = {} }) {
  useHeaderTitle(text)

  return (
    <Heading size={800} style={{ marginBottom: 40, ...style }}>
      {text}
    </Heading>
  )
}

export default SectionTitle
