import React from "react";
import create, { LogLevel } from "zustand-store-addons";
import dayjs from "dayjs";
import axios from "@Utils/axios";
import _get from "lodash/get";
import _set from "lodash/set";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { useFormStore } from "@Screens/pages/Provisionales/Captura/FormModal";
import cap from "lodash/capitalize";
import flatMap from "lodash/flatMap";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import { queryCache } from "@Root/index";
import { getSectionSettings } from "../utils";
import colors from "../style/colors";
import "dayjs/locale/es";

dayjs.locale("es");

function enCeros(captura) {
  const columns = ["subtotal", "iva", "ret_isr", "ret_iva"];
  const documentos = ["emitidos", "recibidos"];
  const subCats = ["pue", "ppd", "complementos"];

  let ceros = true;

  for (let cat of documentos) {
    for (let subCat of subCats) {
      for (let column of columns) {
        if (captura[cat][subCat][column] !== 0) {
          ceros = false;
          break;
        }
      }
      if (!ceros) {
        return false;
      }
    }
    if (!ceros) {
      return false;
    }
  }

  return ceros;
}

function tieneNomina(captura) {
  let nomina = false;

  for (let prop of ["nominasPatron", "nominasObrero"]) {
    for (let concepto of Object.keys(captura[prop])) {
      if (captura[prop][concepto] !== 0 && captura[prop][concepto] !== "0") {
        nomina = true;
        break;
      }
    }
  }

  return nomina;
}

const getConfigurableParams = (qParams) => {
  let params = { ...qParams };
  delete params.direction;
  delete params.order_by;
  delete params.results;
  delete params.uniq;
  return params;
};

const initialQueryParams = {
  tipo_persona: "all",
  prioridad: "all",
  razon_social: "",
  rfc: "",
  socio: "all",
  numero_control: "",
  direction: "first",
  order_by: "razon_social",
  uniq: 0,
  results: 20,
};

export const useCapturaProvisionalesStore = create(
  (set, get) => ({
    startDate: dayjs().startOf("month").subtract(1, "day").startOf("month"),
    formVisible: false,
    compactMode: false,
    activeRowId: null,
    dialogRef: null,
    dialogVisible: false,
    showingConfig: false,
    pageInfo: {},
    fetching: true,
    fetchMore: null,
    cargaModalVisible: false,

    selectedClient: null,
    fetchingClientData: false,

    queryParams: {
      ...initialQueryParams,
    },

    list: [],

    setConfig: (params) => {
      const uniq = get().queryParams.uniq;
      set({
        pageInfo: {},
        list: [],
        fetching: true,
        queryParams:
          typeof params === "function"
            ? params(get().queryParams)
            : { ...params, uniq: uniq + 1 },
      });
    },

    loadSettings: () => {
      const settings = getSectionSettings("capturaProvisionales");
      const currentSettings = get().queryParams;
      set({
        queryParams: {
          ...currentSettings,
          results: _get(settings, "resultsPerBatch", 20),
        },
      });
    },

    setDialogRef: (ref) => {
      set({ dialogRef: ref });
    },

    onPrevHandler: () => {
      set((state) => ({
        list: [],
        startDate: state.startDate.subtract(1, "M"),
        fetching: true,
      }));
    },

    onNextHandler: () => {
      set((state) => ({
        list: [],
        startDate: state.startDate.add(1, "M"),
        fetching: true,
      }));
    },

    onFetch: (data) => {
      const list = flatMap(data, (data) =>
        data.results.map((row, index) => {
          let provisional = undefined;

          if (row.provisional !== null) {
            provisional = {
              ...row.provisional,
              enCeros: enCeros(row.provisional.data_json),
              nomina: tieneNomina(row.provisional.data_json),
            };
          }

          return {
            ...row,
            provisional,
            status: "ok",
            capturado:
              row.provisional !== null
                ? dayjs(row.provisional.fecha_inicial).valueOf()
                : 0,
            ajustado:
              row.provisional !== null ? row.provisional.ajustado : false,
            enCeros:
              typeof provisional !== "undefined"
                ? provisional.enCeros
                  ? 2
                  : 1
                : 0,
            nomina:
              typeof provisional !== "undefined" ? provisional.nomina : false,
          };
        })
      );

      set({
        list,
        pageInfo: last(data).pageInfo,
        fetching: false,
      });
    },

    onFetchMoreBtn: () => {
      if (typeof get().fetchMore === "function") {
        set({
          fetching: true,
        });
        get().fetchMore();
      }
    },

    onEditarProvisional: (
      item,
      prov,
      porComprobar = false,
      readOnly = false
    ) => {
      // console.log("onEditarProvisional", prov);
      let stateMods = {};

      stateMods.provId = prov.id;
      stateMods.editingExisting = true;

      if (prov.periodicidad === "mensual") {
        stateMods.periodicidad = { key: "mensual", text: "Mensual" };
      } else {
        stateMods.periodicidad = { key: "bimestral", text: "Bimestral" };
      }

      let periodo = {};

      if (prov.periodicidad === "mensual") {
        periodo.key = `${dayjs(prov.fecha_inicial).format(
          "YYYY-MM-DD"
        )} ${dayjs(prov.fecha_final).format("YYYY-MM-DD")}`;
        periodo.text = `${cap(dayjs(prov.fecha_inicial).format("MMM YYYY"))}`;
      } else {
        periodo.key = `${dayjs(prov.fecha_inicial).format(
          "YYYY-MM-DD"
        )} ${dayjs(prov.fecha_final).format("YYYY-MM-DD")}`;
        periodo.text = `${cap(
          dayjs(prov.fecha_inicial).format("MMM YYYY")
        )} - ${cap(dayjs(prov.fecha_final).format("MMM YYYY"))}`;
      }

      stateMods.periodo = periodo;
      stateMods.negativos = undefined;

      stateMods.emitidos = {
        ...prov.data_json.emitidos,
        por_comprobar: (() => {
          let obj = {...prov.data_json.emitidos.por_comprobar};
           Object.entries(prov.data_json.emitidos.por_comprobar).forEach(([key, value]) => {
            obj[key] = Number(value);
            if (obj[key] < 0) {
              obj[key] *= -1;
              if (typeof stateMods.negativos === "undefined") {
                stateMods.negativos = {};
              }
              _set(stateMods.negativos, `emitidos.por_comprobar.${key}`, true);
            }
           });
          return obj;
        })(),
      }

      stateMods.recibidos = {
        ...prov.data_json.recibidos,
        por_comprobar: (() => {
          let obj = {...prov.data_json.recibidos.por_comprobar};
           Object.entries(prov.data_json.recibidos.por_comprobar).forEach(([key, value]) => {
            obj[key] = Number(value);
            if (obj[key] < 0) {
              obj[key] *= -1;
              if (typeof stateMods.negativos === "undefined") {
                stateMods.negativos = {};
              }
              _set(stateMods.negativos, `recibidos.por_comprobar.${key}`, true);
            }
           });
          return obj;
        })(),
      }

      stateMods.nominasObrero = prov.data_json.nominasObrero;
      stateMods.nominasPatron = prov.data_json.nominasPatron;

      stateMods.porComprobarEditable = porComprobar;
      stateMods.editable = !porComprobar;
      stateMods.readOnly = readOnly;

      if (readOnly) {
        stateMods.currentStep = 4;
      }

      useFormStore.setState(stateMods);

      set({
        selectedClient: item,
        formVisible: true,
        fetchingClientData: true,
      });
    },

    onCapturarNuevoProvisional: (item) => {
      // console.log("onCapturarNuevoProvisional", item);
      let stateMods = {};
      if (item.status === "partial") {
        if (item.provisionales[0].periodicidad === "mensual") {
          stateMods.periodicidad = {
            key: "mensual",
            text: "Mensual",
          };
        }
      }
      stateMods.editable = true;
      stateMods.porComprobarEditable = false;
      useFormStore.setState(stateMods);

      set({
        selectedClient: item,
        formVisible: true,
        fetchingClientData: true,
      });
    },

    onDeleteProvisional: (item) => {
      const dialog = get().dialogRef;
      const qp = get().getProvisionalesParams;

      // console.log("onDeleteProvisional", item);

      dialog.open({
        title: "Confirmación requerida",
        subtitle: `¿Deseas eliminar la captura ?`,
        cancelLabel: "No",
        actionLabel: "Sí",
        busyText: "Eliminando",
        actionFunction: async () => {
          axios
            .delete(`/api/v1/provisionales/${item.id}`)
            .then(({ data }) => {
              if (data.ok) {
                queryCache.refetchQueries(["provisionalesList"], {
                  fecha_inicial: qp.fecha_inicial,
                  fecha_final: qp.fecha_final,
                });
              }
            })
            .catch((e) => console.log(e));
          return;
        },
      });
    },
  }),
  {
    computed: {
      startMonthName() {
        return cap(this.startDate.format("MMMM"));
      },
      startYear() {
        return this.startDate.format("YYYY");
      },
      endMonthName() {
        return cap(this.startDate.add(1, "M").format("MMMM"));
      },
      endYear() {
        return this.startDate.add(1, "M").format("YYYY");
      },
      getProvisionalesParams() {
        return {
          ...this.queryParams,
          fecha_inicial: this.startDate.format("YYYY-MM-DD"),
          fecha_final: this.startDate.endOf("month").format("YYYY-MM-DD"),
        };
      },
      FiltersButton(ctx) {
        const currentParams = this.queryParams;

        return ({ style = {} }) => (
          <DefaultButton
            onClick={() => ctx.set({ showingConfig: true })}
            text="Configuración"
            style={{ alignSelf: "flex-end", ...style }}
            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
            iconProps={{
              iconName: "FilterSolid",
              style: {
                fontSize: 10,
                color: isEqual(
                  getConfigurableParams(currentParams),
                  getConfigurableParams(initialQueryParams)
                )
                  ? colors.neutral.N5
                  : colors.green.base,
              },
            }}
          />
        );
      },
    },

    settings: {
      name: "CapturaProvisionales",
      logLevel: LogLevel.None,
    },
  }
);
