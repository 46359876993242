import React, { useRef, useState } from "react";
// import { TextField, TextFieldBase } from '@fluentui/react/lib/TextField';
import Cleave from "cleave.js/react";
import "./NumericTextField.scss";

export const numStringFormatter = (num, bypass = false) => {
  if (bypass) return num;

  let _num = Number(num);
  return _num !== 0
    ? _num.toLocaleString("es-MX", {
        useGrouping: false,
        minimumFractionDigits: 2,
      })
    : "0";
};
export default function NumericTextField4d({
  keyData,
  defaultValue = "0",
  onUpdate,
  label,
  labelButton,
  bold = false,
  colored = false,
  ...props
}) {
  const [focused, setFocused] = useState(false);
  const cleaveRef = useRef(null);

  const wrapperClasses = focused
    ? "ms-TextField-fieldGroup textfield-wrapper focused"
    : "ms-TextField-fieldGroup textfield-wrapper";

  function onInputBlur(event) {
    let val = event.target.rawValue;

    let needsCleaning = false;

    if (val === "" || val === "0.") {
      val = "0";
      needsCleaning = true;
    } else if (val.indexOf(".") !== -1 && val.indexOf(".") > val.length - 3) {
      val += "0";
      needsCleaning = true;
    }

    if (needsCleaning) {
      cleaveRef.current.setRawValue(val);
    } else {
      onUpdate && onUpdate(numStringFormatter(val));
    }

    setFocused(false);
  }

  function onInputChanged(event) {
    let val = event.target.rawValue;
    // console.log(val);

    let needsCleaning = false;

    if (val === ".") {
      val = "0.";
      needsCleaning = true;
    }
    if (val.length === 2 && val.indexOf("0") === 0 && val.indexOf(".") !== 1) {
      val = val.charAt(1);
      needsCleaning = true;
    }

    if (needsCleaning) {
      cleaveRef.current.setRawValue(val);
    } else {
      onUpdate && onUpdate(val);
    }
  }

  return (
    <div className="ms-TextField num-field field-root">
      <div
        className="ms-TextField-wrapper field-root"
        style={{ alignItems: "center" }}
      >
        {label && (
          <React.Fragment>
            <label
              htmlFor={label}
              className="ms-Label"
              style={{
                flex: 1,
              }}
            >
              {label}
            </label>
            {labelButton && <span>{labelButton}</span>}
          </React.Fragment>
        )}
        <div className={wrapperClasses}>
          <Cleave
            disabled={props.readOnly ?? false}
            className={`num-field field-root number-field ${
              bold ? "total" : ""
            }`}
            name={label}
            value={numStringFormatter(defaultValue, focused)}
            options={{ numeral: true, numeralThousandsGroupStyle: "thousand" }}
            onInit={(ref) => {
              cleaveRef.current = ref;
            }}
            onChange={onInputChanged}
            onBlur={onInputBlur}
            onFocus={() => {
              !props.readOnly && setFocused(true);
            }}
            style={{
              ...(colored &&
                props.readOnly && {
                  backgroundColor: "#f3f3f3",
                }),
            }}
            {...props}
          />
        </div>
      </div>
    </div>
  );
}
