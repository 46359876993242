import React from "react";
import create, { LogLevel } from "zustand-store-addons";
import dayjs from "dayjs";
import cap from "lodash/capitalize";
import _get from "lodash/get";
import isEqual from "lodash/isEqual";
import flatMap from "lodash/flatMap";
import { DefaultButton } from "@fluentui/react/lib/Button";
import last from "lodash/last";
import colors from "../style/colors";
import "dayjs/locale/es";
import { getDateTokens, getSectionSettings } from "@Utils/index";
import { useCalculoStore } from "@Screens/pages/Provisionales/Calculo/CalculoProvisionalesModalStore";
import axios from "@Utils/axios";
import { copyAndSort } from "@Utils/index";
import { queryCache } from "@Root/index";

dayjs.locale("es");

const clientFields = [
  "prioridad",
  "rfc",
  "numero_control",
  "razon_social",
  "email",
  "socio",
  "activo",
];

const getConfigurableParams = (qParams) => {
  let params = { ...qParams };
  delete params.direction;
  delete params.order_by;
  delete params.results;
  delete params.uniq;
  return params;
};

const initialQueryParams = {
  tipo_persona: "all",
  prioridad: "all",
  razon_social: "",
  rfc: "",
  socio: "all",
  numero_control: "",
  direction: "first",
  order_by: "razon_social",
  uniq: 0,
  results: 20,
};

export const useCalculoProvisionalesStore = create(
  (set, get) => ({
    startDate: dayjs().startOf("month").subtract(1, "day").startOf("month"),
    modalVisible: false,
    modalAutoSave: false,
    onAutoSaved: undefined,
    // existingCalculo: false,
    showingConfig: false,
    pageInfo: {},
    fetching: true,
    fetchMore: null,
    compactMode: false,
    activeRowId: null,
    dialogRef: null,
    dialogVisible: false,
    selectedClient: null,
    canContinue: true,
    tableColumnsStore: null,

    queryParams: {
      ...initialQueryParams,
    },

    list: [],

    setConfig: (params) => {
      const uniq = get().queryParams.uniq;
      set({
        pageInfo: {},
        list: [],
        fetching: true,
        queryParams:
          typeof params === "function"
            ? params(get().queryParams)
            : { ...params, uniq: uniq + 1 },
      });
    },

    loadSettings: () => {
      const settings = getSectionSettings("capturaProvisionales");
      const currentSettings = get().queryParams;
      set({
        queryParams: {
          ...currentSettings,
          results: _get(settings, "resultsPerBatch", 20),
        },
      });
    },

    setDialogRef(ref) {
      set({ dialogRef: ref });
    },

    onPrevHandler() {
      set((state) => ({
        list: [],
        startDate: state.startDate.subtract(1, "M"),
        fetching: true,
      }));
    },

    onNextHandler() {
      set((state) => ({
        list: [],
        startDate: state.startDate.add(1, "M"),
        fetching: true,
      }));
    },

    // onFetch(data) {
    //   const list = data.map((row, index) => {
    //     return row;
    //   });
    //   // console.log("list", list);
    //   set({ list, fetching: false });
    // },
    onFetch(data) {
      const list = flatMap(data, (data) =>
        data.results.map((row, index) => {
          return {
            ...row,
            movimientos:
              typeof row.provisionales !== "undefined"
                ? dayjs(row.provisionales[0].fecha_inicial).valueOf()
                : 0,
            hasCalculo:
              row.calculo !== null
                ? dayjs(row.calculo.fecha_inicial).valueOf()
                : 0,
            tipoCalculo: row.calculo !== null ? row.calculo.tipo : "",
            reporteAvailable:
              row.calculo !== null ? row.calculo.available : false,
            emailed: row.calculo !== null ? row.calculo.emailed : false,
          };
        })
      );
      // console.log("onFetch calculo", data);

      const tableColumns = get().tableColumnsStore.getState().columns;
      const sortingColumn = tableColumns.find((col) => col.isSorted);

      let sortedList = [...list];
      if (sortingColumn && !clientFields.includes(sortingColumn.key)) {
        sortedList = copyAndSort(
          sortedList,
          sortingColumn.fieldName,
          sortingColumn.isSortedDescending
        );
      }

      set({
        list: sortedList,
        pageInfo: last(data).pageInfo,
        fetching: false,
      });
    },

    onFetchMoreBtn: () => {
      if (typeof get().fetchMore === "function") {
        set({
          fetching: true,
        });
        get().fetchMore();
      }
    },

    onOpenNewCalculo(item) {
      // console.log("onOpenNewCalculo", item);
      set({
        selectedClient: item,
        modalVisible: true,
        // existingCalculo: false,
      });
    },

    onOpenExistingCalculo(
      item,
      edit = false,
      autoSave = false,
      onAutoSaved = undefined
    ) {
      // console.log("onOpenExistingCalculo", item);
      set({
        selectedClient: {
          ...item,
          editCalculo: edit,
        },
        modalVisible: true,
        modalAutoSave: autoSave,
        onAutoSaved: onAutoSaved,
      });
    },

    onDeleteCalculo(clientData) {
      const dialog = get().dialogRef;

      dialog.open({
        title: "Confirmación requerida",
        subtitle: `¿Deseas eliminar el cálculo ?`,
        cancelLabel: "No",
        actionLabel: "Sí",
        busyText: "Eliminando",
        actionFunction: async () => {
          axios
            .delete(`/api/v1/calculoProvisionales/${clientData.calculo.id}`)
            .then(({ data }) => {
              if (data.ok) {
                const queryKey = get().calculosListQueryKey;
                queryCache.invalidateQueries(queryKey);
              }
            })
            .catch((e) => console.log(e));
          return;
        },
      });
      // console.log("delete calculo", clientData.calculo);
    },

    async onUpdateCalculoTipo(calculoId, tipo) {
      const queryKey = get().calculosListQueryKey;

      const response = await axios.post(
        "/api/v1/calculoProvisionales/updateTipo",
        { calculoId, tipo }
      );
      if (response && response.data) {
        // Refetch query
        queryCache.invalidateQueries(queryKey);
      }
    },

    async onUpdateCalculoAvailability(calculoId, visible) {
      const queryKey = get().calculosListQueryKey;

      const response = await axios.post(
        "/api/v1/calculoProvisionales/updateAvailability",
        { calculoId, visible }
      );
      if (response && response.data) {
        // Refetch query
        queryCache.invalidateQueries(queryKey);
      }
    },

    onFetchAcumulados(data) {
      const periodicidad = get().selectedClient.periodicidad;
      const dates = get().dateTokens;

      const endDate =
        periodicidad === "mensual"
          ? dates.mensualFechaFinal
          : dates.bimestralFechaFinal;

      const acumulados = data.filter((acum) => {
        return (
          dayjs(acum.fecha_final).isBefore(endDate) ||
          dayjs(acum.fecha_final).isSame(endDate)
        );
      });

      // set({ clientAcumulados: acumulados });
      useCalculoStore.setState({ clientAcumulados: acumulados });
    },
  }),
  {
    computed: {
      startMonthName() {
        return cap(this.startDate.format("MMMM"));
      },
      startYear() {
        return this.startDate.format("YYYY");
      },
      dateTokens() {
        return getDateTokens(this.startDate);
      },
      getProvisionalesParams() {
        return {
          ...this.queryParams,
          fecha_inicial: this.startDate.format("YYYY-MM-DD"),
          fecha_final: this.startDate.endOf("month").format("YYYY-MM-DD"),
        };
      },
      calculosListQueryKey() {
        const qp = this.getProvisionalesParams;

        return [
          "calculoProvisionalesList",
          {
            fecha_inicial: qp.fecha_inicial,
            fecha_final: qp.fecha_final,
            order_by: qp?.order_by ?? "razon_social",
            personas: qp?.tipo_persona ?? "",
            prioridad: qp?.prioridad ?? "",
            razon_social: qp?.razon_social ?? "",
            rfc: qp?.rfc ?? "",
            socio: qp?.socio ?? "",
            numero_control: qp?.numero_control ?? "",
            uniq: qp?.uniq ?? 0,
            [qp?.direction]: qp?.results ?? 20,
          },
        ];
      },
      FiltersButton(ctx) {
        const currentParams = this.queryParams;

        return ({ style = {} }) => (
          <DefaultButton
            onClick={() => ctx.set({ showingConfig: true })}
            text="Configuración"
            style={{ alignSelf: "flex-end", ...style }}
            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
            iconProps={{
              iconName: "FilterSolid",
              style: {
                fontSize: 10,
                color: isEqual(
                  getConfigurableParams(currentParams),
                  getConfigurableParams(initialQueryParams)
                )
                  ? colors.neutral.N5
                  : colors.green.base,
              },
            }}
          />
        );
      },
    },

    settings: {
      name: "CalculoProvisionales",
      logLevel: LogLevel.None,
    },
  }
);
