import React, { useLayoutEffect } from "react";
import {
  Switch,
  Route,
  // useLocation,
  Redirect,
  useLocation,
} from "react-router-dom";
import loadable from "@loadable/component";
import { isValidSession } from "@Utils/index";
import Page from "@Root/layout/Page";
import { useClientsStore } from "@Stores/storeClients";
import { useCapturaProvisionalesStore } from "@Stores/storeCapturaProvisionales";
import { useUsersStore } from "@Stores/storeUsers";
import { useCalculoProvisionalesStore } from "@Stores/storeCalculoProvisionales";
import LoadingSection from "@Components/LoadingSection";
import Login from "@Screens/auth/Login/Login";
import Forgot from "@Screens/auth/Forgot/Forgot";
import ResetPassword from "@Screens/auth/ResetPassword/ResetPassword";
import ResetUserPassword from "@Screens/auth/ResetUserPassword/ResetUserPassword";
// import SocketIO from '@Components/SocketIO'
// import { useSessionStore } from '@Utils/session'

// const Login = loadable(() => import("./screens/auth/Login/Login"), {
//   fallback: <LoadingSection />,
// });

// const Forgot = loadable(() => import("./screens/auth/Forgot/Forgot"), {
//   fallback: <LoadingSection />,
// });

// const ResetPassword = loadable(
//   () => import("./screens/auth/ResetPassword/ResetPassword"),
//   {
//     fallback: <LoadingSection />,
//   }
// );

// const ResetUserPassword = loadable(
//   () => import("./screens/auth/ResetUserPassword/ResetUserPassword"),
//   {
//     fallback: <LoadingSection />,
//   }
// );

const Dashboard = loadable(
  () => import("./screens/pages/Dashboard/Dashboard"),
  {
    fallback: <LoadingSection title="Dashboard" />,
  }
);

const NotFound = loadable(() => import("./screens/404/NotFound"), {
  fallback: <LoadingSection />,
});

const Administrators = loadable(
  () => import("./screens/pages/Administrators/Administrators"),
  {
    fallback: <LoadingSection title="Administradores" />,
  }
);

const Profile = loadable(() => import("./screens/pages/Profile/Profile"), {
  fallback: <LoadingSection title="Mi Perfil" />,
});

const Clients = loadable(() => import("./screens/pages/Clients/Clients"), {
  fallback: <LoadingSection title="Listado de clientes" />,
});

const Users = loadable(() => import("./screens/pages/Users/Users"), {
  fallback: <LoadingSection title="Listado de usuarios" />,
});

const CapturaProvisionales = loadable(
  () => import("./screens/pages/Provisionales/Captura/CapturaProvisionales"),
  {
    fallback: <LoadingSection title="Captura de movimientos" />,
  }
);

const CalculoProvisionales = loadable(
  () => import("./screens/pages/Provisionales/Calculo/CalculoProvisionales"),
  {
    fallback: <LoadingSection title="Cálculo de provisionales" />,
  }
);

const Departamentos = loadable(
  () => import("./screens/pages/Departamentos/AdministradorArchivos/Departamentos"),
  {
    fallback: <LoadingSection title="Departamentos" />,
  }
)

const DepartamentosMonitoreo = loadable(
  () => import("./screens/pages/Departamentos/Monitoreo/Monitoreo"),
  {
    fallback: <LoadingSection title="Monitoreo de Departamentos" />,
  }
)

const ChatEquipos = loadable(() => import("./screens/pages/Chat/Equipos"), {
  fallback: <LoadingSection title="Equipos" />,
});

function PrivateRoute({ children, ...rest }) {
  const location = useLocation();

  useLayoutEffect(() => {
    if (isValidSession()) {
      // console.log("location", location);
      if (location.pathname === "/clientes") {
        useClientsStore.getState().loadSettings();
      } else if (location.pathname === "/capturaProvisionales") {
        useCapturaProvisionalesStore.getState().loadSettings();
      } else if (location.pathname === "/usuarios") {
        useUsersStore.getState().loadSettings();
      } else if (location.pathname === "/calculoProvisionales") {
        useCalculoProvisionalesStore.getState().loadSettings();
      }
    }
  }, [location]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isValidSession()) {
          return <Page>{children}</Page>;
        }

        return (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: location } }}
          />
        );
      }}
    />
  );
}

function App() {
  // const idToken = useSessionStore('idToken')

  return (
    <div>
      {/* {idToken && <SocketIO /> } */}
      <Switch>
        <Route exact path="/">
          <Route
            render={() =>
              isValidSession() ? (
                <Redirect to={{ pathname: "/home" }} />
              ) : (
                <Redirect to={{ pathname: "/auth/login" }} />
              )
            }
          />
        </Route>

        <Route path="/auth/login">
          <Route
            render={() =>
              isValidSession() ? (
                <Redirect to={{ pathname: "/home" }} />
              ) : (
                <Login />
              )
            }
          />
        </Route>

        <Route path="/auth/forgot">
          <Forgot />
        </Route>
        <Route path="/auth/resetPassword">
          <ResetPassword />
        </Route>
        <Route path="/auth/resetUserPassword">
          <ResetUserPassword />
        </Route>

        <PrivateRoute path="/home">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/admins">
          <Administrators />
        </PrivateRoute>
        <PrivateRoute path="/perfil">
          <Profile />
        </PrivateRoute>
        <PrivateRoute path="/clientes">
          <Clients />
        </PrivateRoute>
        <PrivateRoute path="/usuarios">
          <Users />
        </PrivateRoute>
        <PrivateRoute path="/capturaProvisionales">
          <CapturaProvisionales />
        </PrivateRoute>
        <PrivateRoute path="/calculoProvisionales">
          <CalculoProvisionales />
        </PrivateRoute>
        <PrivateRoute path="/departamentos">
          <Departamentos />
        </PrivateRoute>
        <PrivateRoute path="/departamentosMonitoreo">
          <DepartamentosMonitoreo />
        </PrivateRoute>
        <PrivateRoute path="/chatEquipos">
          <ChatEquipos />
        </PrivateRoute>

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
