import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MenuIcon, Popover, Position, Menu, Text, Heading } from 'evergreen-ui'
import { useHistory } from 'react-router-dom';
import colors from '@Root/style/colors';
import { useAppState } from '@Stores/storeApp'
import { useUserState } from '@Stores/storeUser'
import { loginFromTokens } from '@Utils/index';
import { atom, useAtom } from 'jotai';
import axios from '@Utils/axios'
import { motion, useTransform, useViewportScroll } from 'framer-motion';


export const headerTitleAtom = atom('');


const Header = ({ menuOpened }) => {
  const toggleMenu = useAppState((state) => state.toggleMenu)
  const menuOpen = useAppState((state) => state.menuOpen)
  const onLogout = useUserState((state) => state.onLogout)
  const user = useUserState((state) => state.user)
  const history = useHistory()
  const loggingIn = useRef(false);

  const [headerTitle] = useAtom(headerTitleAtom);

  const { scrollY } = useViewportScroll();
  const scrollRange = [0, 50, 51];
  const opacity = useTransform(scrollY, scrollRange, [0, 0, 1]);


  useEffect(() => {
    if (!user) {
      // console.log('Header with no user')
      if (!loggingIn.current) {
        loggingIn.current = true;
        loginFromTokens().then((userData) => {
          loggingIn.current = false;

          if (userData) {
            // console.log('userData', userData)
            useUserState.setState({ user: userData });
          } else {
            // console.log('no user data')
            onLogout(history);
          }
        });
      }
    }
  }, [user, onLogout, history])

  function onSignOut() {
    axios.post('/api/v1/signOutAdmin').then(({ data }) => {
      if (data.ok) {
        onLogout(history)
      }
    })
  }

  return (
    <HeaderS menuOpened={menuOpen}>
      <div className="left">
        <div className="menu-button" onClick={toggleMenu}>
          <MenuIcon className="icon" size={24} />
        </div>
        <motion.div style={{ opacity }}>
          <Heading size={600} marginLeft={20}>
            {headerTitle}
          </Heading>
        </motion.div>
      </div>
      {user && (
        <div className="right">
          <Popover
            shouldCloseOnExternalClick={true}
            position={Position.BOTTOM_RIGHT}
            content={({ close }) => (
              <Menu>
                <Menu.Group>
                  <Menu.Item
                    onSelect={() => {
                      history.push('/perfil')
                      close()
                    }}
                  >
                    Perfil
                  </Menu.Item>
                  <Menu.Item intent="danger" onSelect={() => onSignOut()}>
                    Cerrar sesión
                  </Menu.Item>
                </Menu.Group>
              </Menu>
            )}
          >
            <Text className="user">{`${user?.fullname}`}</Text>
          </Popover>
        </div>
      )}
    </HeaderS>
  )
};

export default Header;

const HeaderS = styled.div`
  height: 64px;
  margin-top: 0px;
  transform: translateY(0px);
  left: ${(props) => props.menuOpened ? '256px' : '0px'};
  @media screen and (max-width: 768px) {
    left: 0px;
  }
  right: 0px;
  position: fixed;
  top: 0;
  z-index: 5;
  background-color: white;
  box-shadow: 0 0 1px rgba(67,90,111,.47), 0 2px 4px -2px rgba(67,90,111,.3);
  display: flex;
  justify-content: center;
  transition: left 0.3s;

  .left, .right {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px;
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;

    .user {
      flex-grow: 0;
      padding: 10px;
      cursor: pointer;
    }
    .user:hover {
      background-color: ${colors.background.tint2}
    }
  }

  .menu-button {

    .icon {
      color: ${colors.blue.B8}
    }
  }

`;