import React from 'react'
import { Modal } from '@fluentui/react/lib/Modal'
import { DefaultPalette, FontSizes, FontWeights } from '@fluentui/react/lib/Styling'
import CloseButton from '@Components/CloseButton'
import { mobileAndTabletCheck } from '@Utils/index'
import { useViewport } from '@Hooks/useViewport'
import styled from 'styled-components'
import { Heading } from 'evergreen-ui'

export default function CustomModal({
  title,
  header,
  isOpen,
  isBlocking,
  onDismissed,
  onClose,
  children,
  styles = {},
  wide = false,
  unClosable = false,
  ...restProps
}) {
  const { width, height } = useViewport()

  return (
    <Modal
      titleAriaId={title}
      isOpen={isOpen}
      isBlocking={true}
      onDismissed={onDismissed}
      onDismiss={onClose}
      styles={{
        root: {
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        },
        main: {
          width: width <= 768 || mobileAndTabletCheck() ? '100vw' : wide ? '550px' : '500px',
          ...(width <= 768 || mobileAndTabletCheck() ? { height: height, top: 0 } : {}),
          margin: 'auto',
        },
        scrollableContent: {
          maxHeight: 'auto'
        },
        ...styles,
      }}
      {...restProps}
    >
      <ModalHeader width={width}>
        { header ?? (
          <Heading size={600} marginTop="default">
            {title}
          </Heading>
        )}
        {!unClosable && <CloseButton ariaLabel="Cerrar formulario" onClick={() => onClose()} />}
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
    </Modal>
  )
}

const ModalHeader = styled.div`
  flex: 1 1 auto;
  border-top: 4px solid ${DefaultPalette.themePrimary};
  color: ${DefaultPalette.neutralPrimary};
  display: flex;
  align-items: center;
  font-weight: ${FontWeights.semibold};
  padding: 12px 12px 14px 24px;
  /* width: 100%; */
  /* width: ${({ width }) => (width <= 768 ? '90vw' : '70vw')}; */

  h1 {
    color: ${DefaultPalette.neutralSecondary};
    font-size: ${FontSizes.xLarge};
  }

  .label {
    color: red !important;
  }
`

const ModalContent = styled.div`
  padding: 12px 24px 14px 24px;
`
