import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  toaster,
} from 'evergreen-ui';
import AuthForm from '@Root/layout/AuthForm';
import { wait, addWhenFieldError } from '@Utils/index';
import colors from '@Root/style/colors';
import axios from '@Utils/axios';
import { CustomTextInput } from '@Screens/auth/ResetPassword/ResetPassword';

function getParams() {
  const url = window.location.href;
  var params = {};
  if (url.indexOf('?') !== -1) {
    var query = url.split('?')[1];
    if (query.indexOf('&') !== -1) {
      var arr = query.split('&');
      for (var i = 0; i < arr.length; i++) {
        params[arr[i].split('=')[0]] = arr[i].split('=')[1];
      }
    } else {
      params[query.split('=')[0]] = query.split('=')[1];
    }
  }
  return params;
}

export default function ResetUserPassword() {
  const shouldRedirectRef = useRef(true);
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const [name] = useState(getParams().name);
  const [token] = useState(getParams().token);
  const { handleSubmit, errors, control, getValues, reset } = useForm();

  useEffect(() => {
    return () => {
      shouldRedirectRef.current = false;
    };
  }, []);

  async function onSubmit(formData) {
    setSending(true);

    axios
      .post('/api/v1/auth/resetPassword', { password: formData.password, token })
      .then(async (response) => {
        if (response.data) {
          setSending(false);
          reset();
          // console.log(response.data);
          toaster.success("Contraseña actualizada", {
            description: "Ya puedes ingresar a la app con tu nueva contraseña.",
          });

          await wait(2000);
          if (shouldRedirectRef.current) {
            // console.log('redirecting');
            history.push("/");
          }
        }
      })
      .catch((err) => {
        setSending(false);
        if (err.response) {
          toaster.danger('No se pudo actualizar la contraseña', {
            description: err.response.data,
          });
        } else {
          toaster.danger('No se pudo actualizar la contraseña', {
            description: 'Error en el servidor. Por favor intenta más tarde.',
          });
        }
      });
  }

  return (
    <AuthForm>
      <ResetPasswordS>
        <h1>Configura tu contraseña</h1>

        <p className="instructions">
          Hola {decodeURIComponent(name)}, configura una nueva contraseña para tu cuenta.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={CustomTextInput}
            control={control}
            defaultValue=""
            type="password"
            label="Contraseña"
            name="password"
            placeholder="SuperSecreto@2020"
            rules={{
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres.' },
            }}
            {...addWhenFieldError('password', errors)}
          />

          <Controller
            as={CustomTextInput}
            control={control}
            defaultValue=""
            type="password"
            label="Confirmar contraseña"
            name="confirm"
            placeholder="SuperSecreto@2020"
            rules={{
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres.' },
              validate: (val) => {
                const pass = getValues('password')
                if (val !== pass) {
                  return 'No coincide con la nueva contraseña'
                }
                return true
              },
            }}
            {...addWhenFieldError('confirm', errors)}
          />

          <div className="action-buttons">
            <Button className="submit" appearance="primary" type="submit" isLoading={sending}>
              {!sending ? 'Enviar' : 'Enviando'}
            </Button>
          </div>
        </form>
      </ResetPasswordS>
    </AuthForm>
  )
}

const ResetPasswordS = styled.div`
  h1 {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.text.dark};
  }

  label {
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    color: ${colors.text.default};
  }

  input[type='text'],
  input[type='password'],
  .instructions {
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  .instructions {
    margin-bottom: 20px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .forgot {
    text-decoration: none;
    color: ${colors.blue.B9};
    margin-right: 14px;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .submit {
    display: flex;
    align-self: right;
  }
`;
