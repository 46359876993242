import { useEffect, useLayoutEffect } from 'react'

export default function useEffectOnceDepsDef(callback, deps = [], key) {
  // const didHappenRef = useRef(false)

  useLayoutEffect(() => {
    window[`didHappenRef_${key}`] = false
    return () => {
      delete window[`didHappenRef_${key}`]
    }
  }, [key])

  useEffect(() => {
    // console.log('useEffectOnDepsDef')

    let ready = false;
    let notReady;

    if (deps.length > 0) {
      deps.forEach((dep) => {
        if (typeof dep === "undefined") {
          notReady = true;
        }
      });
    }

    if (!notReady) {
      ready = true;
    }

    if (ready && !window[`didHappenRef_${key}`]) {
      window[`didHappenRef_${key}`] = true;
      // console.log('useEffectOnDepsDef done')
      callback && callback();
    }
  })

  return null
}
