import React from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import { DefaultPalette } from '@fluentui/react/lib/Styling';


export default function CloseButton({ onClick, ariaLabel }) {
  return (
    <IconButton
      styles={{
        root: {
          color: DefaultPalette.neutralPrimary,
          marginLeft: 'auto',
          marginTop: '4px',
          marginRight: '2px',
        },
        rootHovered: {
          color: DefaultPalette.neutralDark,
        }
      }}
      iconProps={{ iconName: 'Cancel' }}
      ariaLabel={ariaLabel || 'Cerrar'}
      onClick={() => onClick && onClick()}
    />
  )
}

