import React, { useRef, useEffect } from 'react'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import CustomModal from '@Components/CustomModal'
import { useCapturaProvisionalesStore } from '@Stores/storeCapturaProvisionales'
import { Pane } from 'evergreen-ui'
import StepVisualizer from './FormModalStepVisualizer'
import { Text } from '@fluentui/react/lib/Text'
import Cap from 'lodash/capitalize'
import create, { LogLevel } from 'zustand-store-addons'
import Step1 from './FormModalStep1'
import Step2 from './FormModalStep2'
import Step3 from './FormModalStep3'
import Step4 from './FormModalStep4'
import Step5 from './FormModalStep5'
import Step6Submit from './FormModalStep6Submit'
import { queryCache } from '@Root/index'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import isBetween from 'dayjs/plugin/isBetween';
import jsPDF from "jspdf";
import SegoeUI from "../Calculo/jsFonts/SegoeUIRegular";
import SegoeUIBold from "../Calculo/jsFonts/SegoeUIBold";

dayjs.extend(isBetween);
dayjs.locale("es");

const customFonts = [
  {
    fullName: "Segoe_UI",
    font: SegoeUI,
    weight: "400",
  },
  {
    fullName: "Segoe_UI_Bold",
    font: SegoeUIBold,
    weight: "700",
  },
];

jsPDF.API.events.push([
  "addFonts",
  function () {
    customFonts.forEach((customFont) => {
      this.addFileToVFS(`${customFont.fullName}.ttf`, customFont.font);
      this.addFont(
        `${customFont.fullName}.ttf`,
        customFont.fullName,
        "normal",
        customFont.weight
      );
    });
  },
]);

const steps = [
  { label: "Periodo", name: "periodo" },
  { label: "Emitido", name: "emitido" },
  { label: "Recibido", name: "recibido" },
  { label: "Nóminas", name: "nominas" },
  { label: "Revisión", name: "revision" },
];

export const capturaCampos = {
  emitidos: {
    pue: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    ppd: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    complementos: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    por_comprobar: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
  },
  recibidos: {
    pue: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    ppd: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    complementos: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
    por_comprobar: { subtotal: 0, iva: 0, ret_isr: 0, ret_iva: 0 },
  },
  nominasPatron: {
    perc_nomina: 0,
    ispt: 0,
    perc_asimilados: 0,
    imss: 0,
    sar: 0,
    infonavit: 0,
    nomina_porciento_3: 0,
    ret_hon_asimilados: 0,
  },
  nominasObrero: {
    imss: 0,
    sar: 0,
    infonavit: 0,
  },
};

// const catFieldsToNumber = (category) => {
//   let newObj = {}
//   for (let [cat, fields] of Object.entries(category)) {
//     newObj[cat] = {}
//     Object.keys(fields).forEach((fieldName) => {
//       newObj[cat][fieldName] = Number(fields[fieldName])
//     })
//   }
//   return newObj
// }

const initialState = {
  provId: null,
  editable: true,
  readOnly: false,
  editingExisting: false,
  porComprobarEditable: true,
  periodicidad: undefined,
  periodicidadOptions: [
    { key: "bimestral", text: "Bimestral" },
    { key: "mensual", text: "Mensual" },
  ],
  ...JSON.parse(JSON.stringify({ ...capturaCampos })),
  periodo: undefined,
  negativos: undefined,
  periodoText: "",
  currentStep: 0,
  stepsComponent: undefined,

  loadProvisionalData(data) {
    // console.log(data);
  },
};

export const useFormStore = create(
  (set, get) => ({
    ...initialState,

    resetForm() {
      set({ ...initialState });
    },
  }),
  {
    computed: {
      periodoOptions() {
        if (this.periodicidad === undefined) return [];

        // if (this.periodo !== undefined && this.periodoText !== '') {

        // }

        // const today = dayjs()
        const start = useCapturaProvisionalesStore.getState().startDate;
        const end = start.startOf("month").add(1, "M");

        let options = [];

        if (this.periodicidad.key === "mensual") {
          // const capturas = useCapturaProvisionalesStore.getState().selectedClient?.provisionales;
          // console.log('capturas', this.provisionalesCapturados);

          const mes1Inicio = start.format("YYYY-MM-DD");
          const mes1Final = start.endOf("month").format("YYYY-MM-DD");
          const mes1 = {
            key: `${mes1Inicio} ${mes1Final}`,
            text: `${Cap(start.format("MMM YYYY"))}`,
          };

          const mes2Inicio = end.startOf("month").format("YYYY-MM-DD");
          const mes2Final = end.endOf("month").format("YYYY-MM-DD");
          const mes2 = {
            key: `${mes2Inicio} ${mes2Final}`,
            text: `${Cap(end.format("MMM YYYY"))}`,
          };

          options.push(mes1);
          options.push(mes2);

          // if (!this.editingExisting) {
          //   if (this.provisionalesCapturados.length > 0) {
          //     this.provisionalesCapturados.forEach(prov => {
          //       const fechaProv = dayjs(prov.fecha_inicial);
          //       if (fechaProv.isBetween(dayjs(mes1Inicio), dayjs(mes1Final), null, '[]')) {
          //         options.splice(0,1);
          //       } else if (fechaProv.isBetween(dayjs(mes2Inicio), dayjs(mes2Final), null, '[]')) {
          //         options.splice(1,1);
          //       }
          //     })
          //   }
          // }
        } else if (this.periodicidad.key === "bimestral") {
          options.push({
            key: `${start.format("YYYY-MM-DD")} ${end
              .endOf("month")
              .format("YYYY-MM-DD")}`,
            text: `${Cap(start.format("MMM YYYY"))} - ${Cap(
              end.format("MMM YYYY")
            )}`,
          });
        }

        return options;
      },
      step1Ready() {
        return this.periodicidad !== undefined && this.periodo !== undefined;
      },
      step2Ready() {
        return true;
      },
      step3Ready() {
        return true;
      },
      step4Ready() {
        return true;
      },
      step5Ready() {
        return true;
      },
    },

    watchers: {
      periodicidad(newVal) {
        if (typeof newVal !== "undefined") {
          const start = useCapturaProvisionalesStore.getState().startDate;
          const mes1Inicio = start.format("YYYY-MM-DD");
          const mes1Final = start.endOf("month").format("YYYY-MM-DD");
          const mes1 = {
            key: `${mes1Inicio} ${mes1Final}`,
            text: `${Cap(start.format("MMM YYYY"))}`,
          };
          this.set({
            periodo: mes1,
            periodoText: mes1.text,
          });
          // this.get('stepsComponent').current.stepForward()
        }
      },
      stepsComponent(newVal, oldValue) {
        if (typeof newVal !== "undefined" && typeof oldValue === "undefined") {
          if (this.get("step1Ready")) {
            setTimeout(() => {
              newVal.stepForward();
            }, 0);
          }
        }
      },
    },

    settings: {
      name: "formaProv",
      logLevel: LogLevel.Diff,
    },
  }
);

export default function FormModal() {
  const [formVisible, selectedClient] = useCapturaProvisionalesStore(
    "formVisible, selectedClient"
  );
  const stepsRef = useRef();
  const [currentStep, periodoText] = useFormStore("currentStep, periodoText");
  const [currentStepReady, readOnly] = useFormStore(
    `step${currentStep + 1}Ready, readOnly`
  );

  useEffect(() => {
    if (formVisible && selectedClient) {
      useFormStore.setState({
        // provisionalesCapturados: selectedClient.provisionales,
        periodicidad: "mensual",
      });
      // loadProvisionalData(selectedClient);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible, selectedClient]);

  function onDismissed() {
    useCapturaProvisionalesStore.setState({
      selectedClient: null,
    });
    useFormStore.getState().resetForm();
  }

  function onClose() {
    useCapturaProvisionalesStore.setState({
      formVisible: false,
    });
    useFormStore.setState({ negativos: {}})
  }

  function onCancel() {
    useCapturaProvisionalesStore.setState({
      formVisible: false,
    });
    useFormStore.setState({ negativos: {}})
  }

  function onSubmit() {
    const qp = useCapturaProvisionalesStore.getState().getProvisionalesParams;
    queryCache.refetchQueries([
      "provisionalesList",
      {
        fecha_inicial: qp.fecha_inicial,
        fecha_final: qp.fecha_final,
        order_by: qp?.order_by ?? "razon_social",
        personas: qp?.tipo_persona ?? "",
        prioridad: qp?.prioridad ?? "",
        razon_social: qp?.razon_social ?? "",
        rfc: qp?.rfc ?? "",
        socio: qp?.socio ?? "",
        numero_control: qp?.numero_control ?? "",
        uniq: qp?.uniq ?? 0,
        [qp?.direction]: qp?.results ?? 20,
      },
    ]);

    onClose();
  }

  function onModificar() {
    useFormStore.setState({
      editingExisting: true,
      editable: true,
      readOnly: false,
      currentStep: 0,
    });
  }

  function onExportPDF() {
    // console.log("export");
    const doc = new jsPDF({
      compress: true,
      unit: "px",
      format: "letter",
      orientation: "portrait",
    });

    doc.html(document.querySelector(".modalCapturaPago .ms-Dialog-main"), {
      image: { quality: 8, type: "png" },
      html2canvas: {
        width: 1836 / 2,
        height: 2376 / 2,
        scale: 0.2498 * 2.5,
        useCORS: true,
        removeContainer: true,
        ignoreElements: (element) => {
          if (
            typeof element.className === "string" &&
            (element.className.includes("ms-Button-flexContainer") ||
              element.className.includes("ms-Button"))
          ) {
            return true;
          }
          return false;
        },
      },
      x: 0,
      y: 0,
      margin: 0,
      callback: (doc) => {
        doc.deletePage(2);
        doc
          .save(
            `CapturaMovimientos_${selectedClient.rfc}_${periodoText.replace(
              /\s/g,
              ""
            )}.pdf`,
            { returnPromise: true }
          )
          .then(() => {});
      },
    });
  }

  return (
    <CustomModal
      title={"Captura de pago provisional"}
      isOpen={formVisible}
      isBlocking={true}
      onDismissed={onDismissed}
      onClose={onClose}
      unClosable={currentStep === 5}
      wide
      className="modalCapturaPago"
    >
      <Pane>
        <div>
          <p style={{ fontFamily: "Segoe_UI_Bold", fontWeight: "700" }}>
            {selectedClient?.razon_social}
          </p>
          <Text
            variant="smallPlus"
            style={{ fontFamily: "Segoe_UI", fontWeight: "400" }}
            block
          >
            {selectedClient?.rfc}
          </Text>
          {!readOnly && (
            <Text
              variant="smallPlus"
              block
              style={{
                fontFamily: "Segoe_UI",
                fontWeight: "400",
                marginTop: 10,
              }}
            >
              {periodoText}
            </Text>
          )}

          <Pane marginTop={40}>
            {!readOnly && (
              <StepVisualizer
                ref={(ref) => {
                  stepsRef.current = ref;
                  useFormStore.setState({ stepsComponent: ref });
                }}
                steps={steps}
                style={{ width: 300 }}
                onStepChange={(stepIndex) =>
                  useFormStore.setState({ currentStep: stepIndex })
                }
              />
            )}

            {currentStep === 0 && <Step1 />}
            {currentStep === 1 && <Step2 />}
            {currentStep === 2 && <Step3 />}
            {currentStep === 3 && <Step4 />}
            {currentStep === 4 && <Step5 />}
            {currentStep === 5 && <Step6Submit onSubmit={onSubmit} />}

            {currentStep !== 5 && (
              <Pane
                marginTop={40}
                marginBottom={10}
                display="flex"
                justifyContent="flex-end"
              >
                <Pane display="flex" flex={1}>
                  {readOnly ? (
                    <>
                      <DefaultButton
                        style={{ marginRight: 8 }}
                        text="Modificar"
                        onClick={() => onModificar()}
                        allowDisabledFocus
                        disabled={false}
                      />
                      <DefaultButton
                        style={{ marginRight: 8 }}
                        text="Exportar PDF"
                        onClick={() => onExportPDF()}
                        allowDisabledFocus
                        disabled={false}
                      />
                    </>
                  ) : (
                    <DefaultButton
                      style={{ marginRight: 8 }}
                      text="Cancelar"
                      onClick={() => onCancel()}
                      allowDisabledFocus
                      disabled={false}
                    />
                  )}
                </Pane>
                <Pane>
                  {currentStep > 1 && !readOnly && (
                    <DefaultButton
                      style={{ marginRight: 8 }}
                      text="Regresar"
                      onClick={() => stepsRef.current.stepBackward()}
                      allowDisabledFocus
                      disabled={false}
                    />
                  )}
                  {!readOnly ? (
                    <PrimaryButton
                      text={currentStep === 4 ? "Guardar" : "Siguiente"}
                      type="button"
                      onClick={() => stepsRef.current.stepForward()}
                      allowDisabledFocus
                      disabled={!currentStepReady}
                    />
                  ) : (
                    <PrimaryButton
                      text="Cerrar"
                      type="button"
                      onClick={() => onClose()}
                      allowDisabledFocus
                      disabled={!currentStepReady}
                    />
                  )}
                </Pane>
              </Pane>
            )}
          </Pane>
        </div>
      </Pane>
    </CustomModal>
  );
}
