import React, { useEffect, useLayoutEffect } from "react";
import { Pane } from "evergreen-ui";
import { IconButton } from "@fluentui/react/lib/Button";
import { useCalculoStore } from "./CalculoProvisionalesModalStore";
import {
  allCuentasKeys,
  useEquivalenciasStore,
} from "./CalculoProvisionalesEqStore";
import EquivalenciasRow from "./EquivalenciasRow";
import "./EquivalenciasRow.scss";

// const keysToEmptyObjectsArray = (cuentasKeysArr) => {
//   let cuentas = {};
//   cuentasKeysArr.forEach((cuentaKey) => {
//     cuentas[cuentaKey] = {};
//   });
//   return cuentas;
// };

export default function CalculoProvisionalesModalStep2({ autoSave = false }) {
  const eqStore = useEquivalenciasStore();
  const editableOnlyCuentas = ["deudores", "activoFijo", "acreedores"];
  const [existingCalculo, editableMode, previousCalculo] = useCalculoStore(`
    existingCalculo, editableMode, previousCalculo
  `);

  useEffect(() => {
    if (previousCalculo) {
      // console.log("useLayoutEffect prevCalculo ModalStep2");
      const sumSaldoFinalCuenta = (cuenta) => {
        let sumatoria = 0;
        previousCalculo.data_json.equivalencias[cuenta].forEach((row) => {
          sumatoria += row.saldoFinal;
        });
        return sumatoria;
      };

      const replaceSaldoInicial = (cuenta) => {
        const cuentaData = useEquivalenciasStore.getState()[cuenta];
        return {
          ...cuentaData,
          filas: [
            {
              ...cuentaData.filas[0],
              saldoInicial: sumSaldoFinalCuenta(cuenta),
            },
            ...cuentaData.filas.filter((_, i) => i > 0),
          ],
        };
      };

      const replaceSaldosFinales = (cuentaData) => {
        return {
          ...cuentaData,
          filas: cuentaData.filas.map((row) => ({
            ...row,
            saldoFinal: row.saldoInicial + row.debe - row.haber,
          })),
        };
      };

      useEquivalenciasStore.setState({
        bancos: replaceSaldosFinales(replaceSaldoInicial("bancos")),
        cuentasPorCobrar: replaceSaldosFinales(
          replaceSaldoInicial("cuentasPorCobrar")
        ),
        deudores: replaceSaldosFinales(replaceSaldoInicial("deudores")),
        ivaAcreditable: replaceSaldosFinales(
          replaceSaldoInicial("ivaAcreditable")
        ),
        activoFijo: replaceSaldosFinales(replaceSaldoInicial("activoFijo")),
        cuentasPorPagar: replaceSaldosFinales(
          replaceSaldoInicial("cuentasPorPagar")
        ),
        acreedores: replaceSaldosFinales(replaceSaldoInicial("acreedores")),
        impuestosPorPagar: replaceSaldosFinales(
          replaceSaldoInicial("impuestosPorPagar")
        ),
        impuestosFavor: replaceSaldosFinales(
          replaceSaldoInicial("impuestosFavor")
        ),
        ivaPorPagar: replaceSaldosFinales(replaceSaldoInicial("ivaPorPagar")),
        ventas: replaceSaldosFinales(replaceSaldoInicial("ventas")),
        deducciones: replaceSaldosFinales(replaceSaldoInicial("deducciones")),
        percepciones: replaceSaldosFinales(replaceSaldoInicial("percepciones")),
        asimilados: replaceSaldosFinales(replaceSaldoInicial("asimilados")),
        imss: replaceSaldosFinales(replaceSaldoInicial("imss")),
        sar: replaceSaldosFinales(replaceSaldoInicial("sar")),
        infonavit: replaceSaldosFinales(replaceSaldoInicial("infonavit")),
        sNomina: replaceSaldosFinales(replaceSaldoInicial("sNomina")),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousCalculo]);

  useLayoutEffect(() => {
    let timeoutId;
    if (existingCalculo && editableMode && autoSave) {
      // console.log("Step 2 CalculoProvisionalesModalStep2");
      timeoutId = setTimeout(() => {
        useCalculoStore.getState().stepForward();
      }, 3000);
    }
    // if (autoSave) {
    //   console.log("autoSave step 2");
    //   setTimeout(() => {
    //     // document.querySelector(".capturaProvContinueBtn").click();
    //     useCalculoStore.getState().stepForward();
    //   }, 1000);
    // }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [existingCalculo, editableMode, autoSave]);

  const totalDebe = eqStore.sumDebe.toLocaleString("es-MX", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const totalHaber = eqStore.sumHaber.toLocaleString("es-MX", {
    useGrouping: true,
    minimumFractionDigits: 2,
  });

  const balanced = totalDebe === totalHaber;

  return (
    <Pane marginTop={40}>
      <h2 style={{ fontFamily: "Segoe_UI" }}>Tabla de equivalencias</h2>
      <Pane marginTop={20}>
        {allCuentasKeys.map((cuentaKey, cuentaIndex) => {
          const filasCuenta = eqStore[cuentaKey].filas;
          return filasCuenta.map((cuentaFila, cuentaFilaIndex, cuentaFilas) => {
            const readOnly =
              (existingCalculo && !editableMode) ||
              (!editableOnlyCuentas.includes(cuentaKey) &&
                cuentaFilaIndex === 0);
            return (
              <EquivalenciasRow
                key={cuentaFilaIndex}
                cuenta={
                  <div className="cuenta-name-container">
                    {cuentaFilaIndex > 0 && (
                      <IconButton
                        iconProps={{ iconName: "Blocked2Solid" }}
                        title="Borrar fila"
                        ariaLabel="Borrar fila"
                        disabled={existingCalculo && !editableMode}
                        onClick={() =>
                          eqStore.deleteRowFromCuenta(
                            cuentaKey,
                            cuentaFilaIndex
                          )
                        }
                      />
                    )}
                    <div className="nombre-cuenta">
                      {eqStore[cuentaKey].nombre}
                      {cuentaFilaIndex > 0 && (
                        <span>{` ${cuentaFilaIndex + 1}`}</span>
                      )}
                    </div>
                    {cuentaFilaIndex === 0 &&
                      !editableOnlyCuentas.includes(cuentaKey) &&
                      cuentaFilas.length < 2 &&
                      ((!existingCalculo && !editableMode) ||
                        (existingCalculo && editableMode)) && (
                        <IconButton
                          iconProps={{ iconName: "AddTo" }}
                          title="Agregar fila"
                          ariaLabel="Agregar fila"
                          onClick={() => eqStore.addRowToCuenta(cuentaKey)}
                        />
                      )}
                  </div>
                }
                heading={cuentaIndex === 0 && cuentaFilaIndex === 0}
                readOnly={readOnly}
                rowIndex={cuentaFilaIndex}
                dataCuenta={cuentaKey}
                saldoInicial={
                  eqStore[cuentaKey].filas[cuentaFilaIndex].saldoInicial
                }
                debe={eqStore[cuentaKey].filas[cuentaFilaIndex].debe}
                haber={eqStore[cuentaKey].filas[cuentaFilaIndex].haber}
                saldoFinal={
                  eqStore[cuentaKey].filas[cuentaFilaIndex].saldoFinal
                }
              />
            );
          });
        })}
      </Pane>
      <Pane marginTop={20}>
        <div className="equivalencias-row-container">
          <div className="column" />
          <div className="column" />
          <div className="column">
            <div className="content">
              <p
                style={{ fontFamily: "Segoe_UI_SemiBold" }}
                className={`num-field bold ${balanced ? "green" : "red"}`}
              >
                {totalDebe}
              </p>
            </div>
          </div>
          <div className="column">
            <div className="content">
              <p
                style={{ fontFamily: "Segoe_UI_SemiBold" }}
                className={`num-field bold ${balanced ? "green" : "red"}`}
              >
                {totalHaber}
              </p>
            </div>
          </div>
          <div className="column" />
        </div>
      </Pane>
    </Pane>
  );
}
