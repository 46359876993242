import { Badge, Pane } from 'evergreen-ui';
import React from 'react';

export default function RazonSocial({ cliente, bold = false, style = {} }) {
  const fisica = cliente.tipo_persona === "fisica";
  return (
    <Pane display="flex">
      <Badge
        color={fisica ? "green" : "blue"}
        width={20}
        justifyContent="center"
        display="flex"
        marginRight={6}
      >
        {fisica ? "F" : "M"}
      </Badge>
      <span
        style={{
          ...(bold && { fontWeight: 600 }),
          ...style,
        }}
      >
        {cliente.razon_social}
      </span>
    </Pane>
  );
}
