import React from 'react';
import NumericTextField from '@Components/NumericTextField';
import { useEquivalenciasStore } from "./CalculoProvisionalesEqStore";
// import './EquivalenciasRow.scss';

const EquivalenciasRowColumn = ({ heading, children }) => (
  <div className="column">
    {heading && (
      <div className="heading" style={{ fontFamily: "Segoe_UI_Bold" }}>
        {heading}
      </div>
    )}
    <div className="content">{children}</div>
  </div>
);

const localeNumToNumber = (mayBeStringNum) => {
  if (typeof mayBeStringNum === "number") return mayBeStringNum;
  return Number(mayBeStringNum.replace(/,/gi, ""));
};

export default function EquivalenciasRow({
  cuenta,
  saldoInicial = 0,
  debe = 0,
  haber = 0,
  // saldoFinal = Number(saldoInicial) + Number(debe) - Number(haber),
  saldoFinal = 0,
  heading = false,
  readOnly = false,
  rowIndex,
  dataCuenta,
}) {
  return (
    <div
      className={`equivalencias-row-container ${readOnly ? "read-only" : ""}`}
      data-cuenta={dataCuenta}
    >
      <EquivalenciasRowColumn heading={heading ? "CUENTA" : undefined}>
        <div style={{ marginLeft: 6, fontFamily: "Segoe_UI" }}>{cuenta}</div>
      </EquivalenciasRowColumn>
      <EquivalenciasRowColumn heading={heading ? "SI" : undefined}>
        <NumericTextField
          keyData={`${dataCuenta}_saldoInicial`}
          data-cuenta-col="saldoInicial"
          defaultValue={saldoInicial}
          readOnly={readOnly}
          {...(!readOnly && {
            onUpdate: (newValue) => {
              useEquivalenciasStore
                .getState()
                .onValueChanged(
                  dataCuenta,
                  rowIndex,
                  "saldoInicial",
                  localeNumToNumber(newValue)
                );
            },
          })}
        />
      </EquivalenciasRowColumn>
      <EquivalenciasRowColumn heading={heading ? "D" : undefined}>
        <NumericTextField
          keyData={`${dataCuenta}_debe`}
          data-cuenta-col="debe"
          defaultValue={debe}
          readOnly={readOnly}
          {...(!readOnly && {
            onUpdate: (newValue) => {
              useEquivalenciasStore
                .getState()
                .onValueChanged(
                  dataCuenta,
                  rowIndex,
                  "debe",
                  localeNumToNumber(newValue)
                );
            },
          })}
        />
      </EquivalenciasRowColumn>
      <EquivalenciasRowColumn heading={heading ? "H" : undefined}>
        <NumericTextField
          keyData={`${dataCuenta}_haber`}
          data-cuenta-col="haber"
          defaultValue={haber}
          readOnly={readOnly}
          {...(!readOnly && {
            onUpdate: (newValue) => {
              useEquivalenciasStore
                .getState()
                .onValueChanged(
                  dataCuenta,
                  rowIndex,
                  "haber",
                  localeNumToNumber(newValue)
                );
            },
          })}
        />
      </EquivalenciasRowColumn>
      <EquivalenciasRowColumn heading={heading ? "SF" : undefined}>
        <NumericTextField
          keyData={`${dataCuenta}_saldoFinal`}
          data-cuenta-col="saldoFinal"
          defaultValue={saldoFinal}
          readOnly={true}
        />
      </EquivalenciasRowColumn>
    </div>
  );
}
