import React, { useEffect } from "react";
import { Pane } from "evergreen-ui";
import { useCalculoStore } from "./CalculoProvisionalesModalStore";
import { SpinnerSize, Spinner } from "@fluentui/react/lib/Spinner";

export default function CalculoProvisionalesModalStep3() {
  useEffect(() => {
    useCalculoStore.getState().onSaveCalculo();
  }, []);

  return (
    <Pane
      marginTop={40}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner size={SpinnerSize.large} label="Guardando..." />
    </Pane>
  );
}
