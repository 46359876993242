import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form';
import { TextInputField, Button, toaster, EyeOpenIcon, EyeOffIcon } from 'evergreen-ui';
import colors from '@Root/style/colors';
import AuthForm from '@Root/layout/AuthForm';
import { validateEmail, addWhenFieldError } from '@Utils/index'
import axios from '@Utils/axios';
import { useUserState } from '@Stores/storeUser'
import { ReactComponent as Logo } from '@Root/assets/onlineServices.svg'

const CustomTextInput = React.forwardRef(({ rightIcon, type, ...props }, ref) => {

  const [showText, setShowText] = useState(false);

  const inputType = type !== 'password'
    ? type
    : showText ? 'text' : 'password';

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <TextInputField ref={ref} {...props} type={inputType} />
      <Button
        appearance="minimal"
        type="button"
        intent="none"
        onClick={() =>
          setShowText((curr) => {
            return !curr
          })
        }
        style={{ position: 'absolute', right: 0, top: 24 }}
      >
        {showText ? (
          <EyeOpenIcon style={{ color: '#9e9fa0' }} />
        ) : (
          <EyeOffIcon style={{ color: '#9e9fa0' }} />
        )}
      </Button>
    </div>
  )
});

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .logo {
    rect {
      fill: white !important;
      opacity: 0.8;
    }
  }
`

export default function Login() {

  const history = useHistory();
  const location = useLocation()
  const [sending, setSending] = useState(false);
  const { handleSubmit, errors, control, setError } = useForm();
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    setLoading(true)
    useUserState
      .getState()
      .getUserData()
      .then((userData) => {
        if (userData) {
          history.push(location.state?.from ?? '/home')
        } else {
          setLoading(false)
        }
      })

    return () => {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <LoadingScreen>
        <Logo className="logo" />
      </LoadingScreen>
    )
  }

  async function onSubmit(formData) {
    setSending(true);

    const response = await axios.post('/api/v1/auth/adminLogin', formData)
      .catch(err => console.log(err));

    setSending(false);
    if (response?.data?.id) {
      useUserState.getState().onLogin(response.data, history);
    } else {
      onWrongCredentials();
    }
  }

  function onWrongCredentials() {
    toaster.danger('No se pudo iniciar la sesión', {
      description: 'La combinación de email y contraseña es incorrecta.'
    });
    setError('email', 'notOk', 'Revisa la información proporcionada.');
    setError('password', 'notOk', 'Revisa la información proporcionada.');
  }

  return (
    <AuthForm>
      <LoginS>
        <h1>Acceso de administración</h1>
        <p className="instructions">Proporciona tus datos para iniciar sesión.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            as={TextInputField}
            control={control}
            defaultValue=""
            label="Email"
            name="email"
            placeholder="usuario@gmail.com"
            rules={{
              required: 'Este campo es requerido',
              validate: (val) => validateEmail(val) || 'Por favor ingresa un email válido.',
            }}
            {...addWhenFieldError('email', errors)}
          />
          <Controller
            as={CustomTextInput}
            control={control}
            defaultValue=""
            type="password"
            label="Contraseña"
            name="password"
            placeholder="327t2nb_SDgwr8"
            rules={{
              required: 'Este campo es requerido',
              minLength: { value: 5, message: 'Mínimo 5 caracteres.' },
            }}
            {...addWhenFieldError('password', errors)}
          />

          <div className="action-buttons">
            <Link className="forgot" to="/auth/forgot">
              Olvidé mi contraseña
            </Link>
            <Button className="submit" appearance="primary" isLoading={sending} type="submit">
              {!sending ? 'Enviar' : 'Enviando'}
            </Button>
          </div>
        </form>
      </LoginS>
    </AuthForm>
  )
}

const LoginS = styled.div`

  h1 {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.text.dark};
  }

  label {
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    width: 300px;
    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
    color: ${colors.text.default};
  }

  .instructions {
    margin-bottom: 20px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  input[type=text], input[type=password], .instructions {
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .forgot {
    text-decoration: none;
    color: ${colors.blue.B9};
    margin-right: 14px;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 12px;
  }

  .submit {
    display: flex;
    align-self: right;
  }
`;
