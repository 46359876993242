import React from 'react';
import { Pane } from 'evergreen-ui';
import NumericTextField from '@Components/NumericTextField';
import NumericTextField4d from "@Components/NumericTextField4d";
import { useCalculoStore } from "./CalculoProvisionalesModalStore";
import PerdidasField from "./PerdidasField";
import styled from "styled-components";

export default function CalculoProvisionalesModalStep1() {
  const [
    clientData,
    isr_factor,
    isr_facturacion,
    isr_facturacion_acumulada,
    isr_balance,
    isr_balance_acumulado,
    isr_utilidad_estimada,
    isr_isr,
    isr_efectuado,
    isr_retenido,
    isr_pagar,
  ] = useCalculoStore(`
    clientData,
    isr_factor,
    isr_facturacion,
    isr_facturacion_acumulada,
    isr_balance,
    isr_balance_acumulado,
    isr_utilidad_estimada,
    isr_isr,
    isr_efectuado,
    isr_retenido,
    isr_pagar
  `);

  const [
    iva_iva_trasladado,
    iva_iva_acreditable,
    iva_iva_retenido,
    iva_factor,
    iva_iva_favor,
    iva_efectuado,
    iva_iva_declarar,
  ] = useCalculoStore(`
    iva_iva_trasladado,
    iva_iva_acreditable,
    iva_iva_retenido,
    iva_factor,
    iva_iva_favor,
    iva_efectuado,
    iva_iva_declarar
  `);

  const [
    isr_salarios,
    isr_asimilados,
    totales_iva_retenido,
    totales_isr_arrendamiento,
    total_pagar,
  ] = useCalculoStore(`
    isr_salarios,
    isr_asimilados,
    totales_iva_retenido,
    totales_isr_arrendamiento,
    total_pagar
  `);

  const [existingCalculo, editableMode] = useCalculoStore(
    `existingCalculo, editableMode`
  );

  const tipoPersona = clientData?.tipo_persona ?? "fisica";
  const forceFactorEnCeros =
    tipoPersona === "fisica" && isr_balance_acumulado < 0;
  const readOnlyMode = existingCalculo && !editableMode;

  return (
    <PaneContainer width="100%">
      <div className="categories-container">
        <div className="category-container" style={{ width: "45%" }}>
          <h3>ISR</h3>
          <div className="text-fields">
            {tipoPersona === "moral" ? (
              <React.Fragment>
                <NumericTextField
                  label="Facturación"
                  readOnly
                  defaultValue={isr_facturacion}
                  colored
                />
                <NumericTextField
                  label="Facturación Acum."
                  readOnly
                  defaultValue={isr_facturacion_acumulada}
                  colored
                />
                <NumericTextField
                  label="Utilidad Estimada"
                  readOnly
                  defaultValue={isr_utilidad_estimada}
                  colored
                />
                <PerdidasField />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <NumericTextField
                  label="Balance"
                  readOnly
                  defaultValue={isr_balance}
                  colored
                />
                <NumericTextField
                  label="Balance Acum."
                  readOnly
                  defaultValue={isr_balance_acumulado}
                  colored
                />
              </React.Fragment>
            )}

            <NumericTextField4d
              label="Factor"
              defaultValue={isr_factor}
              readOnly={readOnlyMode}
              colored
              onUpdate={(newValue) =>
                useCalculoStore.setState({ isr_factor: newValue })
              }
              {...(forceFactorEnCeros && { readOnly: true, colored: true })}
            />
            <NumericTextField
              label="ISR"
              readOnly
              defaultValue={isr_isr}
              colored
            />
            <NumericTextField
              label="ISR Retenido"
              readOnly
              defaultValue={isr_retenido}
              colored
            />
            <NumericTextField
              label="ISR Efectuado"
              defaultValue={isr_efectuado}
              readOnly={readOnlyMode}
              colored
              onUpdate={(newValue) =>
                useCalculoStore.setState({ isr_efectuado: newValue })
              }
            />
            <NumericTextField
              bold
              label="ISR a pagar"
              readOnly
              defaultValue={isr_pagar}
              colored
            />
          </div>
        </div>
        <div className="category-container" style={{ width: "45%" }}>
          <h3>IVA</h3>
          <div className="text-fields">
            <NumericTextField
              label="IVA Trasladado"
              readOnly
              defaultValue={iva_iva_trasladado}
              colored
            />
            <NumericTextField
              label="IVA Acreditable"
              readOnly
              defaultValue={iva_iva_acreditable}
              colored
            />
            <NumericTextField
              label="IVA Retenido"
              readOnly
              defaultValue={iva_iva_retenido}
              colored
            />
            <NumericTextField4d
              label="Factor"
              defaultValue={iva_factor}
              readOnly={readOnlyMode}
              colored
              onUpdate={(newValue) =>
                // console.log("iva_factor", newValue) ||
                useCalculoStore.setState({ iva_factor: Number(newValue) })
              }
            />
            <NumericTextField
              label="IVA a favor"
              readOnly
              defaultValue={iva_iva_favor}
              colored
            />
            <NumericTextField
              label="IVA Efectuado"
              defaultValue={iva_efectuado}
              readOnly={readOnlyMode}
              colored
              onUpdate={(newValue) =>
                useCalculoStore.setState({ iva_efectuado: newValue })
              }
            />
            <NumericTextField
              bold
              label="IVA por declarar"
              readOnly
              defaultValue={iva_iva_declarar}
              colored
            />
          </div>
        </div>
      </div>
      <div className="category-container resumen">
        <h3>Resumen</h3>
        <div className="text-fields">
          <NumericTextField
            label="Impuesto sobre la renta"
            readOnly
            defaultValue={isr_pagar}
            colored
          />
          <NumericTextField
            label="Impuesto al valor agregado"
            readOnly
            defaultValue={iva_iva_declarar}
            colored
          />
          <NumericTextField
            label="ISR Salarios"
            readOnly
            defaultValue={isr_salarios}
            colored
          />
          <NumericTextField
            label="ISR Asimilados"
            readOnly
            defaultValue={isr_asimilados}
            colored
          />
          <NumericTextField
            label="IVA Retenido"
            readOnly
            defaultValue={totales_iva_retenido}
            colored
          />
          <NumericTextField
            label="ISR Ret Arrendamiento y/u honorarios"
            readOnly
            defaultValue={totales_isr_arrendamiento}
            colored
          />
          <NumericTextField
            bold
            label="Total a pagar"
            readOnly
            defaultValue={total_pagar}
            colored
          />
        </div>
      </div>
    </PaneContainer>
  );
}

const PaneContainer = styled(Pane)`

h2 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 4px;
    text-align: center;
}
h3 {
    font-size: 18px;
    padding-bottom: 4px;
    padding-left: 4px;
    border-bottom: solid 2px rgb(0, 120, 212);
    font-family: 'Segoe_UI_SemiBold';
}
.categories-container {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
    @media only screen and (min-width: 767px) {
        flex-direction: row;
    }
}
.category-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    @media only screen and (min-width: 767px) {
        width: 48%;
    }
    .text-fields {
        margin-top: 20px;
        @media only screen and (min-width: 767px) {
            .num-field .ms-TextField-wrapper {
                display: flex;
                flex-direction: row;
            }
        }
        .num-field:last-child {
            margin-top: 14px;
        }
        .num-field label {
            flex: 1;
            padding: 8px 0px 1px;
            font-family: 'Segoe_UI_SemiBold';

            @media only screen and (min-width: 767px) {
                padding: 8px 0px;
                margin-right: 4px;
                text-align: right;
            }
        }
        .num-field input {
          font-family: 'Segoe_UI';
            text-align: right;
            flex: 1;
        }
    }
}
.category-container.resumen {
    width: 100% !important;
    .text-fields {
        @media only screen and (min-width: 767px) {
            .num-field label {
                flex: 0;
                padding: auto 4px;
                min-width: 200px;
                font-size: 14px;
            }
            .num-field.small label {
                font-size: 12px;
                padding: 0;
            }
            .num-field input {
                flex: 1;
            }
        }
    }
}
.nav-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}
`;

// const CategoriesContainer = styled.div`
//   display: flex;
//   margin-top: 30px;
//   justify-content: space-between;
//   margin-bottom: 30px;
//   flex-direction: column;
//   @media only screen and (min-width: 767px) {
//     flex-direction: row;
//   }
// `;
