import { useEffect } from 'react'
import { headerTitleAtom } from '../layout/Header'
import { useAtom } from 'jotai'

export default function useHeaderTitle(title = '') {
  const [, setHeaderTitle] = useAtom(headerTitleAtom)

  useEffect(() => {
    setHeaderTitle(title)

    return () => setHeaderTitle('')
  }, [setHeaderTitle, title])
}
