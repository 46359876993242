import axios from 'axios';
// import { useSessionStore, setIdToken, setRefreshIdToken } from './session'
// import { queryCache } from '@Root/index'
// // import jwtDecode from 'jwt-decode';

const urlProtocol = process?.env.REACT_APP_BACKEND_PROTOCOL;
const urlHost = process?.env.REACT_APP_BACKEND_HOST;
const urlPort = process?.env.REACT_APP_BACKEND_PORT;

console.log("urlProtocol", urlProtocol);
console.log("urlHost", urlHost);
console.log("urlPort", urlPort);

// export const baseURL =
//   process.env.NODE_ENV !== 'development' ? '/' : `${urlProtocol}://${urlHost}:${urlPort}`

export const baseURL =
  process?.env.REACT_APP_NODE_END === "staging"
    ? `${urlProtocol}://${urlHost}:${urlPort}`
    : "/";

console.log("NODE_END", process?.env.REACT_APP_NODE_END);

console.log(baseURL);

const instance = axios.create({
  baseURL,
  headers: {
    "x-auth-client": "cms",
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // console.info("response", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default instance;



