import create, { LogLevel } from "zustand-store-addons";

const localeNumToNumber = (mayBeStringNum) => {
  if (typeof mayBeStringNum === "number") return mayBeStringNum;
  return Number(mayBeStringNum.replace(/,/gi, ""));
};

const valoresEnCeros = () => ({
  saldoInicial: 0,
  debe: 0,
  haber: 0,
  saldoFinal: 0,
});

const composeCuenta = (nombre, extensible = true) => ({
  nombre,
  filas: [valoresEnCeros()],
  extensible,
});

export const allCuentasKeys = [
  "bancos",
  "cuentasPorCobrar",
  "deudores",
  "ivaAcreditable",
  "activoFijo",
  "cuentasPorPagar",
  "acreedores",
  "impuestosPorPagar",
  "impuestosFavor",
  "ivaPorPagar",
  "ventas",
  "deducciones",
  "percepciones",
  "asimilados",
  "imss",
  "sar",
  "infonavit",
  "sNomina",
];

const initialState = {
  bancos: composeCuenta("Bancos"),
  cuentasPorCobrar: composeCuenta("Cuentas x cobrar"),
  deudores: composeCuenta("Deudores", false),
  ivaAcreditable: composeCuenta("IVA Acreditable"),
  activoFijo: composeCuenta("Activo fijo", false),
  cuentasPorPagar: composeCuenta("Cuentas x pagar"),
  acreedores: composeCuenta("Acreedores", false),
  impuestosPorPagar: composeCuenta("Impuestos x pagar"),
  impuestosFavor: composeCuenta("Impuestos a favor"),
  ivaPorPagar: composeCuenta("IVA por pagar"),
  ventas: composeCuenta("Ventas"),
  deducciones: composeCuenta("Deducciones", false),
  percepciones: composeCuenta("Percepciones", false),
  asimilados: composeCuenta("Asimilados", false),
  imss: composeCuenta("IMSS", false),
  sar: composeCuenta("SAR", false),
  infonavit: composeCuenta("INFONAVIT", false),
  sNomina: composeCuenta("S/Nómina", false),
};

export const useEquivalenciasStore = create(
  (set, get) => ({
    ...initialState,

    resetState() {
      set(initialState);
    },

    addRowToCuenta(cuenta) {
      set({
        [cuenta]: {
          ...get()[cuenta],
          filas: [...get()[cuenta].filas, valoresEnCeros()],
        },
      });
    },

    deleteRowFromCuenta(cuenta, rowCuentaIndex) {
      set({
        [cuenta]: {
          ...get()[cuenta],
          filas: get()[cuenta].filas.filter((fila, i) => i !== rowCuentaIndex),
        },
      });
    },

    onValueChanged(cuenta, rowCuentaIndex, col, value) {
      const updatedFila = {
        ...get()[cuenta].filas.find((fila, i) => i === rowCuentaIndex),
      };

      updatedFila[col] = localeNumToNumber(value);
      updatedFila.saldoFinal =
        updatedFila.saldoInicial + updatedFila.debe - updatedFila.haber;

      set({
        [cuenta]: {
          ...get()[cuenta],
          filas: get()[cuenta].filas.map((fila, i) => {
            if (rowCuentaIndex !== i) return fila;
            return updatedFila;
          }),
        },
      });
    },
  }),
  {
    computed: {
      sumDebe() {
        let total = 0;
        [
          this.bancos,
          this.cuentasPorCobrar,
          this.deudores,
          this.ivaAcreditable,
          this.activoFijo,
          this.cuentasPorPagar,
          this.acreedores,
          this.impuestosPorPagar,
          this.impuestosFavor,
          this.ivaPorPagar,
          this.ventas,
          this.deducciones,
          this.percepciones,
          this.asimilados,
          this.imss,
          this.sar,
          this.infonavit,
          this.sNomina,
        ].forEach((cuenta) => {
          cuenta.filas.forEach((fila) => {
            total += fila.debe;
          });
        });
        return total;
      },

      sumHaber() {
        let total = 0;
        [
          this.bancos,
          this.cuentasPorCobrar,
          this.deudores,
          this.ivaAcreditable,
          this.activoFijo,
          this.cuentasPorPagar,
          this.acreedores,
          this.impuestosPorPagar,
          this.impuestosFavor,
          this.ivaPorPagar,
          this.ventas,
          this.deducciones,
          this.percepciones,
          this.asimilados,
          this.imss,
          this.sar,
          this.infonavit,
          this.sNomina,
        ].forEach((cuenta) => {
          cuenta.filas.forEach((fila) => {
            total += fila.haber;
          });
        });
        return total;
      },
    },

    settings: {
      name: "EqStore",
      logLevel: LogLevel.None,
    },
  }
);
