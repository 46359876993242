import React, { useEffect, useReducer, useRef } from 'react'
import { Text } from '@fluentui/react/lib/Text'
import { FontIcon } from '@fluentui/react/lib/Icon'
import { Pane } from 'evergreen-ui'
import styled from 'styled-components'

function reducer(state, newState) {
  const changes = typeof newState === 'function' ? newState(state) : newState
  return { ...state, ...changes }
}

const initialState = {
  currentStep: 0,
  completedStep: null,
}

const StepVisualizer = React.forwardRef(
  ({ initialStep = 0, steps, style = {}, onStepChange }, ref) => {
    const rIsFirstRender = useRef(true)
    const [state, setState] = useReducer(reducer, initialState)

    React.useImperativeHandle(ref, () => ({
      stepBackward: () => {
        const currentStep = state.currentStep
        if (currentStep === 0) return

        setState({ currentStep: currentStep - 1 })
        onStepChange && onStepChange(currentStep - 1)
      },
      stepForward: () => {
        const currentStep = state.currentStep
        if (currentStep === steps.length) return

        setState({ currentStep: currentStep + 1 })
        onStepChange && onStepChange(currentStep + 1)
      },
    }))

    useEffect(() => {
      if (initialStep !== 0 && rIsFirstRender.current) {
        rIsFirstRender.current = false
        setState({ currentStep: initialStep })
      }
    }, [initialStep])

    const placeStep = (index) => {
      if (index === 0) return 0
      const res = (index / (steps.length - 1)) * 100
      if (res > 100) return 100
      return res
    }

    const stepClasses = (index) => {
      const active = state.currentStep === index ? ' active' : ''
      const completed = index < state.currentStep ? ' completed' : ''
      return `step-icon${active}${completed}`
    }

    return (
      <StepsContainer style={style}>
        <div className="meter">
          <span className="progress-bar" style={{ width: `${placeStep(state.currentStep)}%` }}>
            <span className="progress"></span>
          </span>
          {steps &&
            steps.map((step, iStep) => (
              <div
                key={step.name}
                className={stepClasses(iStep)}
                style={{ left: `calc(${placeStep(iStep)}% - 10px)` }}
              >
                <span>
                  {iStep < state.currentStep ? <FontIcon iconName="CheckMark" /> : iStep + 1}
                </span>

                <Text className="label" variant="smallPlus">
                  {step.label}
                </Text>
              </div>
            ))}
        </div>
        <Pane height={200}></Pane>
      </StepsContainer>
    )
  }
)

export default StepVisualizer

const StepsContainer = styled.div`
  position: relative;
  /* width: 300px; */
  margin: 0 auto;
  height: 60px;

  .meter {
    position: relative;
    height: 4px;
    background-color: rgb(231, 234, 243);
    /* width: 60%; */
  }

  .meter span {
    display: block;
    height: 100%;
  }

  .progress {
    background-color: #00ad56;
    /* -webkit-animation: progressBar 3s ease-in-out;
    -webkit-animation-fill-mode: both;
    -moz-animation: progressBar 3s ease-in-out;
    -moz-animation-fill-mode: both; */
  }
  .progress-bar {
    transition: width 0.3s ease-out;
  }

  .step-icon {
    background: rgb(231, 234, 243);
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: -8px;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: background 0.3s ease-out;

    &.active {
      background: rgb(0, 120, 212);

      span {
        color: white;
      }

      .label {
        color: rgb(50, 49, 48);
      }
    }

    &.completed {
      background: #00ad56;

      span {
        color: white;
      }

      .label {
        color: rgb(50, 49, 48);
      }
    }

    span {
      position: absolute;
      line-height: 20px;
      font-size: 10px;
      color: rgb(50, 49, 48);
    }

    .label {
      margin-top: 40px;
      font-size: 12px;
      font-weight: normal;
      color: rgb(50, 49, 48);
    }
  }

  @-webkit-keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @-moz-keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
`
