import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { TextInputField, Button, ChevronLeftIcon, toaster } from 'evergreen-ui';
import AuthForm from '@Root/layout/AuthForm';
import colors from '@Root/style/colors';
import axios from '@Utils/axios';
import { validateEmail, wait, addWhenFieldError } from '@Utils/index';

export default function Forgot() {
  const shouldRedirectRef = useRef(true);
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { handleSubmit, errors, control } = useForm();

  useEffect(() => {
    return () => {
      shouldRedirectRef.current = false;
    }
  }, [])

  async function onSubmit(formData) {
    setSending(true);
    // await fakeSendData(formData);
    await axios.post('/api/v1/auth/adminForgot', formData).catch((err) => console.log(err))

    setSending(false);
    toaster.success('Instrucciones enviadas con éxito', {
      description: 'Por favor revisa tu correo y sigue los pasos para generar una nueva contraseña.',
    });
    await wait(5000);
    if (shouldRedirectRef.current) {
      // console.log('redirecting');
      history.push("/");
    }
  }

  return (
    <AuthForm>
      <ForgotS>
        <h1>Recuperar contraseña</h1>

        <p className="instructions">Ingresa tu email y recibirás instrucciones para generar una nueva contraseña.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={TextInputField}
          control={control}
          defaultValue=""
          label="Email"
          name="email"
          placeholder="usuario@gmail.com"
          rules={{
            required: 'Este campo es requerido',
            validate: val => validateEmail(val) || 'Por favor ingresa un email válido.',
          }}
          { ...addWhenFieldError('email', errors) }
        />

        <div className="action-buttons">
          <Link className="forgot" to="/auth/login">
            <ChevronLeftIcon />
            Regresar
          </Link>
          <Button
            className="submit"
            appearance="primary"
            isLoading={sending}
            type="submit"
          >
            { !sending ? 'Enviar' : 'Enviando' }
          </Button>
        </div>
        </form>
      </ForgotS>
    </AuthForm>
  )
}

const ForgotS = styled.div`

  h1 {
    font-family: 'Lato';
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.text.dark};
  }

  label {
    font-family: 'Lato';
    font-weight: bold;
    display: flex;
    color: ${colors.text.default};
  }

  input[type=text], input[type=password], .instructions {
    width: 300px;

    @media only screen and (max-width: 600px) {
      width: 80vw;
    }
  }

  .instructions {
    margin-bottom: 20px;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .forgot {
    text-decoration: none;
    color: ${colors.blue.B9};
    margin-right: 14px;
    font-family: 'Lato';
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .submit {
    display: flex;
    align-self: right;
  }

`;
