import React, { useRef, useState } from "react";
import NumericTextField from "@Components/NumericTextField";
import { DefaultButton, IconButton } from "@fluentui/react/lib/Button";
import { Callout } from "@fluentui/react/lib/Callout";
import { useCalculoStore } from "./CalculoProvisionalesModalStore";
import { useCalculoProvisionalesStore } from "@Stores/storeCalculoProvisionales";
import "./PerdidasField.scss";

/*
Perdidas button state

States:
- default (no se tiene un registro para el año en curso)
- editable (se tiene un registro para el año en curso pero no se ha usado)
- blocked (se tiene un registro para el año en curso y ya se usó)
*/

export default function PerdidasField() {
  const [perdidas, perdidas_state] = useCalculoStore(`
    perdidas_e_anteriores, perdidas_state
  `);

  const savedValueRef = useRef(0);

  const [infoCalloutVisible, setInfoCalloutVisible] = useState(false);
  const [blockedCalloutVisible, setBlockedCalloutVisible] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);

  return (
    <React.Fragment>
      <NumericTextField
        label="Pérdidas E.A."
        readOnly={!editEnabled}
        defaultValue={perdidas}
        colored
        onUpdate={(newValue) =>
          useCalculoStore.setState({ perdidas_e_anteriores: newValue })
        }
        labelButton={
          <React.Fragment>
            {perdidas_state === "default" && !editEnabled && (
              <IconButton
                id="infoButton"
                iconProps={{ iconName: "Info", style: { fontSize: 16 } }}
                title="Info"
                ariaLabel="Info"
                onClick={() => setInfoCalloutVisible(true)}
              />
            )}
            {perdidas_state === "editable" && !editEnabled && (
              <IconButton
                id="editButton"
                iconProps={{ iconName: "Edit", style: { fontSize: 16 } }}
                title="Editar"
                ariaLabel="Editar"
                onClick={() => {
                  useCalculoProvisionalesStore.setState({ canContinue: false });
                  setEditEnabled(true);
                  savedValueRef.current = useCalculoStore.getState().perdidas_e_anteriores;
                }}
              />
            )}
            {perdidas_state === "blocked" && (
              <IconButton
                id="lockButton"
                iconProps={{ iconName: "Lock", style: { fontSize: 16 } }}
                title="Bloqueado"
                ariaLabel="Bloqueado"
                onClick={() => setBlockedCalloutVisible(true)}
              />
            )}
            {editEnabled && (
              <>
                <IconButton
                  iconProps={{
                    iconName: "StatusCircleErrorX",
                    style: { fontSize: 16, color: "#bf0e08" },
                  }}
                  title="Close"
                  ariaLabel="Close"
                  onClick={() => {
                    useCalculoProvisionalesStore.setState({
                      canContinue: true,
                    });
                    setEditEnabled(false);
                    useCalculoStore.setState({
                      perdidas_e_anteriores: savedValueRef.current,
                    });
                    savedValueRef.current = 0;
                  }}
                />
                <IconButton
                  iconProps={{ iconName: "Save", style: { fontSize: 16 } }}
                  title="Save"
                  ariaLabel="Save"
                  onClick={() => {
                    useCalculoProvisionalesStore.setState({
                      canContinue: true,
                    });
                    setEditEnabled(false);
                    savedValueRef.current = 0;
                    useCalculoStore.getState().onSavePerdidasValue(perdidas);
                  }}
                />
              </>
            )}
          </React.Fragment>
        }
      />
      {blockedCalloutVisible && (
        <Callout
          className="info-callout"
          target={"#lockButton"}
          setInitialFocus
          onDismiss={() => setBlockedCalloutVisible(false)}
          role="alertdialog"
        >
          <div className="callout-content">
            <p>
              Este valor se encuentra bloqueado ya que existen cálculos
              provisionales donde fue utilizado
            </p>
            <div className="action-buttons">
              <DefaultButton onClick={() => setBlockedCalloutVisible(false)}>
                Ok
              </DefaultButton>
            </div>
          </div>
        </Callout>
      )}
      {infoCalloutVisible && (
        <Callout
          className="info-callout"
          target={"#infoButton"}
          setInitialFocus
          onDismiss={() => setInfoCalloutVisible(false)}
          role="alertdialog"
        >
          <div className="callout-content">
            <p>
              Este cliente no tiene una pérdida registrada correspondiente al
              ejercicio anterior. <br />
              <br />
              <span>¿Deseas capturarla ahora?</span>
            </p>
            <div className="action-buttons">
              <DefaultButton onClick={() => setInfoCalloutVisible(false)}>
                No
              </DefaultButton>
              <DefaultButton
                onClick={() => {
                  useCalculoProvisionalesStore.setState({ canContinue: false });
                  setEditEnabled(true);
                  setInfoCalloutVisible(false);
                  savedValueRef.current = useCalculoStore.getState().perdidas_e_anteriores;
                }}
              >
                Sí
              </DefaultButton>
            </div>
          </div>
        </Callout>
      )}
    </React.Fragment>
  );
}
