import create, { LogLevel } from "zustand-store-addons";
import axios from "@Utils/axios";
import { queryCache } from "@Root/index";

export const useUserState = create(
  (set, get) => ({
    user: null,

    onLogin: (user, history) => {
      // console.log("onLogin", user);
      set({ user });
      // console.log("user", user);
      history.push("/home");
      queryCache.clear();
    },

    onLogout: (history) => {
      // removeSessionData()
      set({ user: null });
      setTimeout(() => {
        history.replace("/auth/login");
        queryCache.clear();
      }, 200);
    },

    getUserData: () => {
      return new Promise((resolve) => {
        axios
          .get("/api/v1/meAdmin")
          .then(({ data }) => {
            if (data.admin) {
              set({ user: data.admin });
              resolve(data.admin);
            }
          })
          .catch((err) => {
            console.log(err);
            resolve(null);
          });
      });
    },

    hasPermission: (permission, action="read") => {
      const permissions = get().user?.permissions ?? [];
      if (!permissions.length) {
        return false;
      }

      const isSuper = permissions.find((perm) => perm.permission === "super");
      if (isSuper) {
        return true;
      }

      const targetPermission = permissions.find(
        (perm) => perm.permission === permission
      );
      if (!targetPermission) {
        return false;
      } else {
        return targetPermission[action];
      }
    },
  }),
  {
    settings: {
      logLevel: LogLevel.None,
      name: "user",
    },
    // watchers: {
    //   user(newValue) {
    //     if (newValue) {

    //     }
    //   }
    // }
  }
);
