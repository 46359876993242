import React from 'react';
import { NormalPeoplePicker, PeoplePickerItem } from '@fluentui/react/lib/Pickers'
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';

const personaStyle = {
  root: {
    padding: '8px 6px',
    selectors: { '.ms-Persona-coin': { display: 'none' } },
  },
  details: {
    paddingLeft: 0,
  },
  primaryText: {
    fontWeight: 600,
    marginBottom: 2,
  },
};

const peoplePickerStyles = {
  root: {
    marginTop: '6px',
    selectors: {
      '.ms-BasePicker-text': {
        borderColor: 'transparent',
        borderRadius: '4px !important',
        boxShadow:
          'inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);',
      },
      '.ms-BasePicker-text:hover': {
        borderColor: 'transparent',
      },
      '.ms-BasePicker-text input': {
        fontFamily:
          '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, system-ui, Roboto, "Helvetica Neue", sans-serif;',
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(66, 90, 112)',
      },
      '.ms-BasePicker-text input:focus': {
        borderStyle: 'none',
        boxShadow:
          'inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14) !important;',
      },
      '.ms-BasePicker-text::after': {
        borderStyle: 'none !important',
      },
    },
  },
};

export default function ClientPicker({
  clients = [],
  delayResults = true,
  defaultValue,
  style = {},
  disabled = false,
  onChange,
  limit = 1,
}) {
  const picker = React.useRef(null);

  const onFilterChanged = (filterText, currentPersonas, limitResults) => {
    if (filterText && filterText.length >= 3) {
      let filteredPersonas = filterPersonas(filterText);

      filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  const filterPersonas = (filterText) => {
    return clients.filter(
      (item) =>
        item.text.toLowerCase().includes(filterText.toLowerCase()) ||
        item.secondaryText.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  function removeDuplicates(personas, possibleDupes) {
    return personas.filter(
      (persona) => !listContainsPersona(persona, possibleDupes)
    );
  }

  function listContainsPersona(persona, personas) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
  }

  const filterPromise = (personasToReturn) => {
    if (delayResults) {
      return convertResultsToPromise(personasToReturn);
    } else {
      return personasToReturn;
    }
  };

  function convertResultsToPromise(results) {
    return new Promise((resolve, reject) =>
      setTimeout(() => resolve(results), 1000)
    );
  }

  function getTextFromItem(persona) {
    return persona.text;
  }

  return (
    <div style={style}>
      <NormalPeoplePicker
        // eslint-disable-next-line react/jsx-no-bind
        disabled={disabled}
        onResolveSuggestions={onFilterChanged}
        getTextFromItem={getTextFromItem}
        className={"ms-PeoplePicker"}
        key={"normal"}
        itemLimit={limit}
        removeButtonAriaLabel={"Remove"}
        inputProps={{
          onBlur: (ev: React.FocusEvent<HTMLInputElement>) =>
            console.log("onBlur called"),
          onFocus: (ev: React.FocusEvent<HTMLInputElement>) =>
            console.log("onFocus called"),
          "aria-label": "People Picker",
        }}
        styles={peoplePickerStyles}
        onRenderItem={(props) => (
          <PeoplePickerItem
            {...props}
            styles={{
              subComponentStyles: {
                persona: personaStyle,
                personaCoin: { display: "none" },
              },
            }}
          />
        )}
        onRenderSuggestionsItem={(props, itemProps) => (
          <Persona
            text={props.text}
            size={PersonaSize.regular}
            styles={personaStyle}
            secondaryText={props.secondaryText}
          />
        )}
        defaultSelectedItems={clients.filter((client) =>
          defaultValue.includes(client.key)
        )}
        pickerSuggestionsProps={{
          loadingText: "Cargando",
          noResultsFoundText: "No hay resultados",
          searchingText: "Buscando",
        }}
        componentRef={picker}
        // onInputChange={(input) => input}
        resolveDelay={300}
        onInputChange={(input) => {
          return input;
        }}
        onChange={(selected) => {
          onChange && onChange(selected ?? []);
        }}
        // disabled={isPickerDisabled}
      />
    </div>
  );
}
