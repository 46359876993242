import React from 'react';
import { Pane, Text } from 'evergreen-ui';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import _get from "lodash/get";
import jwtDecode from "jwt-decode";
import axios from "./axios";
import { useUserState } from "@Stores/storeUser";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(_object, _base) {
    return transform(_object, function (result, value, key) {
      if (!isEqual(value, _base[key])) {
        result[key] =
          isObject(value) && isObject(_base[key])
            ? changes(value, _base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export function getLocalStorageAppKey() {
  const user = useUserState.getState().user;
  if (!user) return null;
  return `acfmexico_cms@${user.id}`;
}

export function getLocalStorage() {
  const appKey = getLocalStorageAppKey();
  if (!appKey) return null;

  const contentStr = localStorage.getItem(appKey);
  if (!contentStr) {
    return null;
  }
  return JSON.parse(contentStr);
}

export function getSectionSettings(section) {
  const content = getLocalStorage();
  return _get(content, `config.${section}`, null);
}

export function setSectionSettings(section, settings) {
  const appKey = getLocalStorageAppKey();
  if (!appKey) return null;

  let currentContent = getLocalStorage();
  if (!currentContent) {
    currentContent = { config: {} };
  }

  const newContent = {
    ...currentContent,
    config: {
      ...currentContent.config,
      [section]: {
        ...(currentContent.config[section] ?? {}),
        ...settings,
      },
    },
  };

  console.log("newContent", newContent)

  localStorage.setItem(appKey, JSON.stringify(newContent));
}

export function validateEmail(email) {
  // const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function addWhenFieldError(fieldName, errors) {
  return errors && errors[fieldName]
    ? {
        isInvalid: true,
        validationMessage: errors[fieldName].message,
      }
    : {};
}

export function validatePassword(pass) {
  return pass.length >= 5;
}

export function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function loginFromTokens() {
  // console.log("loginFromTokens");

  const token = localStorage.getItem("@acfmexico_idToken");
  const refreshToken = localStorage.getItem("@acfmexico_refreshIdToken");

  if (!token || !refreshToken) {
    return null;
  }

  try {
    const tokenData = jwtDecode(token);
    const refreshTokenData = jwtDecode(refreshToken);
    const now = Date.now();
    if (now / 1000 > tokenData.exp && now / 1000 > refreshTokenData.exp) {
      console.log("expired");
      return null;
    }
  } catch (err) {
    console.log(err);
  }
  console.log("lets try login");

  return axios
    .get("/api/v1/meAdmin")
    .then(({ data }) => {
      return data.admin ?? null;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export function isValidSession() {
  // const token = localStorage.getItem('@acfmexico_idToken');
  // const refreshToken = localStorage.getItem('@acfmexico_refreshIdToken');
  const user = useUserState.getState().user;

  // if (token && refreshToken) {
  //   return true;
  // }
  if (user !== null) {
    return true;
  }

  return false;
}

export function FieldErrorMessage({ message, ...rest }) {
  return (
    <Pane
      display="flex"
      alignItems="center"
      opacity={message ? 1 : 0}
      marginTop={4}
      {...rest}
    >
      <svg
        data-icon="error"
        width={16}
        viewBox="0 0 16 16"
        style={{ fill: "rgb(236, 76, 71)", marginRight: 8, width: 14 }}
      >
        <path
          d="M7.99-.01c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-3h-2v-7h2v7z"
          fillRule="evenodd"
        ></path>
      </svg>
      <Text size={300} color={"#bf0e08"}>
        {message}
      </Text>
    </Pane>
  );
}

export function isNumberKey(event) {
  return (
    (event.keyCode >= 48 && event.keyCode <= 57) ||
    [8, 37, 39, 46, 9].includes(event.keyCode) ||
    (event.keyCode === 86 && event.metaKey)
  );
}

export function isNumericKey(event) {
  return (
    (event.keyCode >= 48 && event.keyCode <= 57) ||
    [190, 37, 39, 46, 9].includes(event.keyCode) ||
    (event.keyCode === 86 && event.metaKey)
  );
}

/* eslint-disable no-useless-escape */
export const mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /* cspell: disable-next-line */
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /* cspell: disable-next-line */
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export function copyAndSort(items, columnKey, isSortedDescending) {
  const key = columnKey;
  return items
    .slice(0)
    .sort((a, b) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
}

export function getDateTokens(date, formatted) {
  const mesPar = (date.month() + 1) % 2 === 0;
  const mensualFechaInicial = date;
  const mensualFechaFinal = date.endOf("month");
  const bimestralFechaInicial = mesPar
    ? date.subtract(1, "month")
    : date.subtract(2, "month");
  const bimestralFechaFinal = mesPar
    ? mensualFechaFinal
    : date.subtract(1, "month").endOf("month");

  function applyFormat(d) {
    return d;
  }

  return {
    mesPar: mesPar,
    mensualFechaInicial: applyFormat(mensualFechaInicial),
    mensualFechaFinal: applyFormat(mensualFechaFinal),
    bimestralFechaInicial: applyFormat(bimestralFechaInicial),
    bimestralFechaFinal: applyFormat(bimestralFechaFinal),
  };
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const value = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return value.toLocaleString() + ' ' + sizes[i];
}

export function filenameWithoutExtension(filename) {
  return filename.split('.').slice(0, -1).join('.')
}


export function getExtension(filename) {
  return filename.split('.').pop();
}