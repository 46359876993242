import React from 'react';
import styled from 'styled-components';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import {
  DefaultPalette,
} from '@fluentui/react/lib/Styling';
import { TextInputField, Pane, Spinner } from 'evergreen-ui';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import CustomModal from '@Components/CustomModal'
import axios from '@Utils/axios';
import { useUsersStore } from '@Stores/storeUsers';
import {
  FieldErrorMessage,
  difference,
  validateEmail,
} from '@Utils/index';
import { Separator } from '@fluentui/react/lib/Separator';
import create, { LogLevel } from "zustand-store-addons";
import ClientPicker from "./ClientPicker";
import isEqual from "lodash/isEqual";
import { queryCache } from "@Root/index";
import { useUserState } from "@Stores/storeUser";

export const useFormStore = create(
  (set, get) => ({
    containerDivId: "useFormStore",
    sending: false,
    queriesController: null,
    title: "Crea un nuevo usuario",

    selectedUser: null,
  }),
  {
    watchers: {
      selectedUser(newValue) {
        this.set({
          title: newValue
            ? "Modifica un usuario existente"
            : "Crea un nuevo usuario",
        });
      },
    },
    settings: {
      logLevel: LogLevel.None,
      name: "UsersFormStore",
    },
  }
);

export function updateClientUserOnDB(method, { id, ...rest }) {
  return new Promise((resolve, reject) => {
    const url =
      method === "post" ? `/api/v1/usuarios` : `/api/v1/usuarios/${id}`;

    axios[method](url, { userData: rest })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
}

export default function FormModal({ clients }) {
  const isOpen = useUsersStore("formVisible");

  const [title, sending, selectedUser] = useFormStore(
    `title, sending, selectedUser`
  );
  const hasPermission = useUserState("hasPermission");
  const { handleSubmit, errors, control } = useForm();

  function onDismissed() {
    useUsersStore.setState({
      selectedUser: null,
    });
  }

  function onClose() {
    useUsersStore.setState({
      formVisible: false,
    });
  }

  function onSubmitRender(props, defaultRender) {
    if (props.disabled) {
      const text = props.text === "Agregar" ? "Agregando" : "Actualizando";

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spinner size={16} marginRight={4} />
          <span style={{ fontWeight: 600 }}>{text}</span>
        </div>
      );
    }

    return defaultRender(props);
  }

  function onSubmit(data) {
    useFormStore.setState({ sending: true });
    let userData = {
      fullname: data.fullname,
      email: data.email,
      cliente_ids: data.cliente_ids,
    };

    // let clientId = data.client[0].key;
    let httpMethod = "post";
    let dataToSend;

    if (selectedUser) {
      httpMethod = "put";

      let changed = difference(userData, {
        fullname: selectedUser.fullname,
        email: selectedUser.email,
      });

      if (
        !isEqual(
          selectedUser.cliente_ids.sort(),
          data.cliente_ids.map((c) => c.key).sort()
        )
      ) {
        changed.cliente_ids = data.cliente_ids
          .map((c) => (typeof c === "string" ? c : c.key))
          .sort();
      }

      if (Object.keys(changed).length === 0) {
        useFormStore.setState({ sending: false });
        setTimeout(onClose, 200);
        return;
      } else {
        dataToSend = {
          id: selectedUser.id,
          ...(changed.cliente_ids ? { cliente_ids: changed.cliente_ids } : {}),
          ...(changed.fullname ? { fullname: changed.fullname } : {}),
          ...(changed.email ? { email: changed.email } : {}),
        };
      }
    } else {
      dataToSend = userData;
    }

    updateClientUserOnDB(httpMethod, dataToSend)
      .then((data) => {
        queryCache.refetchQueries(["usersList"]);
        useFormStore.setState({ sending: false });
        setTimeout(() => {
          onClose();
        }, 200);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const defaultClients = selectedUser
    ? selectedUser.clientes?.map((cliente) => cliente.id)
    : [];

  console.log("defaultClients", defaultClients);

  return (
    <CustomModal
      title={title}
      isOpen={isOpen}
      isBlocking={true}
      onDismissed={onDismissed}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled={!hasPermission("usuarios", "write")}>
          <Pane
            display="flex"
            flexFlow="column nowrap"
            flex="1"
            marginBottom={24}
          >
            <Controller
              name="cliente_ids"
              control={control}
              style={{ minWidth: 280 }}
              defaultValue={defaultClients}
              render={({ onChange, onBlur, value, ...rest }) => (
                <>
                  <Label>Cliente*</Label>
                  <ClientPicker
                    disabled={!hasPermission("usuarios", "write")}
                    clients={
                      clients?.map((client) => ({
                        key: client.id,
                        text: client.razon_social,
                        secondaryText: client.rfc,
                      })) ?? []
                    }
                    defaultValue={value}
                    onChange={onChange}
                    limit={9000}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="clientes"
                    render={({ message }) => (
                      <FieldErrorMessage message={message} paddingTop={4} />
                    )}
                  />
                </>
              )}
              rules={{
                validate: (selected) =>
                  selected.length > 0 || "Campo requerido",
              }}
            />
          </Pane>
          <Pane display="flex" flexFlow="column nowrap" flex="1">
            <Controller
              control={control}
              style={{ minWidth: 280 }}
              defaultValue={selectedUser?.fullname ?? ""}
              render={(props) => (
                <Pane marginBottom={24}>
                  <TextInputField
                    label={<Label>Nombre completo*</Label>}
                    marginBottom={4}
                    {...props}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fullname"
                    render={({ message }) => (
                      <FieldErrorMessage message={message} paddingTop={4} />
                    )}
                  />
                </Pane>
              )}
              name="fullname"
              rules={{
                required: "Requerido",
              }}
            />
          </Pane>
          <Pane display="flex" flexFlow="column nowrap" flex="1">
            <Controller
              style={{ minWidth: 280 }}
              control={control}
              defaultValue={selectedUser?.email ?? ""}
              render={(props) => (
                <Pane marginBottom={24}>
                  <TextInputField
                    label={<Label>Email*</Label>}
                    marginBottom={4}
                    type="email"
                    {...props}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="email"
                    render={({ message }) => (
                      <FieldErrorMessage message={message} paddingTop={4} />
                    )}
                  />
                </Pane>
              )}
              name="email"
              rules={{
                required: "Requerido",
                validate: (val) =>
                  validateEmail(val) || "Por favor ingresa un email válido.",
              }}
            />
          </Pane>

          <Separator />

          <Pane display="flex" justifyContent="flex-end" marginBottom={14}>
            <DefaultButton
              style={{ marginRight: hasPermission("usuarios", "write") ? 8 : 0 }}
              text={hasPermission("usuarios", "write") ? "Cancelar" : "Cerrar"}
              onClick={() => onClose()}
              allowDisabledFocus
              disabled={sending}
            />
            {hasPermission("usuarios", "write") && (
              <PrimaryButton
                onRenderText={onSubmitRender}
                text={selectedUser ? "Actualizar" : "Agregar"}
                type="submit"
                allowDisabledFocus
                disabled={sending}
              />
            )}
          </Pane>
        </fieldset>
      </form>
    </CustomModal>
  );
}


const Label = styled.p`
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: ${DefaultPalette.neutralSecondary};
  box-sizing: border-box;
  box-shadow: none;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  overflow-wrap: break-word;
`;

