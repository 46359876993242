import _set from 'lodash/set'
import { produce } from 'immer'

export const addEvents = (categoryState, category, store) => {
  return (path) => ({
    onFocus: (event) => event.target.select(),

    onBlur: (event) => {
      let val = event.target.value
      if (val === '') {
        val = '0'
      }

      if (val !== '0') {
        if (val.indexOf('.') === -1) {
          val += '.00'
        } else {
          if (!/^[0-9]+(\.)?[0-9]{2}$/.test(val)) {
            if (val !== '0.') {
              val = Number(val).toFixed(2)
            } else {
              val = '0'
            }
          }
        }
      }

      // val = Number(val)
      const nextState = produce(categoryState, (draft) => {
        _set(draft, path, val)
      })

      store.setState({ [category]: nextState })
    },

    onChange: (event) => {
      let val = event?.target.value
      if (val === '.') {
        val = '0.'
      }
      if (val.length === 2 && val.indexOf('0') === 0 && val.indexOf('.') !== 1) {
        val = val.charAt(1)
      }

      const regexp = /^[0-9]+(\.)?([0-9]{1,2})?$/
      if (!regexp.test(val) && val !== '') return

      // val = Number(val)

      let isNegative = false;
      if (event.target.getAttribute('data-isNegative') === 'yes') {
        isNegative = true;
      }
      const nextState = produce(categoryState, (draft) => {
        _set(draft, path, val)
      })

      console.log(category, path, nextState, isNegative);

      let obj = {};
      obj[category] = nextState;

      const negativos = store.getState().negativos;
      obj.negativos = negativos;
      _set(obj, `negativos.${category}.${path}`, isNegative);

      store.setState(obj);
    },
  })
}
