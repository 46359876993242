import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserState } from '@Stores/storeUser'

export default function useProtectedRoute(perm, redirectTo = '/home') {
  const history = useHistory()

  useEffect(() => {
    const user = useUserState.getState().user
    const permissions = user?.permissions.map((perm) => perm.permission) ?? []

    if (!permissions.includes(perm)) {
      history.replace(redirectTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
