import React from 'react';
import styled from 'styled-components';
// import { Pane } from 'evergreen-ui';
import themeColors from '@Root/style/colors';
import { ReactComponent as Logo } from '@Root/assets/onlineServices.svg';

export default function AuthForm({ children }) {

  return (
    <AuthFormS>
      <Logo style={{ width: 240, marginBottom: 20 }} />
      <div className="form-container">
        { children }
      </div>
    </AuthFormS>
  )
}

const AuthFormS = styled.div`
  background-color: white;
  background-color: ${themeColors.neutral.N4};
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .form-container {
    background: white;
    border: solid 1px ${themeColors.neutral.N5};
    border-radius: 4px;
    padding: 30px;
    font-family: 'Lato';
    font-weight: 400;
    color: ${themeColors.text.default};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  }
`;
