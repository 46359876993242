import { useRef } from 'react';

export default function useWatchValue(watch, fieldName, defaultValue, onChange) {
  const prevValue = useRef(defaultValue);
  const currentValue = watch(fieldName);
  if (currentValue !== prevValue.current) {
    onChange && setTimeout(() => onChange(currentValue, prevValue), 0);
    prevValue.current = currentValue;
  }
  return currentValue;
}
