const colors = {
  neutral: {
    lightest: "#F9F9FB",
    light: "#E4E7EB",
    base: "#425A70",
    dark: "#234361",
    N1: "#F9F9FB",
    N2: "#F5F6F7",
    N3: "#EDF0F2",
    N4: "#E4E7EB",
    N5: "#C7CED4",
    N6: "#A6B1BB",
    N7: "#7B8B9A",
    N8: "#66788A",
    N9: "#425A70",
    N10: "#234361",
    N1A: "rgba(67, 90, 111, 0.04)",
    N2A: "rgba(67, 90, 111, 0.06)",
    N3A: "rgba(67, 90, 111, 0.09)",
    N4A: "rgba(67, 90, 111, 0.14)",
    N5A: "rgba(67, 90, 111, 0.3)",
    N6A: "rgba(67, 90, 111, 0.47)",
    N7A: "rgba(67, 90, 111, 0.7)",
    N8A: "rgba(67, 90, 111, 0.81)",
  },
  blue: {
    lightest: "#F7F9FD",
    light: "#DDEBF7",
    base: "#1070CA",
    dark: "#084B8A",
    B1: "#F7F9FD",
    B2: "#F1F7FC",
    B3: "#E9F2FA",
    B4: "#DDEBF7",
    B5: "#B7D4EF",
    B6: "#8FBCE6",
    B7: "#579AD9",
    B8: "#3D8BD4",
    B9: "#1070CA",
    B10: "#084B8A",
    B1A: "rgba(16, 112, 202, 0.04)",
    B2A: "rgba(16, 112, 202, 0.06)",
    B3A: "rgba(16, 112, 202, 0.09)",
    B4A: "rgba(16, 112, 202, 0.14)",
    B5A: "rgba(16, 112, 202, 0.3)",
    B6A: "rgba(16, 112, 202, 0.47)",
    B7A: "rgba(16, 112, 202, 0.7)",
    B8A: "rgba(16, 112, 202, 0.81)",
  },
  red: {
    lightest: "#FEF6F6",
    light: "#FAE2E2",
    base: "#EC4C47",
    dark: "#BF0E08",
  },
  orange: {
    lightest: "#FDF8F3",
    light: "#FAE3CD",
    base: "#D9822B",
    dark: "#95591E",
  },
  yellow: {
    lightest: "#FEF8E7",
    light: "#FBE6A2",
    base: "#F7D154",
    dark: "#7E6514",
  },
  green: {
    lightest: "#F1FAF5",
    light: "#D4EEE2",
    base: "#47B881",
    dark: "#00783E",
  },
  teal: {
    lightest: "#F1FBFC",
    light: "#D2EEF3",
    base: "#14B5D0",
    dark: "#007489",
  },
  purple: {
    lightest: "#F8F7FC",
    light: "#EAE7F8",
    base: "#735DD0",
    dark: "#37248F",
  },
  background: {
    tint1: "#F9F9FB",
    tint2: "#F5F6F7",
    overlay: "rgba(67, 90, 111, 0.7)",
    yellowTint: "#FEF8E7",
    greenTint: "#F1FAF5",
    orangeTint: "#FDF8F3",
    redTint: "#FEF6F6",
    blueTint: "#F7F9FD",
    purpleTint: "#F8F7FC",
    tealTint: "#F1FBFC",
  },
  border: {
    default: "#E4E7EB",
    muted: "#EDF0F2",
  },
  text: {
    muted: "#66788A",
    default: "#425A70",
    dark: "#234361",
    selected: "#1070CA",
    success: "#00783E",
    info: "#084B8A",
    danger: "#BF0E08",
    warning: "#95591E",
  },
  icon: {
    default: "#66788A",
    muted: "#7B8B9A",
    disabled: "rgba(67, 90, 111, 0.3)",
    selected: "#1070CA",
    success: "#47B881",
    info: "#1070CA",
    danger: "#EC4C47",
    warning: "#D9822B",
  },
  intent: {
    none: "#1070CA",
    success: "#47B881",
    danger: "#EC4C47",
    warning: "#D9822B",
  },
};

export const coloresDepartamentos = {
  '1_light': 'rgb(220, 242, 234)',
  '1_dark': 'rgb(49, 113, 89)',
  '2_light': 'rgb(214, 224, 255)',
  '2_dark': 'rgb(41, 82, 204)',
  '3_light': 'rgb(231, 228, 249)',
  '3_dark': 'rgb(110, 98, 182)',
  '4_light': 'rgb(253, 229, 226)',
  '4_dark': 'rgb(121, 27, 17)',
  '5_light': 'rgb(243, 243, 180)',
  '5_dark': 'rgb(97, 97, 5)',
  '6_light': 'rgb(235, 235, 235)',
  '6_dark': 'rgb(60, 63, 83)',
}

export default colors;
