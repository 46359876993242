import { produce } from "immer";

export function setQueryParams(store, fn) {
  store.getState().setConfig(produce(fn));
}

export function onParamChange(store, param, newVal) {
  setQueryParams(store, (currentParams) => {
    currentParams[param] = newVal;
  });
}

export default function useConfigPanel(useStore) {
  const [isOpen, queryParams] = useStore("showingConfig, queryParams");

  function onParamChange(param, newVal) {
    setQueryParams(useStore, (currentParams) => {
      currentParams[param] = newVal;
    });
  }

  function onDismiss() {
    useStore.setState({ showingConfig: false });
  }

  return {
    isOpen,
    queryParams,
    onParamChange,
    onDismiss,
  };
}
