import create, { LogLevel } from 'zustand-store-addons';
import { useEquivalenciasStore } from "./CalculoProvisionalesEqStore";
import axios from "@Utils/axios";
import { queryCache } from "@Root/index";
import { useCalculoProvisionalesStore } from "@Stores/storeCalculoProvisionales";

const onEqStoreValueChanged = useEquivalenciasStore.getState().onValueChanged;

type CalculoStore = {
  clientData: any,
  isr_factor: string,
  isr_efectuado: string,
  isr_facturacion: number,
  isr_balance: number,
  isr_salarios: number,
  isr_asimilados: number,
  isr_isr: number,
  isr_pagar: number,
  iva_factor: string,
  iva_iva_trasladado: number,
  iva_iva_acreditable: number,
  iva_iva_retenido: number,
  iva_iva_favor: number,
  iva_iva_declarar: number,
  totales_iva_retenido: number,
  totales_isr_arrendamiento: number,
  total_pagar: number,
  step: number,
  eq_bancos_debe: number,
  eq_bancos_haber: number,
  eq_cuentasPorCobrar_debe: number,
  eq_cuentasPorCobrar_haber: number,
  eq_ivaAcreditable_debe: number,
  eq_ivaAcreditable_haber: number,
  eq_cuentasPorPagar_debe: number,
  eq_cuentasPorPagar_haber: number,
  eq_imptPorPagar_debe: number,
  eq_imptPorPagar_haber: number,
  eq_imptFavor_debe: number,
  eq_imptFavor_haber: number,
  eq_ivaPorPagar_debe: number,
  eq_ivaPorPagar_haber: number,
  eq_ventas_debe: number,
  eq_ventas_haber: number,
  eq_deducciones_debe: number,
  eq_deducciones_haber: number,
  eq_percepciones_debe: number,
  eq_percepciones_haber: number,
  eq_asimilados_debe: number,
  eq_asimilados_haber: number,
  eq_imss_debe: number,
  eq_imss_haber: number,
  eq_sar_debe: number,
  eq_sar_haber: number,
  eq_infonavit_debe: number,
  eq_infonavit_haber: number,
  eq_sNomina_debe: number,
  eq_sNomina_haber: number,
  isr_facturacion_acumulada: number,
  isr_balance_acumulado: number,
  perdidas_e_anteriores: number,
  clientAcumulados: Array,
};
//#region helper_functions
function getProvisionales(cliente) {
  if (!cliente) return undefined;
  // console.log("getProvisionales", cliente);

  return cliente.provisionales?.map((prov) => prov.data_json);
}

function calcISRFacturacion(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.ppd.subtotal);
    sumatoria += Number(prov.emitidos.por_comprobar.subtotal);
  });
  return sumatoria;
}

function calcISRBalance(provisionales, resico = false) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.ppd.subtotal);
    sumatoria += Number(prov.emitidos.por_comprobar.subtotal);

    // Para RESICO estos no figuran
    if (!resico) {
      sumatoria -= Number(prov.recibidos.pue.subtotal);
      sumatoria -= Number(prov.recibidos.ppd.subtotal);
      sumatoria -= Number(prov.recibidos.por_comprobar.subtotal);
      sumatoria -= Number(prov.nominasPatron.imss);
      sumatoria -= Number(prov.nominasPatron.sar);
      sumatoria -= Number(prov.nominasPatron.infonavit);
      sumatoria -= Number(prov.nominasPatron.nomina_porciento_3);
    }

  });
  return sumatoria;
}

function calcISRRetenido(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.ret_isr);
    sumatoria += Number(prov.emitidos.ppd.ret_isr);
    // sumatoria += Number(prov.emitidos.complementos.ret_isr);
    sumatoria += Number(prov.emitidos.por_comprobar.ret_isr);
  });
  return sumatoria;
}

function calcISRSalarios(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.ispt);
  });
  return sumatoria;
}

function calcISRAsimilados(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.ret_hon_asimilados);
  });
  return sumatoria;
}

function calcIVAIVATrasladado(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.iva);
    sumatoria += Number(prov.emitidos.complementos.iva);
    sumatoria += Number(prov.emitidos.por_comprobar.iva);

    // por ruth
    sumatoria += Number(prov.emitidos.ppd.iva);
  });
  return sumatoria;
}

function calcIVAIVAAcreditable(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.iva);
    sumatoria += Number(prov.recibidos.ppd.iva);
    sumatoria += Number(prov.recibidos.por_comprobar.iva);
    // sumatoria += Number(prov.recibidos.complementos.iva);
  });
  return sumatoria;
}

function calcIVAIVARetenido(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.ret_iva);
    sumatoria += Number(prov.emitidos.ppd.ret_iva);
    // sumatoria += Number(prov.emitidos.complementos.ret_iva);
    sumatoria += Number(prov.emitidos.por_comprobar.ret_iva);
  });
  return sumatoria;
}

function calcIVARetenido(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.ret_iva);
    sumatoria += Number(prov.recibidos.complementos.ret_iva);
    sumatoria += Number(prov.recibidos.por_comprobar.ret_iva);

    // agregamos iva retenido de ppd
    sumatoria += Number(prov.recibidos.ppd.ret_iva);
  });
  return sumatoria;
}

function calcISRArrendamiento(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.ret_isr);
    sumatoria += Number(prov.recibidos.complementos.ret_isr);
    sumatoria += Number(prov.recibidos.por_comprobar.ret_isr);

    // agregamos isr retenido de ppd
    sumatoria += Number(prov.recibidos.ppd.ret_isr);
  });
  return sumatoria;
}

function calcEqBancosDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.pue.iva);
    sumatoria -= Number(prov.emitidos.pue.ret_isr);
    sumatoria -= Number(prov.emitidos.pue.ret_iva);
  });
  return sumatoria;
}

function calcEqBancosHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.subtotal);
    sumatoria += Number(prov.recibidos.pue.iva);
    sumatoria -= Number(prov.recibidos.pue.ret_isr);
    sumatoria -= Number(prov.recibidos.pue.ret_iva);

    sumatoria += Number(prov.recibidos.complementos.subtotal);
    sumatoria += Number(prov.recibidos.complementos.iva);
    sumatoria -= Number(prov.recibidos.complementos.ret_isr);
    sumatoria -= Number(prov.recibidos.complementos.ret_iva);

    sumatoria += Number(prov.recibidos.por_comprobar.subtotal);
    sumatoria += Number(prov.recibidos.por_comprobar.iva);
    sumatoria -= Number(prov.recibidos.por_comprobar.ret_isr);
    sumatoria -= Number(prov.recibidos.por_comprobar.ret_iva);

    sumatoria += Number(prov.nominasPatron.perc_nomina);
    sumatoria += Number(prov.nominasPatron.perc_asimilados);
    sumatoria += Number(prov.nominasPatron.imss);
    sumatoria += Number(prov.nominasPatron.sar);
    sumatoria += Number(prov.nominasPatron.infonavit);
    sumatoria += Number(prov.nominasPatron.nomina_porciento_3);
  });
  return sumatoria;
}

function calcEqCuentasPorCobrarDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.pue.iva);
    sumatoria -= Number(prov.emitidos.pue.ret_isr);
    sumatoria -= Number(prov.emitidos.pue.ret_iva);

    sumatoria += Number(prov.emitidos.ppd.subtotal);
    sumatoria += Number(prov.emitidos.ppd.iva);
    sumatoria -= Number(prov.emitidos.ppd.ret_isr);
    sumatoria -= Number(prov.emitidos.ppd.ret_iva);

    //por ruth
    sumatoria += Number(prov.emitidos.complementos.subtotal);
    sumatoria += Number(prov.emitidos.complementos.iva);
    sumatoria -= Number(prov.emitidos.complementos.ret_isr);
    sumatoria -= Number(prov.emitidos.complementos.ret_iva);

  });
  return sumatoria;
}

function calcEqCuentasPorCobrarHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.pue.iva);
    sumatoria -= Number(prov.emitidos.pue.ret_isr);
    sumatoria -= Number(prov.emitidos.pue.ret_iva);

    sumatoria += Number(prov.emitidos.complementos.subtotal);
    sumatoria += Number(prov.emitidos.complementos.iva);
    sumatoria -= Number(prov.emitidos.complementos.ret_isr);
    sumatoria -= Number(prov.emitidos.complementos.ret_iva);
  });
  return sumatoria;
}

function calcEqCuentasPorPagarDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.subtotal);
    sumatoria += Number(prov.recibidos.pue.iva);
    sumatoria -= Number(prov.recibidos.pue.ret_isr);
    sumatoria -= Number(prov.recibidos.pue.ret_iva);

    sumatoria += Number(prov.recibidos.complementos.subtotal);
    sumatoria += Number(prov.recibidos.complementos.iva);
    sumatoria -= Number(prov.recibidos.complementos.ret_isr);
    sumatoria -= Number(prov.recibidos.complementos.ret_iva);
  });
  return sumatoria;
}

function calcEqCuentasPorPagarHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.subtotal);
    sumatoria += Number(prov.recibidos.pue.iva);
    sumatoria -= Number(prov.recibidos.pue.ret_isr);
    sumatoria -= Number(prov.recibidos.pue.ret_iva);

    sumatoria += Number(prov.recibidos.ppd.subtotal);
    sumatoria += Number(prov.recibidos.ppd.iva);
    sumatoria -= Number(prov.recibidos.ppd.ret_isr);
    sumatoria -= Number(prov.recibidos.ppd.ret_iva);

  });
  return sumatoria;
}

function calcEqImptFavorDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.ret_iva);
    sumatoria += Number(prov.emitidos.pue.ret_isr);
    sumatoria += Number(prov.emitidos.ppd.ret_iva);
    sumatoria += Number(prov.emitidos.ppd.ret_isr);
  });
  return sumatoria;
}

function calcEqImptPorPagarHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.ret_iva);
    sumatoria += Number(prov.recibidos.pue.ret_isr);
    sumatoria += Number(prov.recibidos.ppd.ret_iva);
    sumatoria += Number(prov.recibidos.ppd.ret_isr);
    sumatoria += Number(prov.nominasPatron.ispt);
    sumatoria += Number(prov.nominasPatron.ret_hon_asimilados);
  });
  return sumatoria;
}

function calcEqIvaPorPagarHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.iva);
    sumatoria += Number(prov.emitidos.ppd.iva);
  });
  return sumatoria;
}

function calcEqVentasHaber(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.emitidos.pue.subtotal);
    sumatoria += Number(prov.emitidos.ppd.subtotal);
  });
  return sumatoria;
}

function calcEqDeduccionesDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.recibidos.pue.subtotal);
    sumatoria += Number(prov.recibidos.ppd.subtotal);
    sumatoria += Number(prov.recibidos.por_comprobar.subtotal);
  });
  return sumatoria;
}

function calcEqPercepcionesDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.perc_nomina);
    sumatoria += Number(prov.nominasPatron.ispt);
  });
  return sumatoria;
}

function calcEqAsimiladosDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.perc_asimilados);
    sumatoria += Number(prov.nominasPatron.ret_hon_asimilados);
  });
  return sumatoria;
}

function calcEqImssDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.imss);
  });
  return sumatoria;
}

function calcEqSarDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.sar);
  });
  return sumatoria;
}

function calcEqInfonavitDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.infonavit);
  });
  return sumatoria;
}

function calcEqsNominaDebe(provisionales) {
  let sumatoria = 0;
  if (!provisionales) return sumatoria;

  provisionales.forEach((prov) => {
    sumatoria += Number(prov.nominasPatron.nomina_porciento_3);
  });
  return sumatoria;
}
//#endregion helper_functions

export const useCalculoStore = create(
  (
    set: SetStateAddons<CalculoStore> | SetState<CalculoStore>,
    get: GetState<CalculoStore>
  ) => ({
    clientData: null,
    existingCalculo: false,
    editableMode: false,
    isr_factor: "0",
    isr_efectuado: "0",
    iva_factor: "0",
    iva_efectuado: "0",
    step: 1,

    perdidas_e_anteriores: 0,
    perdidas_state: "default",
    perdidas_registered: false,

    clientAcumulados: [],
    previousCalculo: null,
    historialCalculo: [],

    eq_bancos_sInicial: 0,
    eq_cuentasPorCobrar_sInicial: 0,
    eq_deudores_sInicial: 0,
    eq_ivaAcreditable_sInicial: 0,
    eq_activoFijo_sInicial: 0,
    eq_cuentasPorPagar_sInicial: 0,
    eq_acreedores_sInicial: 0,
    eq_imptPorPagar_sInicial: 0,
    eq_imptFavor_sInicial: 0,
    eq_ivaPorPagar_sInicial: 0,
    eq_ventas_sInicial: 0,
    eq_deducciones_sInicial: 0,
    eq_percepciones_sInicial: 0,
    eq_asimilados_sInicial: 0,
    eq_imss_sInicial: 0,
    eq_sar_sInicial: 0,
    eq_infonavit_sInicial: 0,
    eq_sNomina_sInicial: 0,

    toggleReadOnlySwitch() {
      const currentState = get().editableMode;
      set({ editableMode: !currentState });
    },

    stepForward() {
      const { step, isr_factor, iva_factor } = get();
      const dialog = useCalculoProvisionalesStore.getState().dialogRef;

      if (
        ((step === 1 && Number(isr_factor) === 0) ||
          (step === 1 && Number(iva_factor) === 0)) &&
        dialog !== null
      ) {
        dialog.open({
          title: "Factores en ceros",
          subtitle: `El factor ISR y/o el factor IVA se encuentra en ceros. ¿Deseas continuar?`,
          cancelLabel: "No",
          actionLabel: "Sí",
          busyText: "",
          actionFunction: async () => {
            set({ step: step + 1 });
            return;
          },
        });
      } else {
        set({ step: step + 1 });
      }
    },

    stepBackward() {
      set({ step: get().step - 1 });
    },

    onFetchPreviousCalculo(data) {
      const anterior = data.anterior;
      const historial = data.historial;
      if (anterior) {
        set({
          previousCalculo: anterior,
          historialCalculo: historial,
        });
      }
    },

    onFetchPerdidas(data) {
      const { id, perdida, used } = data;
      const registered = id !== undefined;

      set({
        perdidas_registered: registered,
        perdidas_e_anteriores: Number(perdida),
        perdidas_state: registered
          ? used
            ? "blocked"
            : "editable"
          : "default",
      });
    },

    onSavePerdidasValue(newValue) {
      const dateTokens = useCalculoProvisionalesStore.getState().dateTokens;
      const currentYear = dateTokens.mensualFechaInicial.startOf("year");
      const previousYear = currentYear.subtract(1, "year").format("YYYY");

      axios
        .post("/api/v1/perdidas", {
          cliente_id: get().clientData.id,
          perdida: Number(newValue),
          year: previousYear,
        })
        .then((resp) => {
          // console.log(resp.data);

          queryCache.refetchQueries([
            "clientePerdidas",
            {
              year: previousYear,
              cliente: get().clientData.id,
            },
          ]);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async onSaveCalculo() {
      const storeState = get();
      const storeEqState = useEquivalenciasStore.getState();
      const {
        onAutoSaved,
        dateTokens,
      } = useCalculoProvisionalesStore.getState();

      const cliente = storeState.clientData;
      // console.log("onSaveCalculo storeState.clientData", cliente);

      const fecha_inicial =
        cliente.periodicidad === "mensual"
          ? dateTokens.mensualFechaInicial
          : dateTokens.bimestralFechaInicial;
      const fecha_final =
        cliente.periodicidad === "mensual"
          ? dateTokens.mensualFechaFinal
          : dateTokens.bimestralFechaFinal;

      const isr = {
        facturacion: storeState.isr_facturacion,
        facturacion_acumulada: storeState.isr_facturacion_acumulada,
        balance: storeState.isr_balance,
        balance_acumulado: storeState.isr_balance_acumulado,
        utilidad_estimada: storeState.isr_utilidad_estimada,
        perdidas_e_anteriores: storeState.perdidas_e_anteriores,
        factor: storeState.isr_factor,
        isr: storeState.isr_isr,
        isr_efectuado: storeState.isr_efectuado,
        isr_pagar: storeState.isr_pagar,
      };
      const iva = {
        iva_trasladado: storeState.iva_iva_trasladado,
        iva_trasladado_acumulado: storeState.iva_iva_trasladado_acumulado,
        iva_acreditable: storeState.iva_iva_acreditable,
        iva_acreditable_acumulado: storeState.iva_iva_acreditable_acumulado,
        iva_retenido: storeState.iva_iva_retenido,
        iva_retenido_acumulado: storeState.iva_iva_retenido_acumulado,
        factor: storeState.iva_factor,
        iva_favor: storeState.iva_iva_favor,
        iva_efectuado: storeState.iva_efectuado,
        iva_declarar: storeState.iva_iva_declarar,
      };
      const resumen = {
        isr: storeState.isr_pagar,
        iva: storeState.iva_iva_declarar,
        isr_salarios: storeState.isr_salarios,
        isr_asimilados: storeState.isr_asimilados,
        iva_retenido: storeState.totales_iva_retenido,
        isr_arrendamiento: storeState.totales_isr_arrendamiento,
        total_pagar: storeState.total_pagar,
      };
      const equivalencias = {
        bancos: storeEqState.bancos.filas,
        cuentasPorCobrar: storeEqState.cuentasPorCobrar.filas,
        deudores: storeEqState.deudores.filas,
        ivaAcreditable: storeEqState.ivaAcreditable.filas,
        activoFijo: storeEqState.activoFijo.filas,
        cuentasPorPagar: storeEqState.cuentasPorPagar.filas,
        acreedores: storeEqState.acreedores.filas,
        impuestosPorPagar: storeEqState.impuestosPorPagar.filas,
        impuestosFavor: storeEqState.impuestosFavor.filas,
        ivaPorPagar: storeEqState.ivaPorPagar.filas,
        ventas: storeEqState.ventas.filas,
        deducciones: storeEqState.deducciones.filas,
        percepciones: storeEqState.percepciones.filas,
        asimilados: storeEqState.asimilados.filas,
        imss: storeEqState.imss.filas,
        sar: storeEqState.sar.filas,
        infonavit: storeEqState.infonavit.filas,
        sNomina: storeEqState.sNomina.filas,
      };

      const postData = {
        cliente: cliente.id,
        periodicidad: cliente.periodicidad,
        fecha_inicial: fecha_inicial.format("YYYY-MM-DD"),
        fecha_final: fecha_final.format("YYYY-MM-DD"),
        data_json: { isr, iva, resumen, equivalencias },
      };

      axios
        .post("/api/v1/calculoProvisionales", postData)
        .then((response) => {
          // console.log(response.data);
          const queryKey = useCalculoProvisionalesStore.getState()
            .calculosListQueryKey;
          queryCache.refetchQueries(queryKey).then(() => {
            useCalculoProvisionalesStore.setState({
              selectedClient: null,
              modalVisible: false,
            });
            if (typeof onAutoSaved === "function") {
              onAutoSaved();
            }
          });
          // queryCache.invalidateQueries(['calculoProvisionalesList'])
        })
        .catch((err) => console.log(err));
    },
  }),
  {
    computed: {
      isr_facturacion() {
        return calcISRFacturacion(getProvisionales(this.clientData));
      },

      isr_facturacion_acumulada() {
        let sumatoria = 0;
        this.clientAcumulados.forEach((mov) => {
          sumatoria += Number(mov.facturacion);
        });
        return sumatoria;
      },

      isr_balance() {
        return calcISRBalance(getProvisionales(this.clientData), this.clientData?.regimen_clave === '626' ?? false);
      },

      isr_balance_acumulado() {
        let sumatoria = 0;
        this.clientAcumulados.forEach((mov) => {
          sumatoria += Number(mov.balance);
        });
        return sumatoria;
      },

      isr_salarios() {
        return calcISRSalarios(getProvisionales(this.clientData));
      },

      isr_asimilados() {
        return calcISRAsimilados(getProvisionales(this.clientData));
      },

      isr_utilidad_estimada() {
        return Number(this.isr_factor) * this.isr_facturacion_acumulada;
      },

      isr_retenido() {
        return calcISRRetenido(getProvisionales(this.clientData));
      },

      isr_isr() {
        if (!this.clientData) return 0;
        if (this.clientData.tipo_persona === "fisica") {
          return Math.max(
            0,
            Number(this.isr_factor) * this.isr_balance_acumulado
          );
        } else {
          const utilidadPeriodo =
            this.isr_utilidad_estimada - Number(this.perdidas_e_anteriores);
          return utilidadPeriodo > 0 ? 0.3 * utilidadPeriodo : 0;
        }
      },

      isr_pagar() {
        // const efectuado = this.isr_efectuado;
        // return Math.max(this.isr_isr - Number(this.isr_efectuado) - Number(this.isr_retenido), 0);
        return this.isr_isr - Number(this.isr_efectuado) - Number(this.isr_retenido);
      },

      iva_iva_trasladado() {
        return calcIVAIVATrasladado(getProvisionales(this.clientData));
      },

      iva_iva_trasladado_acumulado() {
        let sumatoria = 0;
        this.clientAcumulados.forEach((mov) => {
          sumatoria += Number(mov.iva_trasladado);
        });
        return sumatoria;
      },

      iva_iva_acreditable() {
        const iva_factor = Number(this.iva_factor);
        if (iva_factor > 0) {
          return calcIVAIVAAcreditable(getProvisionales(this.clientData)) * iva_factor;
        }
        return calcIVAIVAAcreditable(getProvisionales(this.clientData));
      },

      iva_iva_acreditable_acumulado() {
        let sumatoria = 0;
        this.clientAcumulados.forEach((mov) => {
          sumatoria += Number(mov.iva_acreditable);
        });
        return sumatoria;
      },

      iva_iva_retenido() {
        return calcIVAIVARetenido(getProvisionales(this.clientData));
      },

      iva_iva_retenido_acumulado() {
        let sumatoria = 0;
        this.clientAcumulados.forEach((mov) => {
          sumatoria += Number(mov.iva_retenido);
        });
        return sumatoria;
      },

      iva_iva_favor() {
        if (this.historialCalculo.length > 0) {
          const ultimoCalculo = this.historialCalculo[0];
          if (Number(ultimoCalculo.data_json.iva.iva_declarar) < 0) {
            return Math.abs(Number(ultimoCalculo.data_json.iva.iva_declarar));
          }
        }
        return 0;
      },

      iva_iva_declarar() {
        return (
          this.iva_iva_trasladado -
          this.iva_iva_acreditable -
          this.iva_iva_retenido -
          this.iva_iva_favor -
          this.iva_efectuado
        );
      },

      totales_iva_retenido() {
        return calcIVARetenido(getProvisionales(this.clientData));
      },

      totales_isr_arrendamiento() {
        return calcISRArrendamiento(getProvisionales(this.clientData));
      },

      total_pagar() {
        return (
          Math.max(this.isr_pagar, 0) +
          Math.max(this.iva_iva_declarar, 0) +
          this.isr_salarios +
          this.isr_asimilados +
          this.totales_iva_retenido +
          this.totales_isr_arrendamiento
        );
      },

      eq_bancos_debe() {
        const newValue = calcEqBancosDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("bancos", 0, "debe", newValue);
        return newValue;
      },

      eq_bancos_haber() {
        const newValue = calcEqBancosHaber(getProvisionales(this.clientData));
        onEqStoreValueChanged("bancos", 0, "haber", newValue);
        return newValue;
      },

      eq_cuentasPorCobrar_debe() {
        const newValue = calcEqCuentasPorCobrarDebe(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("cuentasPorCobrar", 0, "debe", newValue);
        return newValue;
      },

      eq_cuentasPorCobrar_haber() {
        const newValue = calcEqCuentasPorCobrarHaber(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("cuentasPorCobrar", 0, "haber", newValue);
        return newValue;
      },

      eq_ivaAcreditable_debe() {
        // const newValue = this.iva_iva_acreditable;
        const newValue = calcIVAIVAAcreditable(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("ivaAcreditable", 0, "debe", newValue);
        return newValue;
      },

      eq_ivaAcreditable_haber() {
        return 0;
      },

      eq_cuentasPorPagar_debe() {
        const newValue = calcEqCuentasPorPagarDebe(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("cuentasPorPagar", 0, "debe", newValue);
        return newValue;
      },

      eq_cuentasPorPagar_haber() {
        const newValue = calcEqCuentasPorPagarHaber(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("cuentasPorPagar", 0, "haber", newValue);
        return newValue;
      },

      eq_imptPorPagar_debe() {
        return 0;
      },

      eq_imptFavor_debe() {
        const newValue = calcEqImptFavorDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("impuestosFavor", 0, "debe", newValue);
        return newValue;
      },

      eq_imptFavor_haber() {
        return 0;
      },

      eq_imptPorPagar_haber() {
        const newValue = calcEqImptPorPagarHaber(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("impuestosPorPagar", 0, "haber", newValue);
        return newValue;
      },

      eq_ivaPorPagar_debe() {
        return 0;
      },

      eq_ivaPorPagar_haber() {
        const newValue = calcEqIvaPorPagarHaber(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("ivaPorPagar", 0, "haber", newValue);
        return newValue;
      },

      eq_ventas_debe() {
        return 0;
      },

      eq_ventas_haber() {
        const newValue = calcEqVentasHaber(getProvisionales(this.clientData));
        onEqStoreValueChanged("ventas", 0, "haber", newValue);
        return newValue;
      },

      eq_deducciones_debe() {
        const newValue = calcEqDeduccionesDebe(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("deducciones", 0, "debe", newValue);
        return newValue;
      },

      eq_deducciones_haber() {
        return 0;
      },

      eq_percepciones_debe() {
        const newValue = calcEqPercepcionesDebe(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("percepciones", 0, "debe", newValue);
        return newValue;
      },

      eq_percepciones_haber() {
        return 0;
      },

      eq_asimilados_debe() {
        const newValue = calcEqAsimiladosDebe(
          getProvisionales(this.clientData)
        );
        onEqStoreValueChanged("asimilados", 0, "debe", newValue);
        return newValue;
      },

      eq_asimilados_haber() {
        return 0;
      },

      eq_imss_debe() {
        const newValue = calcEqImssDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("imss", 0, "debe", newValue);
        return newValue;
      },

      eq_imss_haber() {
        return 0;
      },

      eq_sar_debe() {
        const newValue = calcEqSarDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("sar", 0, "debe", newValue);
        return newValue;
      },

      eq_sar_haber() {
        return 0;
      },

      eq_infonavit_debe() {
        const newValue = calcEqInfonavitDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("infonavit", 0, "debe", newValue);
        return newValue;
      },

      eq_infonavit_haber() {
        return 0;
      },

      eq_sNomina_debe() {
        const newValue = calcEqsNominaDebe(getProvisionales(this.clientData));
        onEqStoreValueChanged("sNomina", 0, "debe", newValue);
        return newValue;
      },

      eq_sNomina_haber() {
        return 0;
      },
    },

    watchers: {
      clientData(newValue) {
        if (newValue === null) {
          this.set({
            isr_factor: "0",
            isr_efectuado: "0",
            iva_factor: "0",
            iva_efectuado: "0",
            step: 1,
            clientAcumulados: [],
            previousCalculo: null,
            historialCalculo: [],
            perdidas_e_anteriores: 0,
            perdidas_state: "default",
            existingCalculo: false,
            editableMode: false,
          });
          useEquivalenciasStore.getState().resetState();
          useCalculoProvisionalesStore.setState({ canContinue: true });
        } else {
          if (newValue.calculo) {
            this.set({
              existingCalculo: true,
              editableMode: newValue.editCalculo,
              isr_factor: newValue.calculo.data_json.isr.factor,
              isr_efectuado: newValue.calculo.data_json.isr.isr_efectuado,
              iva_factor: newValue.calculo.data_json.iva.factor,
              iva_efectuado: newValue.calculo.data_json.iva.iva_efectuado,
            });
            const eqStore = useEquivalenciasStore.getState();

            const replaceCuenta = (cuentaNombre) => ({
              ...(eqStore[cuentaNombre] || {}),
              filas: [
                ...(newValue.calculo.data_json.equivalencias[cuentaNombre] ||
                  []),
              ],
            });

            useEquivalenciasStore.setState({
              bancos: replaceCuenta("bancos"),
              cuentasPorCobrar: replaceCuenta("cuentasPorCobrar"),
              deudores: replaceCuenta("deudores"),
              ivaAcreditable: replaceCuenta("ivaAcreditable"),
              activoFijo: replaceCuenta("activoFijo"),
              cuentasPorPagar: replaceCuenta("cuentasPorPagar"),
              acreedores: replaceCuenta("acreedores"),
              impuestosPorPagar: replaceCuenta("impuestosPorPagar"),
              impuestosFavor: replaceCuenta("impuestosFavor"),
              ivaPorPagar: replaceCuenta("ivaPorPagar"),
              ventas: replaceCuenta("ventas"),
              deducciones: replaceCuenta("deducciones"),
              percepciones: replaceCuenta("percepciones"),
              asimilados: replaceCuenta("asimilados"),
              imss: replaceCuenta("imss"),
              sar: replaceCuenta("sar"),
              infonavit: replaceCuenta("infonavit"),
              sNomina: replaceCuenta("sNomina"),
            });
            // TODO: Setear factores, efectuados, etc...
          }
        }
      },
      iva_factor(newValue, oldValue) {
        // console.log("iva_factor_changed", newValue, oldValue);
      },
    },

    settings: {
      name: "CalculoStore",
      logLevel: LogLevel.All,
    },
  }
);
