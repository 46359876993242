import React from 'react'
import { Dropdown } from '@fluentui/react/lib/Dropdown'
import { Pane } from 'evergreen-ui'
import { useFormStore } from './FormModal'

export default function Step1() {
  const [
    periodicidad,
    periodicidadOptions,
    periodo,
    periodoOptions
  ] = useFormStore(
    'periodicidad, periodicidadOptions, periodo, periodoOptions'
  )

  return (
    <Pane marginTop={20}>
      <Dropdown
        label="Periodicidad"
        selectedKey={periodicidad ? periodicidad.key : undefined}
        onChange={(e, option) => {
          useFormStore.setState({ periodicidad: option })
        }}
        placeholder="Selecciona una opción"
        options={periodicidadOptions}
      />
      <Dropdown
        styles={{ root: { marginTop: 20 } }}
        label="Periodo"
        selectedKey={periodo ? periodo.key : undefined}
        onChange={(e, option) => {
          console.log(option)
          useFormStore.setState({ periodo: option, periodoText: option.text })
        }}
        placeholder="Selecciona una opción"
        options={periodoOptions}
      />
    </Pane>
  )
}
