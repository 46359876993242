import React from 'react'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import { Text } from '@fluentui/react/lib/Text'
import { TextField } from '@fluentui/react/lib/TextField'
import { useFormStore } from './FormModal'
import { addEvents } from '../formats'

const fieldNames = {
  perc_nomina: 'Per Nómina',
  perc_asimilados: 'Per Asimilados',
  ispt: 'ISPT',
  imss: 'IMSS',
  sar: 'SAR',
  infonavit: 'Infonavit',
  nomina_porciento_3: 'S/Nómina',
  ret_hon_asimilados: 'R H Asimilados',
};

export default function Step4() {
  const [nominasPatron, porComprobarEditable] = useFormStore('nominasPatron, porComprobarEditable')
  const addEventHandlersPatron = addEvents(nominasPatron, 'nominasPatron', useFormStore)
  const nominasObrero = useFormStore('nominasObrero')
  const addEventHandlersObrero = addEvents(nominasObrero, 'nominasObrero', useFormStore)

  return (
    <Pane marginTop={20}>
      <Text variant="xLarge" styles={{ root: { display: 'flex', justifyContent: 'center' } }}>
        Nóminas
      </Text>
      <CategoryContainer variant="cyanBlue" style={{ height: 160 }}>
        <Text variant="medium" className="title">
          PATRÓN
        </Text>
        <Pane display="flex" justifyContent="space-between" marginBottom={20}>
          {['perc_nomina', 'perc_asimilados', 'nomina_porciento_3', 'ret_hon_asimilados'].map((field) => (
            <TextField
              disabled={porComprobarEditable}
              key={field}
              className="field"
              label={fieldNames[field]}
              value={nominasPatron[field]}
              {...addEventHandlersPatron(field)}
            />
          ))}
        </Pane>
        <Pane display="flex" justifyContent="space-between">
          {['ispt', 'imss', 'sar', 'infonavit'].map((field) => (
            <TextField
              disabled={porComprobarEditable}
              key={field}
              className="field"
              label={fieldNames[field]}
              value={nominasPatron[field]}
              {...addEventHandlersPatron(field)}
            />
          ))}
        </Pane>
      </CategoryContainer>
      <CategoryContainer variant="gray" style={{ height: 100 }}>
            <Text variant="medium" className="title">
          OBRERO
        </Text>
        {/* <Pane display="flex" justifyContent="space-between" marginBottom={20}>
          {['percepciones', 'ispt', 'asimilados'].map((field) => (
            <TextField
              disabled={porComprobarEditable}
              key={field}
              className="field"
              label={fieldNames[field]}
              value={nominasObrero[field]}
              {...addEventHandlersObrero(field)}
            />
          ))}
        </Pane> */}
        <Pane display="flex" justifyContent="space-between">
          {['imss', 'sar', 'infonavit'].map((field) => (
            <TextField
              disabled={porComprobarEditable}
              key={field}
              className="field"
              label={fieldNames[field]}
              value={nominasObrero[field]}
              {...addEventHandlersObrero(field)}
            />
          ))}
        </Pane>
      </CategoryContainer>
    </Pane>
  )
}

const CategoryContainer = styled.div`
  --color-variant-magenta: #881798aa;
  --color-variant-magenta-transp: #88179815;
  --color-variant-cyanBlue: #004e8caa;
  --color-variant-cyanBlue-transp: #004e8c15;
  --color-variant-green: #0b6a0baa;
  --color-variant-green-transp: #0b6a0b15;
  --color-variant-gray: #393939aa;
  --color-variant-gray-transp: #39393915;

  border-bottom: solid 1px var(--color-variant-${(props) => props.variant});
  background-color: var(--color-variant-${(props) => props.variant}-transp);
  height: 80px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    background: var(--color-variant-${(props) => props.variant});
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: bold;
    color: white;
  }

  .field {
    width: 110px;
    margin-top: -10px;

    input {
      text-align: right;
    }
  }
`
