import React, { useRef } from 'react'
import styled from 'styled-components';
import colors from '@Root/style/colors';
import Sidebar from './Sidebar';
import Header from './Header';
import { useAppState } from '@Stores/storeApp'
import { useOnClickOutside } from '@Hooks/index';


export default function Page({ children }) {
  const ref = useRef()
  const menuOpen = useAppState((state) => state.menuOpen)
  const toggleMenu = useAppState((state) => state.toggleMenu)


  useOnClickOutside(ref, () => {
    if (menuOpen && window.innerWidth <= 768) {
      toggleMenu()
    }
  })


  return (
    <PageS menuOpen={menuOpen}>
      <Sidebar ref={ref} menuOpen={menuOpen} />
      <div className="page-container">
        <Header/>
        <div className="content-container">
          {children}
        </div>
      </div>
    </PageS>
  )
}

const PageS = styled.div`
  display: flex;
  justify-content: flex-start;

  .page-container {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;

    .content-container {
      background-color: ${colors.neutral.N2};
      flex: 1 1 auto;
      max-width: 100%;
      padding: 84px 20px 0px 20px;

      /* width: 100%; */
      position: relative;
      margin-left: ${(props) => (props.menuOpen ? '256px' : '0px')};

      @media screen and (max-width: 768px) {
        margin-left: 0px;
      }
      transition: transform 0.3s, margin-left 0.3s;
      /* z-index: 1; */
    }
  }
`
