import React from 'react'
import { Pane } from 'evergreen-ui'
import { useFormStore } from './FormModal'
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner'
import { useCapturaProvisionalesStore } from '@Stores/storeCapturaProvisionales'
import { useEffectOnceDeps } from '@Hooks/index'
import { useMutation } from 'react-query'
import CalculoProvisionalesModal from "../Calculo/CalculoProvisionalesModal";
import axios from "@Utils/axios";
import { useCalculoProvisionalesStore } from "../../../../stores/storeCalculoProvisionales";
import _get from 'lodash/get';

const postProvisional = ({ provId, ...params }) => {
  return axios
    .post("/api/v1/provisionales", params)
    .then((response) => response.data);
};

const updateProvisional = ({ provId, ...params }) => {
  return axios
    .put(`/api/v1/provisionales/${provId}`, params)
    .then((response) => response.data);
};

const addMultiplier = (obj, _negativos, field) => {
  let _obj = {};
  Object.entries(obj).forEach(([key, value]) => {
    let multiplier = 1;
    if (_get(_negativos, `${field}.por_comprobar.${key}`)) {
      multiplier = -1;
    }
    _obj[key] = value * multiplier;
  });
  return _obj;
}

export default function Step6({ onSubmit }) {
  const selectedClient = useCapturaProvisionalesStore("selectedClient");
  const [
    emitidos,
    recibidos,
    nominasPatron,
    nominasObrero,
    periodicidad,
    periodo,
    provId,
  ] = useFormStore(
    "emitidos, recibidos, nominasPatron, nominasObrero, periodicidad, periodo, provId"
  );

  const [mutatePost] = useMutation(postProvisional, {
    onSuccess: (data) => {
      // console.log("onSuccessMutation", data);
      onSubmit && onSubmit();
    },
  });

  const [mutatePut] = useMutation(updateProvisional, {
    onSuccess: async (data) => {
      // console.log("onSuccessMutation", data);

      // Checar si existe un calculo donde entraria este provisional
      const response = await axios.get(
        "/api/v1/calculoProvisionales/provisional",
        {
          params: {
            cliente: selectedClient.id,
            fecha_inicial: data.fecha_inicial,
            fecha_final: data.fecha_final,
          },
        }
      );

      if (response.data) {
        // console.log("calculoProvisionales/provisional", response.data);
        if (response.data.calculo) {
          // Hay que actualizar el calculo existente
          useCalculoProvisionalesStore.getState().onOpenExistingCalculo(
            {
              ...selectedClient,
              ...response.data,
            },
            true,
            true,
            () => {
              // console.log("onSubmit captura con calculo");
              onSubmit && onSubmit();
            }
          );
        } else {
          onSubmit && onSubmit();
        }
      }
    },
  });

  useEffectOnceDeps(
    () => {
      const negativos = useFormStore.getState().negativos;

      const mutationData = {
        provId: provId,
        cliente: selectedClient.id,
        periodicidad: periodicidad.key,
        fecha_inicial: periodo.key.split(" ")[0],
        fecha_final: periodo.key.split(" ")[1],
        data_json: {
          emitidos: {
            ...emitidos,
            por_comprobar: addMultiplier(emitidos.por_comprobar, negativos, "emitidos"),
          },
          recibidos: {
            ...recibidos,
            por_comprobar: addMultiplier(recibidos.por_comprobar, negativos, "recibidos"),
          },
          nominasPatron,
          nominasObrero,
          version: 1,
        },
      };

      if (provId) {
        mutatePut(mutationData);
      } else {
        mutatePost(mutationData);
      }
      // mutatePost(postData)
    },
    [
      selectedClient,
      emitidos,
      recibidos,
      nominasPatron,
      nominasObrero,
      periodicidad,
      periodo,
      provId,
    ],
    "sendProv"
  );

  return (
    <Pane paddingBottom={20} paddingTop={20}>
      <Spinner size={SpinnerSize.large} label="Guardando..." />
      <CalculoProvisionalesModal fromParent="capturaProvisionales" />
    </Pane>
  );
}
