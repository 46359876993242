import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Color from 'color';
import colors from '@Root/style/colors';
import { ReactComponent as Logo } from '@Root/assets/onlineServices.svg';
import { useAppState } from '@Stores/storeApp'
import { useUserState } from '@Stores/storeUser';

function activeClass(path, currentPath) {
  return currentPath === path ? { className: 'active' } : {};
}

function LinkItem({ location, to, label }) {
  return (
    <li>
      <Link
        onClick={() => {
          // console.log('route click');
          if (window.innerWidth <= 768) {
            const opened = useAppState.getState().menuOpen;
            opened && useAppState.setState({ menuOpen: false });
          }
        }}
        {...activeClass(to, location.pathname)}
        to={to}
      >
        {label}
      </Link>
    </li>
  );
}

const Sidebar = React.forwardRef(({ menuOpen }, ref) => {
  const location = useLocation();
  const user = useUserState('user');
  const permissions = user?.permissions.map(perm => perm.permission) ?? [];

  return (
    <SidebarS ref={ref} opened={menuOpen}>
      <Logo className="logo" />
      <nav>
        <ul>
          <LinkItem to="/home" location={location} label="Dashboard" />
        </ul>
        {
          (permissions.includes('admins') || permissions.includes('clientes') || permissions.includes('usuarios')) && (
            <React.Fragment>
              <p>Catálogos</p>
              <ul>
                { permissions.includes('admins') &&
                  <LinkItem to="/admins" location={location} label="Administradores" />
                }
                { permissions.includes('clientes') &&
                  <LinkItem to="/clientes" location={location} label="Clientes" />
                }
                { permissions.includes('usuarios') &&
                  <LinkItem to="/usuarios" location={location} label="Usuarios" />
                }
              </ul>
            </React.Fragment>
          )
        }
        {
          permissions.includes('provisionales') && (
            <React.Fragment>
              <p>Provisionales</p>
              <ul>
                <LinkItem to="/capturaProvisionales" location={location} label="Captura de movimientos" />
                <LinkItem to="/calculoProvisionales" location={location} label="Cálculo" />
              </ul>
            </React.Fragment>
          )
        }
        {
          (permissions.includes('departamentos') || permissions.includes('super')) && (
            <React.Fragment>
            <p>Departamentos</p>
            <ul>
              <LinkItem to="/departamentos" location={location} label="Administrador de archivos" />
              <LinkItem to="/departamentosMonitoreo" location={location} label="Monitoreo" />
            </ul>
          </React.Fragment>
          )
        }
        {/* {
          (permissions.includes('chat') || permissions.includes('super')) && (
            <React.Fragment>
            <p>Chat</p>
            <ul>
              <LinkItem to="/chatEquipos" location={location} label="Equipos" />
              <LinkItem to="/chatEtiquetas" location={location} label="Etiquetas" />
            </ul>
          </React.Fragment>
          )
        } */}

      </nav>
    </SidebarS>
  )
});

export default Sidebar;

const SidebarS = styled.div`
  /* height: 100vh; */
  height: 100%;
  top: 0px;
  transform: translateX(${(props) => (props.opened ? '0px' : '-256px')});
  width: ${(props) => (props.opened ? '256px' : '0px')};
  max-height: calc(100% - 0px);
  /* @media screen and (max-width: 768px) {
    background-color: rgba(20,25,37,1);
  } */
  background-color: rgb(40, 46, 62);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s, width 0.3s;
  flex-shrink: 0;
  z-index: 6;
  position: fixed;
  left: 0px;

  nav {
    width: 100%;
    color: ${colors.neutral.light};
    font-family: 'Lato';
    font-size: 14px;
    /* background-color: rgba(16, 21, 33, 0.8); */

    p {
      text-transform: uppercase;
      font-weight: 400;
      /* color: ${colors.neutral.N7}; */
      color: ${colors.blue.B4};
      padding: 16px 20px 10px 20px;
      font-size: 12px;
      /* background-color: rgba(16, 21, 33, 1.0); */
    }

    ul {
      margin-bottom: 20px;
    }

    li {
      font-weight: 600;
      letter-spacing: 0.2px;
      cursor: pointer;
      font-size: 14px;
      color: ${colors.neutral.N7};
      background-color: rgba(25, 30, 42, 0.8);

      a {
        display: flex;
        padding: 14px 20px;
        color: inherit;
        text-decoration: none;
        outline: 0;
      }

      a.active {
        color: ${Color(colors.blue.base).lighten(0.4).rgb()};
      }
    }

    li:hover {
      background-color: ${colors.blue.B1A};
      color: ${colors.neutral.N4};
    }
  }

  .logo {
    width: 180px;
    margin-top: 20px;
    margin-bottom: 20px;

    rect {
      fill: white !important;
      opacity: 0.8;
    }
  }
`;
