import React from "react";
import SectionTitle from "./SectionTitle";
import Section from "./Section";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";

export default function LoadingSection({ title = "" }) {
  return (
    <>
      <SectionTitle text={title} />
      <Section>
        <ProgressIndicator />
      </Section>
    </>
  );
}
