import React from 'react'
import styled from 'styled-components'

export default function Section({ children, style = {}, ...rest }) {
  return (
    <SectionContainer style={style} {...rest}>
      {children}
    </SectionContainer>
  )
}

const SectionContainer = styled.div`
  background: #fff;
  padding: 0px 28px 28px 28px;
  margin-bottom: 28px;
  border-radius: 2px;
  background-clip: padding-box;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 210px);
`
