import React from 'react'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import { Text } from '@fluentui/react/lib/Text'
import { TextField } from '@fluentui/react/lib/TextField'
import { useFormStore } from './FormModal'
import { addEvents } from '../formats'
import { NegTextField } from './FormModalStep2'

const fieldNames = {
  subtotal: 'Subtotal',
  iva: 'IVA',
  ret_isr: 'Ret ISR',
  ret_iva: 'Ret IVA',
}

export default function Step3() {
  const [recibidos, editable, porComprobarEditable] = useFormStore(
    'recibidos, editable, porComprobarEditable'
  )
  const addEventHandlers = addEvents(recibidos, 'recibidos', useFormStore)

  const generateFieldsFor = (category, disabled = false) => {
    const CustomTextField = category === 'por_comprobar' ? NegTextField : TextField;
    return (
      <Pane display="flex" justifyContent="space-between">
        {['subtotal', 'iva', 'ret_isr', 'ret_iva'].map((field) => (
          <CustomTextField
            key={field}
            disabled={disabled}
            className="field"
            label={fieldNames[field]}
            value={recibidos[category][field]}
            fieldPath={`recibidos.${category}.${field}`}
            {...addEventHandlers(`${category}.${field}`)}
          />
        ))}
      </Pane>
    )
  }

  return (
    <Pane marginTop={20}>
      <Text variant="xLarge" styles={{ root: { display: 'flex', justifyContent: 'center' } }}>
        Comprobantes recibidos
      </Text>
      <CategoryContainer variant="magenta" style={{ height: 80 }}>
        <Text variant="medium" className="title">
          PUE
        </Text>
        {generateFieldsFor('pue', !editable)}
      </CategoryContainer>
      <CategoryContainer variant="cyanBlue">
        <Text variant="medium" className="title">
          PPD
        </Text>
        {generateFieldsFor('ppd', !editable)}
      </CategoryContainer>
      <CategoryContainer variant="green">
        <Text variant="medium" className="title">
          Complementos
        </Text>
        {generateFieldsFor('complementos', !editable)}
      </CategoryContainer>
      <CategoryContainer variant="gray">
        <Text variant="medium" className="title">
          Por Comprobar
        </Text>
        {generateFieldsFor('por_comprobar', !porComprobarEditable)}
      </CategoryContainer>
    </Pane>
  )
}

const CategoryContainer = styled.div`
  --color-variant-magenta: #881798aa;
  --color-variant-magenta-transp: #88179815;
  --color-variant-cyanBlue: #004e8caa;
  --color-variant-cyanBlue-transp: #004e8c15;
  --color-variant-green: #0b6a0baa;
  --color-variant-green-transp: #0b6a0b15;
  --color-variant-gray: #393939aa;
  --color-variant-gray-transp: #39393915;

  border-bottom: solid 1px var(--color-variant-${(props) => props.variant});
  background-color: var(--color-variant-${(props) => props.variant}-transp);
  height: 80px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    background: var(--color-variant-${(props) => props.variant});
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: bold;
    color: white;
  }

  .field {
    width: 110px;
    margin-top: -10px;

    input {
      text-align: right;
    }
  }
`
