import React from 'react';

export const useViewport = () => {
  const [width, setWidth] = React.useState(window.visualViewport?.width ?? window.innerWidth);
  const [height, setHeight] = React.useState(window.visualViewport?.height ??window.innerHeight);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.visualViewport?.width ?? window.innerWidth);
      setHeight(window.visualViewport?.height ?? window.innerHeight);
    }
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width, height };
}
