import React from 'react'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import { Text } from '@fluentui/react/lib/Text'
import { useFormStore } from './FormModal'
import _get from 'lodash/get'

// const fieldNames = {
//   percepciones: 'Percepciones',
//   ispt: 'ISPT',
//   asimilados: 'Asalariados',
//   imss: 'IMSS',
//   sar: 'SAR',
//   infonavit: 'Infonavit',
//   porciento_3: '3%',
// }

function sumAll(obj, negativos, fieldPath) {
  let sum = 0

  const cleanValue = (val) => typeof val === 'string'
    // eslint-disable-next-line no-useless-escape
    ? val.replace(/[^\d*|\.]/g, '') : val

  let subtotalMultiplier = 1;
  let ivaMultiplier = 1;
  let retIsrMultiplier = 1;
  let retIvaMultiplier = 1;

  if (_get(negativos, `${fieldPath}.subtotal`)) {
    subtotalMultiplier = -1;
  }

  if (_get(negativos, `${fieldPath}.iva`)) {
    ivaMultiplier = -1;
  }

  if (_get(negativos, `${fieldPath}.ret_isr`)) {
    retIsrMultiplier = -1;
  }

  if (_get(negativos, `${fieldPath}.ret_iva`)) {
    retIvaMultiplier = -1;
  }

  const subtotal = cleanValue(obj.subtotal);
  const iva = cleanValue(obj.iva);
  const ret_isr = cleanValue(obj.ret_isr);
  const ret_iva = cleanValue(obj.ret_iva);

  sum += subtotal * 100 * subtotalMultiplier;
  sum += iva * 100 * ivaMultiplier;
  sum -= ret_isr * 100 * retIsrMultiplier;
  sum -= ret_iva * 100 * retIvaMultiplier;

  return sum === 0 ? sum : sum / 100
}

function numFormat(num, multiplier = 1) {
  let val = num
  if (typeof num === 'string') {
    // eslint-disable-next-line no-useless-escape
    val = num.replace(/[^\d*|\.]/g, '')
  }
  const number = parseFloat(val) * multiplier

  return new Intl.NumberFormat('es-MX', {
    minimumFractionDigits: number !== 0 ? 2 : 0,
    maximumFractionDigits: 2,
    signDisplay: 'auto'
  }).format(number)
}

function getMultiplier(_negativos, fieldPath) {
  let multiplier = 1;
  if (_get(_negativos, fieldPath)) {
    multiplier = -1;
  }
  return multiplier;
}

export default function Step5() {
  const [
    emitidos,
    recibidos,
    nominasPatron,
    nominasObrero,
    readOnly,
    periodo,
    negativos
  ] = useFormStore(
    "emitidos, recibidos, nominasPatron, nominasObrero, readOnly, periodo, negativos"
  );

  return (
    <Pane style={{ marginTop: !readOnly ? 20 : -20 }}>
      {!readOnly ? (
        <Text
          variant="xLarge"
          styles={{ root: { display: "flex", justifyContent: "center" } }}
        >
          Por favor revisa que la información sea correcta
        </Text>
      ) : (
        <Text
          variant="medium"
          style={{ fontFamily: "Segoe_UI", fontWeight: "400" }}
          styles={{
            root: { display: "flex", justifyContent: "flex-end" },
          }}
        >
          {periodo.text}
        </Text>
      )}
      <Pane>
        <CategoryContainer variant="cyanBlue">
          <Text variant="medium" className="title">
            Comprobantes emitidos
          </Text>
          <table className="details-table">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Ret ISR</th>
                <th>Ret IVA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PUE</td>
                <td>{numFormat(emitidos.pue.subtotal)}</td>
                <td>{numFormat(emitidos.pue.iva)}</td>
                <td>{numFormat(emitidos.pue.ret_isr)}</td>
                <td>{numFormat(emitidos.pue.ret_iva)}</td>
                <td>{numFormat(sumAll(emitidos.pue))}</td>
              </tr>
              <tr>
                <td>PPD</td>
                <td>{numFormat(emitidos.ppd.subtotal)}</td>
                <td>{numFormat(emitidos.ppd.iva)}</td>
                <td>{numFormat(emitidos.ppd.ret_isr)}</td>
                <td>{numFormat(emitidos.ppd.ret_iva)}</td>
                <td>{numFormat(sumAll(emitidos.ppd))}</td>
              </tr>
              <tr>
                <td>Compl.</td>
                <td>{numFormat(emitidos.complementos.subtotal)}</td>
                <td>{numFormat(emitidos.complementos.iva)}</td>
                <td>{numFormat(emitidos.complementos.ret_isr)}</td>
                <td>{numFormat(emitidos.complementos.ret_iva)}</td>
                <td>{numFormat(sumAll(emitidos.complementos))}</td>
              </tr>
              <tr>
                <td>X Compr.</td>
                <td>{numFormat(emitidos.por_comprobar.subtotal, getMultiplier(negativos, 'emitidos.por_comprobar.subtotal'))}</td>
                <td>{numFormat(emitidos.por_comprobar.iva, getMultiplier(negativos, 'emitidos.por_comprobar.iva'))}</td>
                <td>{numFormat(emitidos.por_comprobar.ret_isr, getMultiplier(negativos, 'emitidos.por_comprobar.ret_isr'))}</td>
                <td>{numFormat(emitidos.por_comprobar.ret_iva, getMultiplier(negativos, 'emitidos.por_comprobar.ret_iva'))}</td>
                <td>{numFormat(sumAll(emitidos.por_comprobar, negativos, 'emitidos.por_comprobar'))}</td>
              </tr>
            </tbody>
          </table>
        </CategoryContainer>

        <CategoryContainer variant="green">
          <Text variant="medium" className="title">
            Comprobantes recibidos
          </Text>
          <table className="details-table">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Subtotal</th>
                <th>IVA</th>
                <th>Ret ISR</th>
                <th>Ret IVA</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PUE</td>
                <td>{numFormat(recibidos.pue.subtotal)}</td>
                <td>{numFormat(recibidos.pue.iva)}</td>
                <td>{numFormat(recibidos.pue.ret_isr)}</td>
                <td>{numFormat(recibidos.pue.ret_iva)}</td>
                <td>{numFormat(sumAll(recibidos.pue))}</td>
              </tr>
              <tr>
                <td>PPD</td>
                <td>{numFormat(recibidos.ppd.subtotal)}</td>
                <td>{numFormat(recibidos.ppd.iva)}</td>
                <td>{numFormat(recibidos.ppd.ret_isr)}</td>
                <td>{numFormat(recibidos.ppd.ret_iva)}</td>
                <td>{numFormat(sumAll(recibidos.ppd))}</td>
              </tr>
              <tr>
                <td>Compl.</td>
                <td>{numFormat(recibidos.complementos.subtotal)}</td>
                <td>{numFormat(recibidos.complementos.iva)}</td>
                <td>{numFormat(recibidos.complementos.ret_isr)}</td>
                <td>{numFormat(recibidos.complementos.ret_iva)}</td>
                <td>{numFormat(sumAll(recibidos.complementos))}</td>
              </tr>
              <tr>
                <td>X Compr.</td>
                <td>{numFormat(recibidos.por_comprobar.subtotal, getMultiplier(negativos, 'recibidos.por_comprobar.subtotal'))}</td>
                <td>{numFormat(recibidos.por_comprobar.iva, getMultiplier(negativos, 'recibidos.por_comprobar.iva'))}</td>
                <td>{numFormat(recibidos.por_comprobar.ret_isr, getMultiplier(negativos, 'recibidos.por_comprobar.ret_isr'))}</td>
                <td>{numFormat(recibidos.por_comprobar.ret_iva, getMultiplier(negativos, 'recibidos.por_comprobar.ret_iva'))}</td>
                <td>{numFormat(sumAll(recibidos.por_comprobar, negativos, 'recibidos.por_comprobar'))}</td>
              </tr>
            </tbody>
          </table>
        </CategoryContainer>

        <CategoryContainer variant="cyanBlue" style={{ height: 120 }}>
          <Text variant="medium" className="title">
            Nóminas Patrón
          </Text>
          <table className="details-table">
            <thead>
              <tr>
                <th>Per Nómina</th>
                <th>Per Asimilados</th>
                <th>S/Nómina</th>
                <th>R H Asimilados</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numFormat(nominasPatron.perc_nomina)}</td>
                <td>{numFormat(nominasPatron.perc_asimilados)}</td>
                <td>{numFormat(nominasPatron.nomina_porciento_3)}</td>
                <td>{numFormat(nominasPatron.ret_hon_asimilados)}</td>
              </tr>
            </tbody>
          </table>
          <table className="details-table" style={{ marginTop: 20 }}>
            <thead>
              <tr>
                <th>ISPT</th>
                <th>IMSS</th>
                <th>SAR</th>
                <th>Infonavit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numFormat(nominasPatron.ispt)}</td>
                <td>{numFormat(nominasPatron.imss)}</td>
                <td>{numFormat(nominasPatron.sar)}</td>
                <td>{numFormat(nominasPatron.infonavit)}</td>
              </tr>
            </tbody>
          </table>
        </CategoryContainer>

        <CategoryContainer variant="cyanBlue" style={{ height: 70 }}>
          <Text variant="medium" className="title">
            Nóminas Obrero
          </Text>
          <table className="details-table">
            <thead>
              <tr>
                <th>IMSS</th>
                <th>SAR</th>
                <th>Infonavit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numFormat(nominasObrero.imss)}</td>
                <td>{numFormat(nominasObrero.sar)}</td>
                <td>{numFormat(nominasObrero.infonavit)}</td>
              </tr>
            </tbody>
          </table>
        </CategoryContainer>
      </Pane>
    </Pane>
  );
}

const colorVariants = {
  magenta: "rgba(136, 23, 152, 0.67)",
  "magenta-transp": "rgba(136, 23, 152, 0.08)",
  cyanBlue: "rgb(84,136,178)",
  "cyanBlue-transp": "rgb(235,241,246)",
  green: "rgb(92,155,92)",
  "green-transp": "rgb(235,243,235)",
  gray: "rgba(57, 57, 57, 0.67)",
  "gray-transp": "rgba(57, 57, 57, 0.08)",
};

const CategoryContainer = styled.div`
  /*--color-variant-magenta: rgba(136, 23, 152, 0.67);
  --color-variant-magenta-transp: rgba(136, 23, 152, 0.08);
  --color-variant-cyanBlue: rgb(84,136,178);
  --color-variant-cyanBlue-transp: rgb(235,241,246);
  --color-variant-green: rgba(11, 106, 11, 0.67);
  --color-variant-green-transp: rgba(11, 106, 11, 0.08);
  --color-variant-gray: rgba(57, 57, 57, 0.67);
  --color-variant-gray-transp: rgba(57, 57, 57, 0.08);
  */

  border-bottom: solid 1px ${(props) => colorVariants[props.variant]};
  background: ${(props) => colorVariants[`${props.variant}-transp`]};
  height: 80px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .fields-header {
    display: flex;
  }

  .fields-header > * {
    width: 80px;
    display: flex;
    border: solid 1px red;
  }

  .details-table {
    thead {
      border-bottom: solid 2px ${(props) => colorVariants[props.variant]};

      th:first-child {
        text-align: left;
      }

      th {
        padding-bottom: 2px;
        text-align: right;
      }
    }
    th,
    td {
      font-size: 12px;
      font-family: "Segoe_UI";
    }
    tbody td {
      text-align: right;
    }
    tbody tr:first-child td {
      padding-top: 4px;
    }
    tbody tr td:first-child {
      text-align: left;
    }
  }

  .title {
    background: ${(props) => colorVariants[props.variant]};
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    position: absolute;
    top: -20px;
    left: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: bold;
    color: white;
    font-family: "Segoe_UI_Bold";
  }

  .field {
    width: 110px;
    margin-top: -10px;

    input {
      text-align: right;
    }
  }
`;
