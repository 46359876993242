import React from 'react'
import create, { LogLevel } from "zustand-store-addons";
import { useFormStore } from "@Screens/pages/Users/FormModal";
import axios from "@Utils/axios";
import flatMap from "lodash/flatMap";
import isEqual from "lodash/isEqual";
import _get from "lodash/get";
import last from "lodash/last";
import { queryCache } from "@Root/index";
import colors from "../style/colors";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { getSectionSettings } from "../utils";

const getConfigurableParams = (qParams) => {
  let params = { ...qParams };
  delete params.direction;
  delete params.order_by;
  delete params.results;
  return params;
};

const initialState = {
  list: [],
  clientList: [],
  refetchFunction: null,
  compactMode: false,
  dialogRef: null,
  activeRowId: null,
  selectedUser: null,
  formVisible: false,
  dialogVisible: false,
  showingConfig: false,
  pageInfo: {},
  fetching: true,
  fetchMore: null,

  queryParams: {
    tipo_persona: "all",
    prioridad: "all",
    razon_social: "",
    rfc: "",
    socio: "all",
    numero_control: "",
    direction: "first",
    order_by: "fullname",
    results: 20,
  },
};

export const useUsersStore = create(
  (set, get) => ({
    ...initialState,

    resetStore: () => {
      set(initialState);
    },

    setConfig: (params) => {
      set({
        pageInfo: {},
        list: [],
        fetching: true,
        queryParams:
          typeof params === "function" ? params(get().queryParams) : params,
      });
    },

    loadSettings: () => {
      const settings = getSectionSettings("clientes");
      // console.log("loadSettings", settings);
      const currentSettings = get().queryParams;
      set({
        queryParams: {
          ...currentSettings,
          results: _get(settings, "resultsPerBatch", 20),
        },
      });
    },

    setDialogRef: (ref) => {
      set({ dialogRef: ref });
    },

    onUsersFetch: async (data) => {
      set({
        list: flatMap(data, (data) => data.users),
        pageInfo: last(data).pageInfo,
        fetching: false,
      });
    },

    onFetchMoreBtn: () => {
      if (typeof get().fetchMore === "function") {
        set({
          fetching: true,
        });
        get().fetchMore();
      }
    },

    onUserEdit: (item) => {
      set({ selectedUser: item, formVisible: true });
    },

    onUserDelete: (item) => {
      const dialog = get().dialogRef;
      dialog.open({
        title: "Confirmación requerida",
        subtitle: `¿Deseas eliminar la cuenta de acceso de: ${item.fullname}?`,
        cancelLabel: "No",
        actionLabel: "Sí",
        busyText: "Eliminando",
        actionFunction: async () => {
          axios
            .delete(`/api/v1/usuarios/${item.id}`)
            .then(({ data }) => {
              if (data.ok) {
                queryCache.refetchQueries(["usersList"]);
              }
            })
            .catch((e) => console.log(e));
        },
      });
    },
  }),
  {
    computed: {
      FiltersButton(ctx) {
        const currentParams = this.queryParams;

        return ({ style = {} }) => (
          <DefaultButton
            onClick={() => ctx.set({ showingConfig: true })}
            text="Configuración"
            style={{ alignSelf: "flex-end", ...style }}
            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
            iconProps={{
              iconName: "FilterSolid",
              style: {
                fontSize: 10,
                color: isEqual(
                  getConfigurableParams(currentParams),
                  getConfigurableParams(initialState.queryParams)
                )
                  ? colors.neutral.N5
                  : colors.green.base,
              },
            }}
          />
        );
      },
    },

    watchers: {
      selectedUser(newValue) {
        useFormStore.setState({ selectedUser: newValue });
      },
    },
    settings: {
      name: LogLevel.None,
      logLevel: "diff",
    },
  }
);