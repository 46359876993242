import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Label as ELabel } from '@fluentui/react/lib/Label';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import CustomModal from '@Components/CustomModal'
import {
  DefaultPalette,
  mergeStyleSets,
} from '@fluentui/react/lib/Styling';
import { TextInputField, Pane, Spinner, TagInput, toaster } from 'evergreen-ui';
import { useViewport } from '@Hooks/useViewport';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import axios from '@Utils/axios';
import { useClientsStore } from '@Stores/storeClients'
import { addWhenFieldError, FieldErrorMessage, isNumberKey, difference, validateEmail } from '@Utils/index';
import { Separator } from '@fluentui/react/lib/Separator';
import create, { LogLevel } from 'zustand-store-addons';
import { useQuery } from 'react-query';
import { useCallback } from 'react';
import { useWatchValue } from '@Hooks/index';
import { queryCache } from '@Root/index';
import { useUserState } from '@Stores/storeUser';

/* eslint-disable no-useless-escape */
const mobileAndTabletCheck = function () {
  let check = false;

  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
/* eslint-enable no-useless-escape */

const radioOptionHorStyle = {
  styles: mergeStyleSets({
    root: { marginTop: 0 },
    field: { marginRight: 20, display: 'flex', alignItems: 'center' },
  }),
};

const comboStyle = {
  input: {
    fontFamily:
      '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, system-ui, Roboto, "Helvetica Neue", sans-serif;',
    fontSize: 12,
    fontWeight: 400,
    color: 'rgb(66, 90, 112)',
    borderTop: 'inset 1px rgba(67, 90, 111, 0.3)',
    borderBottom: 'inset 1px rgba(67, 90, 111, 0.3)',
  },
  rootFocused: {
    boxShadow:
      'inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);',
    borderRadius: '3px',
    selectors: {
      '::after': {
        borderStyle: 'none',
      },
      input: {
        borderTopColor: '#579ad9',
        borderTopStyle: 'solid',
        borderBottomColor: '#579ad9',
        borderBottomStyle: 'solid',
      },
    },
  },
  root: {
    boxShadow: 'inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);',
    selectors: {
      '::after': {
        borderStyle: 'none',
      },
      button: {
        backgroundColor: 'transparent !important',
      },
      'button:active': {
        backgroundColor: 'transparent !important',
      },
    },
    borderRadius: '3px',
  },
};

const dropDownStyle = mergeStyleSets({
  dropdown: {
    borderStyle: 'none',
    selectors: {
      '.ms-Dropdown-title': {
        borderStyle: 'none',
        boxShadow:
          'inset 0 0 0 1px rgba(67, 90, 111, 0.3), inset 0 1px 2px rgba(67, 90, 111, 0.14);',
        borderRadius: '3px',
        fontFamily:
          '"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, system-ui, Roboto, "Helvetica Neue", sans-serif;',
        fontSize: 12,
        fontWeight: 400,
        color: 'rgb(66, 90, 112)',
      },
      ':focus::after': {
        borderStyle: 'none',
      },
      ':focus .ms-Dropdown-title': {
        boxShadow:
          'inset 0 0 2px rgba(67, 90, 111, 0.14), inset 0 0 0 1px #579ad9, 0 0 0 3px rgba(16, 112, 202, 0.14);',
        borderRadius: '3px',
        borderWidth: '1px',
        color: 'rgb(66, 90, 112)',
      },
    },
  },
});

function onSubmitRender(props, defaultRender) {
  if (props.disabled) {
    const text = props.text === 'Agregar' ? 'Agregando' : 'Actualizando';

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Spinner size={16} marginRight={4} />
        <span style={{ fontWeight: 600 }}>{text}</span>
      </div>
    );
  }

  return defaultRender(props);
}

async function getRegimenesFiscales() {
  const { data } = await axios.get('/api/v1/catalogos/regimenes');
  return [{ clave: '0', descripcion: 'Selecciona' }, ...data];
}

async function getGiros() {
  const { data } = await axios.get('/api/v1/catalogos/giros');
  return [{ id: 0, nombre: 'Selecciona' }, ...data];
}

async function getEstados() {
  const { data } = await axios.get('/api/v1/catalogos/estados');
  return [{ clave: '0', nombre: '' }, ...data];
}

async function getMunicipios(key, { estado }) {
  if (estado === '0') return [];

  const { data } = await axios.get('/api/v1/catalogos/municipios/' + estado);
  return [{ clave: '0', nombre: '' }, ...data];
}

export const useFormStore = create(
  (set, get) => ({
    containerDivId: 'useFormStore',
    sending: false,
    queriesController: null,
    title: 'Crea un nuevo cliente',
    regimenes: [{ clave: '0', descripcion: 'Selecciona' }],
    estados: [{ clave: '0', nombre: '' }],
    municipios: [{ clave: '0', nombre: '' }],
    giros: [{ id: 0, nombre: 'Selecciona'}],

    selectedClient: null,
    updateRegimenes: (regimenes) => set({ regimenes }),
    updateEstados: (estados) => set({ estados }),
    updateMunicipios: (municipios) => set({ municipios }),
    updateGiros: (giros) => set({ giros }),
  }),
  {
    watchers: {
      selectedClient(newValue) {
        this.set({
          selectedRegimen: newValue?.regimen_fiscal ?? '0',
          title: newValue ? 'Modifica un cliente existente' : 'Crea un nuevo cliente',
        });
        if (newValue) {
          if (this.get().queriesController) {
            this.get().queriesController.fetchRegimenes();
          }
        }
      },
    },
    settings: {
      logLevel: LogLevel.None,
      name: 'ClientsFormStore',
    },
  }
);

const QueriesController = React.forwardRef(({ estado }, ref) => {
  const [_estado, setEstado] = useState();
  const prevEstadoRef = useRef(estado);
  const mountedRef = useRef(false);

  const { refetch: fetchGiros } = useQuery('getGiros', getGiros, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => useFormStore.getState().updateGiros(data),
  })

  const { refetch: fetchRegimenes } = useQuery('getRegimenes', getRegimenesFiscales, {
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    onSuccess: (data) => useFormStore.getState().updateRegimenes(data),
  });

  const { refetch: fetchEstados } = useQuery('getEstados', getEstados, {
    enabled: false,
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
    onSuccess: (data) => useFormStore.getState().updateEstados(data),
  });

  const { refetch: _fetchMunicipios } = useQuery(
    ['getMunicipios', { estado: _estado }],
    getMunicipios,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => useFormStore.getState().updateMunicipios(data),
    }
  );

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!mountedRef.current) return;
    fetchGiros && fetchGiros()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if (estado !== undefined && estado !== '0' && estado !== '' && prevEstadoRef.current !== estado) {
    if (prevEstadoRef.current !== estado && mountedRef.current) {
      prevEstadoRef.current = estado;
      setEstado(estado);
    }
  }, [estado]);

  const fetchMunicipios = useCallback(
    (customEstado) => {
      if (!mountedRef.current) return;

      if (customEstado) {
        setEstado(customEstado);
      }
      setTimeout(() => {
        if (_estado !== '0') {
          _fetchMunicipios();
        }
      }, 0);
    },
    [_estado, _fetchMunicipios]
  );

  useEffect(() => {
    if (!mountedRef.current) return;

    if (_estado && _estado !== '0' && _estado && '') {
      fetchMunicipios();
    }
  }, [_estado, fetchMunicipios]);

  React.useImperativeHandle(ref, () => ({
    fetchRegimenes,
    fetchEstados,
    fetchMunicipios,
    key: _estado,
  }));

  return null;
});

export function updateClientOnDB(method, dataToSend) {
  return new Promise((resolve, reject) => {
    axios[method]("/api/v1/clientes", { clientData: dataToSend })
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
}

export default function FormModal() {
  const { width, height } = useViewport();
  const isOpen = useClientsStore('formVisible');
  const prevOpenRef = useRef(false);
  const queryControllerKeyRef = useRef(-1);
  const hasPermission = useUserState('hasPermission')

  const [title, sending, regimenes, selectedClient, estados, municipios, giros] = useFormStore(
    `title, sending, regimenes, selectedClient, estados, municipios, giros`
  );

  const { handleSubmit, errors, control, watch, trigger, setValue } = useForm();

  const currentPersona = watch('tipo_persona');

  const currentPais = useWatchValue(
    watch,
    'pais',
    selectedClient?.pais ?? '0',
    (newValue, prevValue) => {
      setTimeout(() => {
        setValue('estado', selectedClient?.pais === newValue ? selectedClient?.estado : '0');
      }, 0);
    }
  );

  useEffect(() => {
    if (isOpen && prevOpenRef.current === false) {
      setTimeout(() => {
        setValue('estado', selectedClient?.estado ?? '0');
      }, 0);
    }
    prevOpenRef.current = isOpen;
  }, [isOpen, selectedClient, setValue]);

  const currentEstado = useWatchValue(
    watch,
    'estado',
    selectedClient?.estado ?? '0',
    async (newValue, prevValue) => {
      if (currentPais === 'MEX') {
        await useFormStore.getState().queriesController.fetchMunicipios(newValue);
      }

      if ((selectedClient?.estado ?? newValue) !== newValue) {
        setTimeout(() => {
          setValue('municipio', currentPais === 'MEX' ? '0' : '');
        }, 0);
        return;
      }

      setTimeout(() => {
        setValue(
          'municipio',
          selectedClient?.pais === 'MEX' && newValue === selectedClient?.estado
            ? selectedClient?.municipio_clave
            : currentPais === 'MEX'
            ? '0'
            : ''
        );
      }, 0);
    }
  );

  const municipio_defaultValue = selectedClient?.municipio ? selectedClient?.municipio : '0';

  function onQueryController(ref) {
    if (!ref) return;
    if (queryControllerKeyRef.current !== ref.key && ref.key !== undefined) {
      // console.log('ref.key', ref.key, typeof ref.key);
      queryControllerKeyRef.current = ref.key;
      useFormStore.getState().queriesController = ref;
      useFormStore.getState().queriesController.fetchRegimenes();
      useFormStore.getState().queriesController.fetchEstados();
    }
  }

  function onDismissed() {
    useClientsStore.setState({
      selectedClient: null,
    });
  }

  function onClose() {
    useClientsStore.setState({
      formVisible: false,
    });
    setValue("estado", "0");
  }

  function onSubmit(data) {
    useFormStore.setState({ sending: true });
    let clientData = { ...data };

    if (data.municipio !== "" && data.municipio.length < 4) {
      clientData.municipio = municipios.find(
        (mun) => mun.clave === data.municipio
      ).nombre;
    }

    let httpMethod = "post";
    let dataToSend;

    if (selectedClient) {
      clientData.id = selectedClient.id;
      httpMethod = "put";
      const changed = difference(clientData, selectedClient);

      if (changed.email) {
        changed.email = clientData.email;
      }

      if (Object.keys(changed).length === 0) {
        useFormStore.setState({ sending: false });
        setTimeout(onClose, 200);
        return;
      } else {
        dataToSend = { id: selectedClient.id, ...changed };
      }
    } else {
      dataToSend = clientData;
    }

    // console.log(dataToSend);
    // return;

    updateClientOnDB(httpMethod, dataToSend)
      .then((data) => {
        // console.log('data from server', data);
        queryCache.refetchQueries(["clientsList"]);
        useFormStore.setState({ sending: false });
        setTimeout(() => {
          onClose();
        }, 200);
      })
      .catch((e) => {
        if (
          e.response?.data &&
          e.response?.data.includes("clientes_unique_rfc")
        ) {
          toaster.danger("Ya existe un cliente con el RFC proporcionado.");
          useFormStore.setState({ sending: false });
        } else {
          console.log(e);
          toaster.danger(
            "No se pudo agregar el cliente. Por favor intenta de nuevo más tarde."
          );
          useFormStore.setState({ sending: false });
          setTimeout(() => {
            onClose();
          }, 200);
        }
      });
  }

  return (
    <CustomModal
      title={title}
      isOpen={isOpen}
      isBlocking={true}
      onDismissed={onDismissed}
      onClose={onClose}
      styles={{
        root: { display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' },
        main: {
          width: width <= 768 || mobileAndTabletCheck() ? '100vw' : '70vw',
          ...(width <= 768 || mobileAndTabletCheck() ? { height: height, top: 0 } : {}),
          margin: 'auto',
        },
      }}
    >
      <ModalContent>
        {isOpen && (
          <QueriesController ref={(ref) => onQueryController(ref)} estado={currentEstado} />
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={!hasPermission("clientes", "write")}>
            <Pane display="flex" flexFlow="row wrap">
              {/* <TestContainer /> */}
              <Controller
                control={control}
                name="rfc"
                className="rfc"
                defaultValue={selectedClient?.rfc ?? ''}
                as={TextInputField}
                label={<Label>RFC*</Label>}
                maxLength={13}
                style={{ textTransform: 'uppercase' }}
                rules={{
                  required: 'Este campo es requerido',
                }}
                {...addWhenFieldError('rfc', errors)}
              />
              <div style={{ display: 'flex', flex: 1 }}>
                <Controller
                  control={control}
                  defaultValue={selectedClient?.tipo_persona ?? null}
                  render={({ onChange, onBlur, value, ...rest }) => (
                    <Pane display="flex" flexDirection="column">
                      <ChoiceGroup
                        {...rest}
                        disabled={!hasPermission("clientes", "write")}
                        defaultSelectedKey={selectedClient?.tipo_persona ?? null}
                        label={<Label style={{ marginBottom: 4 }}>Persona*</Label>}
                        options={[
                          { key: 'fisica', text: 'Física', ...radioOptionHorStyle },
                          { key: 'moral', text: 'Moral', ...radioOptionHorStyle },
                        ]}
                        styles={{
                          flexContainer: { display: 'flex' },
                        }}
                        onChange={(val, option) => {
                          onChange(option.key)
                          trigger('tipo_persona')
                          setValue('regimen_fiscal', '0')
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="tipo_persona"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={6} />
                        )}
                      />
                    </Pane>
                  )}
                  name="tipo_persona"
                  rules={{
                    required: 'Este campo es requerido',
                  }}
                  {...addWhenFieldError('tipo_persona', errors)}
                />

              </div>

            </Pane>
            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  name="socio"
                  defaultValue={selectedClient?.socio ?? '0'}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <Dropdown
                        allowFreeform={false}
                        label={<Label>Socio*</Label>}
                        disabled={!hasPermission("clientes", "write")}
                        options={[
                          { key: '0', text: 'Selecciona' },
                          { key: 'José Luis Castillo', text: 'José Luis Castillo' },
                          { key: 'Fernando García', text: 'Fernando García' },
                        ]}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="socio"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                  rules={{
                    validate: (val) => {
                      return val !== '0' || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('socio', errors)}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  name="periodicidad"
                  // defaultValue={selectedClient?.periodicidad ?? '0'}
                  defaultValue={'mensual'}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <Dropdown
                        allowFreeform={false}
                        label={<Label>Periodicidad de cálculos*</Label>}
                        // disabled={!hasPermission("clientes", "write")}
                        disabled={true}
                        options={[
                          { key: '0', text: 'Selecciona' },
                          { key: 'mensual', text: 'Mensual' },
                          { key: 'bimestral', text: 'Bimestral' },
                        ]}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="periodicidad"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                  rules={{
                    validate: (val) => {
                      return val !== '0' || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('periodicidad', errors)}
                />
              </Pane>
            </Pane>
            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1" paddingBottom={24}>
                <Controller
                  control={control}
                  defaultValue={selectedClient?.prioridad ?? 0}
                  name="prioridad"
                  rules={{
                    validate: (val) => {
                      return val !== 0 || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('prioridad', errors)}
                  render={({ onChange, onBlur, value, ...rest }) => (
                    <>
                      <Dropdown
                        allowFreeform={false}
                        disabled={!hasPermission("clientes", "write")}
                        label={<Label>Prioridad*</Label>}
                        options={[
                          { key: 0, text: 'Selecciona' },
                          { key: 1, text: 'Alta' },
                          { key: 2, text: 'Media' },
                          { key: 3, text: 'Baja' },
                        ]}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                          trigger('priodidad')
                        }}

                      />
                      <ErrorMessage
                        errors={errors}
                        name="prioridad"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </>
                  )}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1" paddingBottom={24}>
                <Controller
                  control={control}
                  defaultValue={selectedClient?.giro ?? 0}
                  name="giro"
                  rules={{
                    validate: (val) => {
                      return val !== 0 || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('giro', errors)}
                  render={({ onChange, onBlur, value, ...rest }) => (
                    <>
                      <Dropdown
                        allowFreeform={false}
                        disabled={!hasPermission("clientes", "write")}
                        label={<Label>Giro*</Label>}
                        options={giros.map(giro => ({ key: giro.id, text: giro.nombre }))}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                          trigger('giro')
                        }}

                      />
                      <ErrorMessage
                        errors={errors}
                        name="giro"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </>
                  )}
                />
              </Pane>
            </Pane>
            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  defaultValue={selectedClient?.razon_social ?? ''}
                  style={{ minWidth: 280 }}
                  as={TextInputField}
                  label={<Label>Razón Social*</Label>}
                  name="razon_social"
                  rules={{
                    required: 'Este campo es requerido',
                  }}
                  {...addWhenFieldError('razon_social', errors)}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  defaultValue={selectedClient?.numero_control ?? ''}
                  as={TextInputField}
                  label={<Label>Número de control</Label>}
                  name="numero_control"
                />
              </Pane>
            </Pane>

            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  name="regimen_fiscal"
                  defaultValue={selectedClient?.regimen_fiscal ?? '0'}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <Dropdown
                        // defaultSelectedKey={selectedClient?.regimen_fiscal ?? '0'}
                        allowFreeform={false}
                        disabled={!hasPermission("clientes", "write")}
                        label={<Label>Régimen Fiscal*</Label>}
                        options={regimenes
                          .filter(
                            (regimen) =>
                              regimen.clave === '0' ||
                              (!!currentPersona ? regimen[currentPersona] : false)
                          )
                          .map((regimen) => ({
                            key: String(regimen.clave),
                            text: regimen.descripcion,
                          }))}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                          trigger('regimen_fiscal')
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="regimen_fiscal"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                  rules={{
                    validate: (val) => {
                      return val !== '0' || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('regimen_fiscal', errors)}
                />
              </Pane>
            </Pane>
            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  style={{ minWidth: 280 }}
                  control={control}
                  defaultValue={selectedClient?.email ?? []}
                  name="email"
                  rules={{
                    validate: (val) => val.length > 0 || 'Campo obligatorio'
                  }}
                  {...addWhenFieldError('email', errors)}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <ELabel style={{color: '#605e5c'}}>Emails*</ELabel>
                      <TagInput
                        name="email"
                        disabled={!hasPermission("clientes", "write")}
                        tagProps={value => {
                          return { color: 'blue', isSolid: false }
                        }}
                        style={{ width: '100%', minWidth: 280 }}
                        addOnBlur={true}
                        tagSubmitKey="space"
                        inputProps={{ placeholder: 'Agrega un email...' }}
                        values={value}
                        onAdd={({ length, [length - 1]: newValue }) => {
                          if (!validateEmail(newValue)) {
                            toaster.danger('Por favor ingresa un correo válido.')
                            return
                          }
                          onChange([...value, newValue]);
                        }}
                        onRemove={(newValue, index) => {
                          onChange(value.filter((_, i) => i !== index));
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  style={{ minWidth: 280 }}
                  control={control}
                  name="telefono"
                  defaultValue={selectedClient?.telefono ?? []}
                  // as={TextInputField}
                  // label={<Label>Teléfonos* (10 dígitos)</Label>}
                  rules={{
                    validate: (val) => val.length > 0 || 'Campo obligatorio'
                  }}
                  {...addWhenFieldError('telefono', errors)}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <ELabel style={{color: '#605e5c'}}>Teléfonos* (10 dígitos)</ELabel>
                      <TagInput
                        onKeyPress={(e) => {
                          if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        name="telefono"
                        disabled={!hasPermission("clientes", "write")}
                        tagProps={value => {
                          return { color: 'blue', isSolid: false }
                        }}
                        style={{ width: '100%', minWidth: 280 }}
                        addOnBlur={true}
                        tagSubmitKey="space"
                        inputProps={{ placeholder: 'Agrega un número...' }}
                        values={value}
                        onAdd={({ length, [length - 1]: newValue }) => {
                          if (!newValue || newValue.length !== 10) {
                            toaster.danger('Por favor ingresa un número de 10 dígitos')
                            return
                          }
                          onChange([...value, newValue]);
                        }}
                        onRemove={(newValue, index) => {
                          onChange(value.filter((_, i) => i !== index));
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="telefono"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                />
              </Pane>
            </Pane>

            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  defaultValue={selectedClient?.calle ?? ''}
                  as={TextInputField}
                  label={<Label>Calle</Label>}
                  name="calle"
                />
              </Pane>
              <Pane display="flex" flexFlow="row nowrap">
                <Controller
                  control={control}
                  defaultValue={selectedClient?.no_ext ?? ''}
                  as={TextInputField}
                  style={{ width: 60, marginRight: 24 }}
                  label={<Label>No. Ext</Label>}
                  name="no_ext"
                  className="no_ext"
                />
                <Controller
                  control={control}
                  defaultValue={selectedClient?.no_int ?? ''}
                  as={TextInputField}
                  style={{ width: 60 }}
                  label={<Label>No. Int</Label>}
                  name="no_int"
                />
              </Pane>
            </Pane>

            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  defaultValue={selectedClient?.colonia ?? ''}
                  as={TextInputField}
                  label={<Label>Colonia</Label>}
                  name="colonia"
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap">
                <Controller
                  control={control}
                  style={{ width: 144 }}
                  defaultValue={selectedClient?.cp ?? ''}
                  render={({ onChange, value }) => (
                    <Pane marginBottom={24}>
                      <TextInputField
                        label={<Label>Código Postal*</Label>}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={5}
                        marginBottom={4}
                        value={value}
                        onKeyDown={(e) => {
                          if (!isNumberKey(e)) {
                            e.preventDefault()
                          }
                        }}
                        onChange={onChange}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="cp"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                  name="cp"
                  rules={{
                    required: 'Requerido',
                  }}
                  {...addWhenFieldError('cp', errors)}
                />
              </Pane>
            </Pane>

            <Pane className="flexible-row">
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  name="pais"
                  defaultValue={selectedClient?.pais ?? '0'}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <Dropdown
                        disabled={!hasPermission("clientes", "write")}
                        allowFreeform={false}
                        label={<Label>País*</Label>}
                        options={[
                          { key: '0', text: 'Selecciona' },
                          { key: 'MEX', text: 'México' },
                          { key: 'USA', text: 'Estados Unidos' },
                          { key: 'CAN', text: 'Canadá' },
                        ]}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={dropDownStyle}
                        onChange={(e, option) => {
                          onChange(option.key)
                          trigger('pais')
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="pais"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                  rules={{
                    validate: (val) => {
                      return val !== '0' || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('pais', errors)}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  // key={currentEstado}
                  control={control}
                  style={{ minWidth: 280 }}
                  name="estado"
                  defaultValue={selectedClient?.estado ?? '0'}
                  rules={{
                    validate: (val) => {
                      return val !== '0' || 'Este campo es requerido'
                    },
                  }}
                  {...addWhenFieldError('estado', errors)}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      <ComboBox
                        disabled={!hasPermission("clientes", "write")}
                        allowFreeform={true}
                        label={<Label>Estado*</Label>}
                        options={estados
                          .filter((estado) => estado.clave === '0' || estado.pais === currentPais)
                          .map((estado) => ({ key: String(estado.clave), text: estado.nombre }))}
                        style={{ minWidth: 280 }}
                        selectedKey={value}
                        styles={comboStyle}
                        onChange={(e, option) => {
                          onChange(option?.key ?? '0')
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="estado"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                />
              </Pane>
              <Pane display="flex" flexFlow="column nowrap" flex="1">
                <Controller
                  control={control}
                  style={{ minWidth: 280 }}
                  name="municipio"
                  defaultValue={municipio_defaultValue}
                  rules={{
                    validate: (val) => {
                      if (currentPais === 'MEX') {
                        return (val !== '0' && val !== '') || 'Este campo es requerido'
                      }
                      return true
                    },
                  }}
                  {...addWhenFieldError('municipio', errors)}
                  render={({ onChange, onBlur, value }) => (
                    <Pane marginBottom={24}>
                      {currentPais === 'MEX' ? (
                        <ComboBox
                          name="municipio"
                          disabled={!hasPermission("clientes", "write")}
                          allowFreeform={true}
                          label={<Label>Municipio*</Label>}
                          options={municipios
                            .filter((mun) => mun.clave === '0' || mun.estado === currentEstado)
                            .map((mun) => ({ key: String(mun.clave), text: mun.nombre }))}
                          style={{ minWidth: 280 }}
                          selectedKey={value}
                          styles={comboStyle}
                          onChange={(e, option, index, value) => {
                            onChange(option?.key ?? '0')
                          }}
                        />
                      ) : (
                        <TextInputField
                          name="municipio"
                          label={<Label>Municipio*</Label>}
                          marginBottom={4}
                          onChange={onChange}
                          value={value === '0' ? '' : value}
                          style={{ minWidth: 280 }}
                        />
                      )}
                      <ErrorMessage
                        errors={errors}
                        name="municipio"
                        render={({ message }) => (
                          <FieldErrorMessage message={message} paddingTop={4} />
                        )}
                      />
                    </Pane>
                  )}
                />
              </Pane>
            </Pane>

            <Separator />

            <Pane display="flex" justifyContent="flex-end" marginBottom={14}>
              <DefaultButton
                style={{ marginRight: hasPermission("clientes", "write") ? 8 : 0 }}
                text={hasPermission("clientes", "write") ? 'Cancelar' : 'Cerrar'}
                onClick={() => onClose()}
                allowDisabledFocus
                disabled={sending}
              />
              {hasPermission("clientes", "write") && (
                <PrimaryButton
                  onRenderText={onSubmitRender}
                  text={selectedClient ? 'Actualizar' : 'Agregar'}
                  type="submit"
                  allowDisabledFocus
                  disabled={sending}
                />
              )}
            </Pane>
          </fieldset>
        </form>
      </ModalContent>
    </CustomModal>
  )
}

const Label = styled.p`
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 600;
  color: ${DefaultPalette.neutralSecondary};
  box-sizing: border-box;
  box-shadow: none;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  overflow-wrap: break-word;
`;

const ModalContent = styled.div`
  padding: 12px 24px 14px 24px;

  .perms-container {
    width: 60%;
    margin-bottom: 30px;
  }

  .rfc {
    width: 180px;
    margin-right: 24px;
  }

  .flexible-row {
    margin: 0px 0 0 -24px;
    width: calc(100% + 24px);
    display: flex;
    flex-flow: row wrap;

    & > * {
      margin: 0px 0 0 24px;
    }
  }
`;
