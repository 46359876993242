import React from 'react'
import { DefaultButton } from '@fluentui/react/lib/Button'
import flatMap from 'lodash/flatMap'
import last from 'lodash/last'
import isEqual from 'lodash/isEqual'
import _get from "lodash/get";
import create from "zustand-store-addons";
import { queryCache } from "@Root/index";
import {
  useFormStore,
  updateClientOnDB,
} from "../screens/pages/Clients/FormModal";
import axios from "@Utils/axios";
import colors from "../style/colors";
import { getSectionSettings } from "../utils";

const getConfigurableParams = (qParams) => {
  let params = { ...qParams };
  delete params.direction;
  delete params.order_by;
  delete params.results;
  return params;
};

const initialState = {
  list: [],
  compactMode: false,
  dialogRef: null,
  activeRowId: null,
  selectedClient: null,
  formVisible: false,
  dialogVisible: false,
  showingConfig: false,
  pageInfo: {},
  fetching: true,
  fetchMore: null,

  queryParams: {
    tipo_persona: "all",
    prioridad: "all",
    razon_social: "",
    rfc: "",
    socio: "all",
    numero_control: "",
    direction: "first",
    order_by: "razon_social",
    results: 20,
  },
};

export const useClientsStore = create(
  (set, get) => ({
    ...initialState,

    resetStore: () => {
      set(initialState);
    },

    loadSettings: () => {
      const settings = getSectionSettings("clientes");
      // console.log("loadSettings", settings);
      const currentSettings = get().queryParams;
      set({
        queryParams: {
          ...currentSettings,
          results: _get(settings, "resultsPerBatch", 20),
        },
      });
    },

    setConfig: (params) => {
      set({
        pageInfo: {},
        list: [],
        fetching: true,
        queryParams:
          typeof params === "function" ? params(get().queryParams) : params,
      });
    },

    setDialogRef: (ref) => {
      set({ dialogRef: ref });
    },

    onClientEdit: (item) => {
      set({ selectedClient: item, formVisible: true });
    },

    onClientsFetch: async (data) => {
      if (data) {
        set({
          list: flatMap(data, (data) => data.clients),
          pageInfo: last(data).pageInfo,
          fetching: false,
        });
      }
    },

    onFetchMoreBtn: () => {
      if (typeof get().fetchMore === "function") {
        set({
          fetching: true,
        });
        get().fetchMore();
      }
    },

    updateList: (data) => {
      // console.log(data);
      set({ list: data });
    },

    onToggleActive: (item) => {
      const dialog = get().dialogRef;
      dialog.open({
        title: "Confirmación requerida",
        subtitle: `¿Deseas ${
          item.activo ? "desactivar" : "activar"
        } al cliente: ${item.razon_social}?`,
        cancelLabel: "No",
        actionLabel: "Sí",
        busyText: "Guardado cambios",
        actionFunction: async () => {
          updateClientOnDB("put", { id: item.id, activo: !item.activo })
            .then((data) => {
              // console.log(data);
              queryCache.refetchQueries(["clientsList"]);
            })
            .catch((e) => console.log(e));
        },
      });
    },

    onClientDelete: (item) => {
      const dialog = get().dialogRef;
      dialog.open({
        title: "Confirmación requerida",
        subtitle: `¿Deseas eliminar toda la información del cliente: ${item.razon_social}?`,
        cancelLabel: "No",
        actionLabel: "Sí",
        busyText: "Eliminando",
        actionFunction: async () => {
          axios
            .delete("/api/v1/clientes", { params: { clientId: item.id } })
            .then(({ data }) => {
              if (data.ok) {
                queryCache.refetchQueries(["clientsList"]);
              }
            })
            .catch((e) => console.log(e));
        },
      });
    },
  }),
  {
    computed: {
      FiltersButton(ctx) {
        const currentParams = this.queryParams;

        return ({ style = {} }) => (
          <DefaultButton
            onClick={() => ctx.set({ showingConfig: true })}
            text="Configuración"
            style={{ alignSelf: "flex-end", ...style }}
            styles={{ flexContainer: { flexDirection: "row-reverse" } }}
            iconProps={{
              iconName: "FilterSolid",
              style: {
                fontSize: 10,
                color: isEqual(
                  getConfigurableParams(currentParams),
                  getConfigurableParams(initialState.queryParams)
                )
                  ? colors.neutral.N5
                  : colors.green.base,
              },
            }}
          />
        );
      },
    },

    watchers: {
      selectedClient(newValue) {
        useFormStore.setState({ selectedClient: newValue });
      },
    },

    middleware: [],

    settings: {
      name: "clients",
      logLevel: "diff",
    },
  }
);
